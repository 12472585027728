"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FindTuneRoutingModule = void 0;
const router_1 = require("@angular/router");
const find_tune_page_1 = require("./find-tune.page");
const find_tune_guard_1 = require("./route-guards/find-tune.guard");
const i0 = require("@angular/core");
const i1 = require("@angular/router");
const routes = [
    {
        path: '',
        component: find_tune_page_1.FindTunePage,
        canActivate: [find_tune_guard_1.findTuneRouteGuard],
        children: [
            {
                path: 'search',
                loadChildren: () => Promise.resolve().then(() => require('./search-handler/search-handler.module')).then(c => c.SearchHandlerModule),
            },
            {
                path: 'select',
                loadChildren: () => Promise.resolve().then(() => require('./category-handler/category-handler.module')).then(c => c.CategoryHandlerModule),
            }
        ]
    },
    {
        path: '**',
        redirectTo: ''
    }
];
class FindTuneRoutingModule {
    static { this.ɵfac = function FindTuneRoutingModule_Factory(t) { return new (t || FindTuneRoutingModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: FindTuneRoutingModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [router_1.RouterModule.forChild(routes), router_1.RouterModule] }); }
}
exports.FindTuneRoutingModule = FindTuneRoutingModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(FindTuneRoutingModule, { imports: [i1.RouterModule], exports: [router_1.RouterModule] }); })();
