"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignUpPageModule = void 0;
const common_1 = require("@angular/common");
const forms_1 = require("@angular/forms");
const angular_1 = require("@ionic/angular");
const sign_up_routing_module_1 = require("./sign-up-routing.module");
const sign_up_page_1 = require("./sign-up.page");
const footer_bar_module_1 = require("../../components/footer-bar/footer-bar.module");
const i0 = require("@angular/core");
const i1 = require("@angular/common");
const i2 = require("@angular/forms");
const i3 = require("@ionic/angular");
const i4 = require("@angular/router");
const i5 = require("../../components/footer-bar/footer-bar.component");
class SignUpPageModule {
    static { this.ɵfac = function SignUpPageModule_Factory(t) { return new (t || SignUpPageModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: SignUpPageModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [common_1.CommonModule,
            forms_1.FormsModule,
            angular_1.IonicModule,
            sign_up_routing_module_1.SignUpPageRoutingModule,
            footer_bar_module_1.FooterBarModule] }); }
}
exports.SignUpPageModule = SignUpPageModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(SignUpPageModule, { declarations: [sign_up_page_1.SignUpPage], imports: [common_1.CommonModule,
        forms_1.FormsModule,
        angular_1.IonicModule,
        sign_up_routing_module_1.SignUpPageRoutingModule,
        footer_bar_module_1.FooterBarModule] }); })();
i0.ɵɵsetComponentScope(sign_up_page_1.SignUpPage, [i1.NgIf, i2.ɵNgNoValidate, i2.NgControlStatus, i2.NgControlStatusGroup, i2.RequiredValidator, i2.NgModel, i2.NgForm, i3.IonButton, i3.IonCol, i3.IonContent, i3.IonGrid, i3.IonHeader, i3.IonImg, i3.IonInput, i3.IonLabel, i3.IonRow, i3.IonTitle, i3.IonToolbar, i3.TextValueAccessor, i3.RouterLinkWithHrefDelegate, i4.RouterLink, i5.FooterBarComponent], []);
