"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackPageRoutingModule = void 0;
const router_1 = require("@angular/router");
const track_page_1 = require("./track.page");
const i0 = require("@angular/core");
const i1 = require("@angular/router");
const routes = [
    {
        path: '',
        component: track_page_1.TrackPage
    }
];
class TrackPageRoutingModule {
    static { this.ɵfac = function TrackPageRoutingModule_Factory(t) { return new (t || TrackPageRoutingModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: TrackPageRoutingModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [router_1.RouterModule.forChild(routes), router_1.RouterModule] }); }
}
exports.TrackPageRoutingModule = TrackPageRoutingModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(TrackPageRoutingModule, { imports: [i1.RouterModule], exports: [router_1.RouterModule] }); })();
