"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddTuneToListComponent = void 0;
const angular_1 = require("@ionic/angular");
const interfaces_shared_1 = require("../../../../../backend/classes-enums-interfaces-types/interfaces/interfaces.shared");
const enums_enum_1 = require("../../classes-enums-interfaces-types/enums/enums.enum");
const core_1 = require("@ionic/core");
const classes_1 = require("../../classes-enums-interfaces-types/classes/classes");
const my_server_service_1 = require("../../services/my-server/my-server.service");
const global_service_1 = require("../../services/global.service");
const enums_shared_enum_1 = require("../../../../../backend/classes-enums-interfaces-types/enums/enums.shared.enum");
const error_handler_service_1 = require("../../services/error-handler/error-handler.service");
const i0 = require("@angular/core");
const i1 = require("@ionic/angular");
const i2 = require("../../services/my-server/my-server.service");
const i3 = require("../../services/global.service");
const i4 = require("../../services/error-handler/error-handler.service");
const i5 = require("@angular/common");
const i6 = require("@angular/forms");
function AddTuneToListComponent_ng_container_11_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainer(0);
} }
function AddTuneToListComponent_ng_template_14_Template(rf, ctx) { if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ion-toolbar")(1, "ion-buttons", 1)(2, "ion-button", 2);
    i0.ɵɵlistener("click", function AddTuneToListComponent_ng_template_14_Template_ion_button_click_2_listener() { i0.ɵɵrestoreView(_r8); const ctx_r7 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r7.cancelCTL()); });
    i0.ɵɵtext(3, "Cancel");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(4, "ion-title");
    i0.ɵɵtext(5, "List name");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "ion-buttons", 10)(7, "ion-button", 11);
    i0.ɵɵlistener("click", function AddTuneToListComponent_ng_template_14_Template_ion_button_click_7_listener() { i0.ɵɵrestoreView(_r8); const ctx_r9 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r9.confirmCTL()); });
    i0.ɵɵtext(8, "Done");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(9, "ion-grid", 12)(10, "ion-row")(11, "ion-item", 13)(12, "ion-input", 14);
    i0.ɵɵlistener("ngModelChange", function AddTuneToListComponent_ng_template_14_Template_ion_input_ngModelChange_12_listener($event) { i0.ɵɵrestoreView(_r8); const ctx_r10 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r10.newListName = $event); });
    i0.ɵɵelementEnd()()()();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(7);
    i0.ɵɵproperty("strong", true);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("ngModel", ctx_r2.newListName);
} }
function AddTuneToListComponent_ng_template_15_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "ion-row", 15);
    i0.ɵɵtext(1, " You have no Tune lists yet :( ");
    i0.ɵɵelementEnd();
} }
function AddTuneToListComponent_ng_template_17_ion_item_3_Template(rf, ctx) { if (rf & 1) {
    const _r14 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ion-item", 20);
    i0.ɵɵlistener("click", function AddTuneToListComponent_ng_template_17_ion_item_3_Template_ion_item_click_0_listener() { const restoredCtx = i0.ɵɵrestoreView(_r14); const tuneList_r12 = restoredCtx.$implicit; const ctx_r13 = i0.ɵɵnextContext(2); return i0.ɵɵresetView(ctx_r13.tuneListClicked(tuneList_r12)); });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const tuneList_r12 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", tuneList_r12.name, " ");
} }
function AddTuneToListComponent_ng_template_17_Template(rf, ctx) { if (rf & 1) {
    const _r16 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ion-row")(1, "ion-content", 16);
    i0.ɵɵlistener("touchmove", function AddTuneToListComponent_ng_template_17_Template_ion_content_touchmove_1_listener($event) { i0.ɵɵrestoreView(_r16); const ctx_r15 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r15.onScrollStart($event)); });
    i0.ɵɵelementStart(2, "ion-list", 17);
    i0.ɵɵtemplate(3, AddTuneToListComponent_ng_template_17_ion_item_3_Template, 2, 1, "ion-item", 18);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "ion-infinite-scroll", 19);
    i0.ɵɵlistener("ionInfinite", function AddTuneToListComponent_ng_template_17_Template_ion_infinite_scroll_ionInfinite_4_listener($event) { i0.ɵɵrestoreView(_r16); const ctx_r17 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r17.onIonInfinite($event)); });
    i0.ɵɵelement(5, "ion-infinite-scroll-content");
    i0.ɵɵelementEnd()()();
} if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngForOf", ctx_r6.tuneLists);
} }
class AddTuneToListComponent {
    constructor(modalCtrl, mServer, gs, eh, alertController) {
        this.modalCtrl = modalCtrl;
        this.mServer = mServer;
        this.gs = gs;
        this.eh = eh;
        this.alertController = alertController;
        this.modalsE = enums_enum_1.ModalsE;
        this.tuneListsLoaded = false;
        this.mServer.getUserTuneLists().subscribe((userTuneListM) => {
            this.tuneLists = userTuneListM?.tuneListMCreated;
            this.tuneListsLoaded = true;
        }, e => {
            this.tuneListsLoaded = true;
            console.error(`Error getting user Tune lists`, e);
            this.eh.logSentryError(e);
        });
    }
    cancelAtTL() {
        return this.modalCtrl.dismiss(null, 'cancel', enums_enum_1.ModalsE.addToTuneList);
    }
    cancelCTL() {
        this.modalCtrl.dismiss(null, 'cancel', enums_enum_1.ModalsE.createTuneList);
    }
    confirmCTL() {
        console.log('confirmCTL');
        //this.modalCtrl.dismiss()
        this.mServer.createTuneList(this.newListName, [this.tune]).subscribe(async (tuneList) => {
            console.log(`Tune list created`);
            try {
                await this.modalCtrl.dismiss(null, 'done', enums_enum_1.ModalsE.createTuneList);
                this.gs.showToast({ msg: 'Tune list created' });
                await this.modalCtrl.dismiss({ createdList: true, tuneList: tuneList }, 'added', enums_enum_1.ModalsE.addToTuneList);
            }
            catch (e) {
                console.error(`Error dismissing modal`, e);
                this.eh.logSentryError(e);
            }
        }, (e) => {
            if (e.error == enums_shared_enum_1.CreateTuneListErrors.tuneListNameNotUnique) {
                this.gs.showToast({ msg: 'Tune list name not unique', type: enums_enum_1.ToastEnum.error });
            }
            else {
                this.gs.showToast({ msg: "Error creating list", type: enums_enum_1.ToastEnum.error });
            }
            console.error(`Error creating Tune list`, e);
            this.eh.logSentryError(e);
            // add error log after sentry merger
        });
    }
    onIonInfinite(event) {
        event.target.complete();
    }
    tuneListClicked(tuneList) {
        this.mServer.addTuneToTuneList(tuneList._id.toString(), this.tune._id, true).subscribe((res) => {
            console.log(`Tune added to tune list`);
            this.modalCtrl.dismiss({ tuneList: tuneList }, 'added', enums_enum_1.ModalsE.addToTuneList);
            this.gs.showToast({ msg: 'Tune added to Tune list' });
        }, e => {
            if (e.status == 409) {
                this.gs.showToast({ msg: 'Duplicates Tunes in list is not yet supported', type: enums_enum_1.ToastEnum.error, duration: 4000 });
                //this.showAlertCancelConfirm(tuneList)
            }
            else {
                console.error(`Error adding Tune to Tune list`, e);
                this.eh.logSentryError(e);
            }
            // add error log after sentry merger
        });
    }
    ngOnInit() {
        // const scrollcontainer = this.element.nativeElement.querySelector(this.scrollContainerClass);
        // scrollcontainer.ontouchmove = function (e) {
        // 	e.stopPropagation();
        // };
    }
    onScrollStart(event) {
        event.stopPropagation();
    }
    async showAlertCancelConfirm(tuneList) {
        const cancelBtn = {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'm-alert-cancel-button'
        };
        const addBtn = {
            text: 'Add',
            role: 'add',
            handler: () => {
                this.mServer.addTuneToTuneList(tuneList._id.toString(), this.tune._id, true, false).subscribe((res) => {
                    console.log(`Tune added to tune list`);
                    this.modalCtrl.dismiss({ tuneList: tuneList }, 'added', enums_enum_1.ModalsE.addToTuneList);
                    this.gs.showToast({ msg: 'Tune added to Tune list' });
                }, e => {
                    console.error(`Error adding Tune to Tune list`, e);
                    this.eh.logSentryError(e);
                });
            }
        };
        const alert = await this.alertController.create({
            header: "Confirm",
            message: "Tune is already in list",
            buttons: [cancelBtn, addBtn],
        });
        await alert.present();
    }
    static { this.ɵfac = function AddTuneToListComponent_Factory(t) { return new (t || AddTuneToListComponent)(i0.ɵɵdirectiveInject(i1.ModalController), i0.ɵɵdirectiveInject(i2.MyServerService), i0.ɵɵdirectiveInject(i3.GlobalService), i0.ɵɵdirectiveInject(i4.ErrorHandlerService), i0.ɵɵdirectiveInject(i1.AlertController)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: AddTuneToListComponent, selectors: [["app-add-tune-to-list"]], inputs: { tune: "tune" }, decls: 19, vars: 4, consts: [["slot", "fixed"], ["slot", "start"], ["color", "medium", 3, "click"], [2, "padding-top", "3dvh"], ["id", "open-modal", 1, "themed-ion-button"], [4, "ngIf", "ngIfThen", "ngIfElse"], ["trigger", "open-modal", 3, "id"], ["modal", ""], ["noTuneListTemplate", ""], ["tuneListTemplate", ""], ["slot", "end"], ["color", "primary", 3, "strong", "click"], ["id", "list-name-grid"], ["id", "list-name-item"], ["type", "text", 3, "ngModel", "ngModelChange"], [2, "font-size", "5vw", "margin-top", "3dvh"], ["id", "tune-list-content", 3, "touchmove"], ["id", "tune-list-ion-list", "lines", "full"], ["lines", "full", "class", "tune-list-item", 3, "click", 4, "ngFor", "ngForOf"], [3, "ionInfinite"], ["lines", "full", 1, "tune-list-item", 3, "click"]], template: function AddTuneToListComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "ion-header", 0)(1, "ion-toolbar")(2, "ion-buttons", 1)(3, "ion-button", 2);
            i0.ɵɵlistener("click", function AddTuneToListComponent_Template_ion_button_click_3_listener() { return ctx.cancelAtTL(); });
            i0.ɵɵtext(4, "Cancel");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(5, "ion-title");
            i0.ɵɵtext(6, "Add Tune to list");
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(7, "ion-grid", 3)(8, "ion-row")(9, "ion-button", 4);
            i0.ɵɵtext(10, "New Tune List");
            i0.ɵɵelementEnd()();
            i0.ɵɵtemplate(11, AddTuneToListComponent_ng_container_11_Template, 1, 0, "ng-container", 5);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(12, "ion-modal", 6, 7);
            i0.ɵɵtemplate(14, AddTuneToListComponent_ng_template_14_Template, 13, 2, "ng-template");
            i0.ɵɵelementEnd();
            i0.ɵɵtemplate(15, AddTuneToListComponent_ng_template_15_Template, 2, 0, "ng-template", null, 8, i0.ɵɵtemplateRefExtractor);
            i0.ɵɵtemplate(17, AddTuneToListComponent_ng_template_17_Template, 6, 1, "ng-template", null, 9, i0.ɵɵtemplateRefExtractor);
        } if (rf & 2) {
            const _r3 = i0.ɵɵreference(16);
            const _r5 = i0.ɵɵreference(18);
            i0.ɵɵadvance(11);
            i0.ɵɵproperty("ngIf", (!ctx.tuneLists || (ctx.tuneLists == null ? null : ctx.tuneLists.length) == 0) && ctx.tuneListsLoaded)("ngIfThen", _r3)("ngIfElse", _r5);
            i0.ɵɵadvance(1);
            i0.ɵɵpropertyInterpolate("id", ctx.modalsE.createTuneList);
        } }, dependencies: [i5.NgForOf, i5.NgIf, i6.NgControlStatus, i6.NgModel, i1.IonButton, i1.IonButtons, i1.IonContent, i1.IonGrid, i1.IonHeader, i1.IonInfiniteScroll, i1.IonInfiniteScrollContent, i1.IonInput, i1.IonItem, i1.IonList, i1.IonRow, i1.IonTitle, i1.IonToolbar, i1.IonModal, i1.TextValueAccessor], styles: ["#main-content[_ngcontent-%COMP%] {\n  display: flex;\n  justify-content: center;\n  color: var(--ion-text-color);\n}\n\nion-content[_ngcontent-%COMP%] {\n  display: flex;\n}\n\nion-grid[_ngcontent-%COMP%] {\n  justify-content: center;\n  color: var(--ion-text-color);\n}\n\nion-button[_ngcontent-%COMP%] {\n  justify-self: center;\n}\n\nion-row[_ngcontent-%COMP%] {\n  justify-content: center;\n}\n\nion-col[_ngcontent-%COMP%] {\n  justify-content: center;\n}\n\nion-modal#CREATE_TUNE_LIST[_ngcontent-%COMP%] {\n  --width: fit-content;\n  --min-width: 250px;\n  --height: fit-content;\n  --border-radius: 6px;\n  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);\n}\n\nion-modal#create-tune-list-modal[_ngcontent-%COMP%] {\n  color: var(--ion-text-color);\n}\n\n#list-name-grid[_ngcontent-%COMP%] {\n  width: 80dvw;\n  padding: 2dvh;\n}\n\n#list-name-item[_ngcontent-%COMP%] {\n  margin-bottom: 2dvh;\n}\n\n.tune-list-item[_ngcontent-%COMP%] {\n  margin: 0.75dvh;\n  --border-color:var(--border-color-faded);\n}\n\n#tune-list-ion-list[_ngcontent-%COMP%] {\n  margin-left: 2dvw;\n  margin-right: 2dvw;\n}\n\n#tune-list-content[_ngcontent-%COMP%] {\n  width: 80dvw;\n  height: 50dvh;\n  margin-top: 3dvh;\n}\n\n#open-modal[_ngcontent-%COMP%] {\n  margin-bottom: 2dvh;\n}\n\n\n\n\n\n\n"] }); }
}
exports.AddTuneToListComponent = AddTuneToListComponent;
