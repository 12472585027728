"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MyLibraryPageModule = void 0;
const lyrics_module_1 = require("./../components/lyrics/lyrics.module");
const forms_1 = require("@angular/forms");
const footer_bar_module_1 = require("./../components/footer-bar/footer-bar.module");
const my_library_routing_module_1 = require("./my-library-routing.module");
const selected_track_module_1 = require("../components/selected-track/selected-track.module");
const my_library_page_1 = require("./my-library.page");
const list_element_module_1 = require("../components/list-element/list-element.module");
const trigger_component_1 = require("./trigger/trigger.component");
const loop_progress_bar_module_1 = require("../components/loop-progress-bar/loop-progress-bar.module");
const directives_module_1 = require("../directives/directives.module");
const shared_module_1 = require("../shared/shared.module");
const accordion_1 = require("@angular/cdk/accordion");
const i0 = require("@angular/core");
class MyLibraryPageModule {
    static { this.ɵfac = function MyLibraryPageModule_Factory(t) { return new (t || MyLibraryPageModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: MyLibraryPageModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [forms_1.FormsModule,
            shared_module_1.SharedModule,
            my_library_routing_module_1.MyLibraryPageRoutingModule,
            selected_track_module_1.SelectedTrackModule,
            list_element_module_1.ListElementModule,
            footer_bar_module_1.FooterBarModule,
            loop_progress_bar_module_1.LoopProgressBarModule,
            lyrics_module_1.LyricsModule,
            directives_module_1.DirectivesModule,
            accordion_1.CdkAccordionModule] }); }
}
exports.MyLibraryPageModule = MyLibraryPageModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(MyLibraryPageModule, { declarations: [my_library_page_1.MyLibraryPage,
        trigger_component_1.TriggerComponent], imports: [forms_1.FormsModule,
        shared_module_1.SharedModule,
        my_library_routing_module_1.MyLibraryPageRoutingModule,
        selected_track_module_1.SelectedTrackModule,
        list_element_module_1.ListElementModule,
        footer_bar_module_1.FooterBarModule,
        loop_progress_bar_module_1.LoopProgressBarModule,
        lyrics_module_1.LyricsModule,
        directives_module_1.DirectivesModule,
        accordion_1.CdkAccordionModule] }); })();
