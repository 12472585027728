"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FacebookDataService = void 0;
const core_1 = require("@angular/core");
const http_1 = require("@angular/common/http");
const environment_1 = require("../../../../environments/environment");
const common_1 = require("@angular/common");
const i0 = require("@angular/core");
const i1 = require("@angular/common/http");
class FacebookDataService {
    constructor(
    //private renderer : Renderer2,
    _document, http, rendererFactory) {
        this._document = _document;
        this.http = http;
        this.mFbId = "2241502182535955";
        this.truddPageAccessToken = "EAAKkgroxCXsBAOpKseo10VB9nDYCQ7arERZCvVfWIflrcHZA25ynNFaXGoTKrJgV1ykCOM2IfxZAZCZCZBAuuoIW1X8hgKdGLZC1L2iJBmvWGsOdrhg93R3QCKmploIuxZA0ZB0QVTr2FJngjksXwqtejMAG8xsZBy5XedvAF5gldvGnJ8dxfORBVl1znmQR7U374ZD";
        this.psid = "";
        this.renderer = rendererFactory.createRenderer(null, null);
    }
    /*  injectMessengerExtensionHtmlCode()
     {
       const s = this.renderer.createElement('script');
       s.type = 'text/javascript';
       //s.src = 'https://path/to/your/script';
       s.text = ``;
       s.text =
       `(function(d, s, id)
       {
         var js, fjs = d.getElementsByTagName(s)[0];
         if (d.getElementById(id)) {return;}
         js = d.createElement(s); js.id = id;
         js.src = "//connect.facebook.net/en_US/messenger.Extensions.js";
         fjs.parentNode.insertBefore(js, fjs);
       }(document, 'script', 'Messenger'));
       window.extAsyncInit = function() {
         MessengerExtensions.getContext('743831329900923',
           function success(thread_context){
             console.log('psid: '+ thread_context)
            //alert(thread_context)
           },
           function error(err){
             console.log("sdk error: "+err);
             //alert("error: " + err);
           }
         );
       };`
       //this.elementRef.nativeElement.appendChild(s);;
       this.renderer.appendChild(this._document.body, s);
   
     } */
    sendTune(tuneId) {
        let body;
        body.psid = this.psid;
        body.tuneId = tuneId;
        {
            this.http.post(environment_1.ENV.DOMAIN_OF_BACKEND + "/facebook/send-tune", body)
                .subscribe(data => {
                console.log(data);
            }, err => {
                console.log("Error testPostData");
                console.log(err);
            });
        }
    }
    static { this.ɵfac = function FacebookDataService_Factory(t) { return new (t || FacebookDataService)(i0.ɵɵinject(common_1.DOCUMENT), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i0.RendererFactory2)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: FacebookDataService, factory: FacebookDataService.ɵfac, providedIn: 'root' }); }
}
exports.FacebookDataService = FacebookDataService;
