"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DevRouterPage = void 0;
const mglobal_1 = require("../mglobal");
const environment_1 = require("../../../environments/environment");
const global_service_1 = require("../services/global.service");
const i0 = require("@angular/core");
const i1 = require("../services/global.service");
const i2 = require("@angular/forms");
const i3 = require("@ionic/angular");
const i4 = require("@angular/router");
const _c0 = function () { return { tune: "172" }; };
class DevRouterPage {
    constructor(gs) {
        this.gs = gs;
        this.mGlobal = mglobal_1.mGlobal;
        this.env = environment_1.ENV;
    }
    ngOnInit() {
    }
    ionViewDidEnter() {
        this.gs.setPendingHistoryBackClick(false);
    }
    static { this.ɵfac = function DevRouterPage_Factory(t) { return new (t || DevRouterPage)(i0.ɵɵdirectiveInject(i1.GlobalService)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: DevRouterPage, selectors: [["app-dev-router"]], decls: 34, vars: 13, consts: [[3, "routerLink"], [3, "routerLink", "queryParams"], [2, "margin-top", "3vh"], [3, "ngModel", "ngModelChange"]], template: function DevRouterPage_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "ion-header")(1, "ion-toolbar")(2, "ion-title");
            i0.ɵɵtext(3, "dev-router");
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(4, "ion-grid")(5, "ion-row")(6, "ion-button", 0);
            i0.ɵɵtext(7, " Dev2 ");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(8, "ion-row")(9, "ion-button", 0);
            i0.ɵɵtext(10, " Find tune ");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(11, "ion-row")(12, "ion-button", 0);
            i0.ɵɵtext(13, " Edit tune ");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(14, "ion-row")(15, "ion-button", 0);
            i0.ɵɵtext(16, " Sign up ");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(17, "ion-row")(18, "ion-button", 1);
            i0.ɵɵtext(19, " Play tune ");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(20, "ion-row")(21, "ion-button", 0);
            i0.ɵɵtext(22, " Chat ");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(23, "ion-row")(24, "ion-button", 0);
            i0.ɵɵtext(25, " Chat list ");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(26, "ion-row")(27, "ion-button", 0);
            i0.ɵɵtext(28, " My Library ");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(29, "ion-row", 2)(30, "ion-checkbox", 3);
            i0.ɵɵlistener("ngModelChange", function DevRouterPage_Template_ion_checkbox_ngModelChange_30_listener($event) { return ctx.mGlobal.isAdmin = $event; });
            i0.ɵɵtext(31, "isAdmin");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(32, "ion-row");
            i0.ɵɵtext(33);
            i0.ɵɵelementEnd()();
        } if (rf & 2) {
            i0.ɵɵadvance(6);
            i0.ɵɵproperty("routerLink", "/dev");
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("routerLink", "/find-tune");
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("routerLink", "/edit-tune");
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("routerLink", "/sign-up");
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("routerLink", "/play-tune")("queryParams", i0.ɵɵpureFunction0(12, _c0));
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("routerLink", "/chat");
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("routerLink", "/chat-list");
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("routerLink", "/my-library");
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("ngModel", ctx.mGlobal.isAdmin);
            i0.ɵɵadvance(3);
            i0.ɵɵtextInterpolate2(" v", ctx.env.DEPLOY_VERSION_MAJOR, ".", ctx.env.DEPLOY_VERSION_MINOR, " ");
        } }, dependencies: [i2.NgControlStatus, i2.NgModel, i3.IonButton, i3.IonCheckbox, i3.IonGrid, i3.IonHeader, i3.IonRow, i3.IonTitle, i3.IonToolbar, i3.BooleanValueAccessor, i3.RouterLinkDelegate, i4.RouterLink], styles: ["ion-grid[_ngcontent-%COMP%] {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  color: var(--ion-text-color);\n  width: 100vw;\n}"] }); }
}
exports.DevRouterPage = DevRouterPage;
