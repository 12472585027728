"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataService = void 0;
const http_1 = require("@angular/common/http");
const classes_1 = require("./../../classes-enums-interfaces-types/classes/classes");
const player_service_1 = require("./player.service");
const enums_shared_enum_1 = require("../../../../../backend/classes-enums-interfaces-types/enums/enums.shared.enum");
const i0 = require("@angular/core");
const i1 = require("@angular/common/http");
const i2 = require("./player.service");
class DataService {
    constructor(http, ps) {
        this.http = http;
        this.ps = ps;
        this.listElementTypes = enums_shared_enum_1.ListElementTypesEnum;
        this.spotSearchLimitDefault = 20;
        this.getTracks = (query, offset, limit) => {
            console.log("Got to getTrack");
            var params = new http_1.HttpParams().set('q', query).set('type', "track").set('offset', String(offset)).set('limit', String(limit));
            //params.set('q',query).set('type',"track").set('offset',String(offset)).set('limit',String(limit));
            return this.http.get("https://api.spotify.com/v1/search/", {
                params: params,
                headers: new http_1.HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken())
            });
        };
    }
    getTracksById(trackIds) {
        //trackIds are comma sepereated List, TODO make input array and convert here
        console.log("Got to getTrackCover");
        return this.http.get("https://api.spotify.com/v1/tracks/?ids=" + trackIds, {
            headers: new http_1.HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken())
        });
    }
    getTrackNameArtistAndCover(tune) {
        console.log("getTrackNameAndArtistName");
        return this.http.get("https://api.spotify.com/v1/tracks/" + tune.slimTuneTrack.trackId, { headers: new http_1.HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken()) });
    }
    spotFetchFormatToMFormat(spotArr, itemsAddedAt) {
        var mFormatArr = [];
        for (const [i, spotElem] of spotArr.entries()) {
            switch (spotElem.type) {
                case 'track':
                    let mTrack;
                    if (itemsAddedAt) {
                        mTrack = this.spotTrackToMSlimSpotTrack(spotArr[i], itemsAddedAt[i]);
                    }
                    else {
                        mTrack = this.spotTrackToMSlimSpotTrack(spotArr[i]);
                    }
                    if (this.mFormatHasFields(mTrack))
                        mFormatArr.push(mTrack);
                    break;
                case 'artist':
                    const mArtist = this.spotArtistToMSlimSpotArtist(spotElem);
                    if (this.mFormatHasFields(mArtist))
                        mFormatArr.push(mArtist);
                    break;
                default:
                    break;
            }
        }
        return mFormatArr;
    }
    mFormatHasFields(elem) {
        // Some artists returned from Spot API has images as empty array
        // Add more checks when more bugs are discovered
        if (elem.images && (elem?.images.length >= 3)) {
            return true;
        }
        else {
            return false;
        }
        if (elem.type == enums_shared_enum_1.ListElementTypesEnum.slimSpotTrack) {
        }
        else if (elem.type == enums_shared_enum_1.ListElementTypesEnum.slimSpotTrack) {
        }
    }
    //Fix, just unnessary fetch
    //no reason to do on FE
    spotTrackToMTrack(spotTrack) {
        let mArtists = [];
        spotTrack.artists.forEach(artist => {
            mArtists.push(new classes_1.MArtistV1(artist.id, artist.name, [], enums_shared_enum_1.ListElementTypesEnum.slimSpotArtist));
        });
        const mTrack = new classes_1.MTrackV1(new classes_1.MAlbumV1(spotTrack.album.id, spotTrack.album.images), mArtists, spotTrack.available_markets, spotTrack.durationMs, spotTrack.id, '', 0, spotTrack.name);
        mArtists.push();
        return mTrack;
    }
    spotTrackToMSlimSpotTrack(spotTrack, addedAt) {
        let slimSpotTrack = new classes_1.SlimSpotTrackV1(spotTrack.artists[0].id, spotTrack.artists[0].name, spotTrack.duration_ms, spotTrack.album.images, spotTrack.id, spotTrack.name, spotTrack.addedAt);
        return slimSpotTrack;
    }
    spotArtistToMSlimSpotArtist(spotArtist) {
        let slimSpotArtist = new classes_1.SlimSpotArtistV1(spotArtist.id, spotArtist.images, spotArtist.name, enums_shared_enum_1.ListElementTypesEnum.slimSpotArtist);
        return slimSpotArtist;
    }
    search(searchWord, type, offset, limit = this.spotSearchLimitDefault) {
        const params = new http_1.HttpParams()
            .set('q', searchWord)
            .set('type', type)
            .set('limit', limit.toString())
            .set('offset', offset.toString())
            .set('market', 'SE');
        // dont know what res will be if no results found	
        return this.http.get('https://api.spotify.com/v1/search', {
            headers: new http_1.HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken()),
            params
        });
    }
    getDevices() {
        return this.http.get("https://api.spotify.com/v1/me/player/devices", { headers: new http_1.HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken()) });
    }
    getPlaylist(playListId, offset, sectionLength) {
        console.log("getPlayList");
        console.log(playListId);
        console.log(offset);
        return this.http.get("https://api.spotify.com/v1/playlists/" + playListId + "/tracks?offset=" + offset + "&limit=" + sectionLength + "&fields=items(track(name,duration_ms,artists(name),album(images(url)),id))", {
            headers: new http_1.HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken())
        });
    }
    getLikedSongsList(offset, limit) {
        return this.http.get("https://api.spotify.com/v1/me/tracks?offset=" + offset + "&limit=" + limit, {
            headers: new http_1.HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken())
        });
    }
    getTrackImg(trackId) { }
    addTrackToPlaylist(plId, trackId) {
        return this.http.post("https://api.spotify.com/v1/playlists/" + plId + "/tracks", {
            "uris": ["spotify:track:" + trackId]
        }, {
            headers: new http_1.HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken())
        });
    }
    deleteTrackFromPlaylist(plId, trackId) {
        const options = {
            headers: new http_1.HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken()),
            body: {
                "tracks": [
                    {
                        "uri": "spotify:track:" + trackId
                    }
                ]
            }
        };
        return this.http.delete("https://api.spotify.com/v1/playlists/" + plId + "/tracks", options);
    }
    getUserSpotPlaylists(paginationPlaylistOffset, limit = 10) {
        const params = new http_1.HttpParams()
            .set('offset', paginationPlaylistOffset.toString())
            .set('limit', limit.toString());
        return this.http.get("https://api.spotify.com/v1/me/playlists", {
            headers: new http_1.HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken()),
            params
        });
    }
    getSpotPlaylist(playlistId, offset, limit = 10) {
        const params = new http_1.HttpParams()
            .set('offset', offset.toString())
            .set('limit', limit.toString());
        return this.http.get(`https://api.spotify.com/v1/playlists/${playlistId}/tracks`, {
            headers: new http_1.HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken()),
            params
        });
    }
    getUserLikedSongs(offset, limit = 10) {
        const params = new http_1.HttpParams()
            .set('offset', offset.toString())
            .set('limit', limit.toString());
        return this.http.get(`https://api.spotify.com/v1/me/tracks`, {
            headers: new http_1.HttpHeaders().set('Authorization', "Bearer " + this.ps.getSpotAccessToken()),
            params
        });
    }
    static { this.ɵfac = function DataService_Factory(t) { return new (t || DataService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.PlayerService)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: DataService, factory: DataService.ɵfac, providedIn: 'root' }); }
}
exports.DataService = DataService;
