"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatPageRoutingModule = void 0;
const router_1 = require("@angular/router");
const chat_page_1 = require("./chat.page");
const i0 = require("@angular/core");
const i1 = require("@angular/router");
const routes = [
    {
        path: '',
        component: chat_page_1.ChatPage
    },
];
class ChatPageRoutingModule {
    static { this.ɵfac = function ChatPageRoutingModule_Factory(t) { return new (t || ChatPageRoutingModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: ChatPageRoutingModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [router_1.RouterModule.forChild(routes), router_1.RouterModule] }); }
}
exports.ChatPageRoutingModule = ChatPageRoutingModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(ChatPageRoutingModule, { imports: [i1.RouterModule], exports: [router_1.RouterModule] }); })();
