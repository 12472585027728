"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayTuneModule = void 0;
const lyrics_module_1 = require("./../components/lyrics/lyrics.module");
const loop_progress_bar_module_1 = require("./../components/loop-progress-bar/loop-progress-bar.module");
const footer_bar_module_1 = require("./../components/footer-bar/footer-bar.module");
const play_tune_routing_module_1 = require("./play-tune-routing.module");
const play_tune_component_1 = require("./play-tune.component");
const shared_module_1 = require("./../shared/shared.module");
const powered_by_row_module_1 = require("../components/powered-by-row/powered-by-row.module");
const i0 = require("@angular/core");
class PlayTuneModule {
    static { this.ɵfac = function PlayTuneModule_Factory(t) { return new (t || PlayTuneModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: PlayTuneModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [shared_module_1.SharedModule,
            play_tune_routing_module_1.PlayTuneRoutingModule,
            footer_bar_module_1.FooterBarModule,
            loop_progress_bar_module_1.LoopProgressBarModule,
            lyrics_module_1.LyricsModule,
            powered_by_row_module_1.PoweredByRowModule] }); }
}
exports.PlayTuneModule = PlayTuneModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(PlayTuneModule, { declarations: [play_tune_component_1.PlayTuneComponent], imports: [shared_module_1.SharedModule,
        play_tune_routing_module_1.PlayTuneRoutingModule,
        footer_bar_module_1.FooterBarModule,
        loop_progress_bar_module_1.LoopProgressBarModule,
        lyrics_module_1.LyricsModule,
        powered_by_row_module_1.PoweredByRowModule], exports: [play_tune_component_1.PlayTuneComponent] }); })();
