"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommaToDotDirective = void 0;
const core_1 = require("@angular/core");
const i0 = require("@angular/core");
class CommaToDotDirective {
    constructor() {
        this.ngModelChange = new core_1.EventEmitter();
    }
    onInputChange(value) {
        value = value.replace(',', '.');
        // Splitting into two string and replacing all the dots (.'s) in the second string
        var firstOccuranceIndex = value.search(/\./) + 1; // Index of first occurance of (.)
        var resultStr = value.substr(0, firstOccuranceIndex) + value.slice(firstOccuranceIndex).replace(/\./g, '');
        //#opt will still brake if trying to make something stupid like pasting text
        this.ngModelChange.emit(Number(value));
    }
    static { this.ɵfac = function CommaToDotDirective_Factory(t) { return new (t || CommaToDotDirective)(); }; }
    static { this.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: CommaToDotDirective, selectors: [["", "appCommaToDot", ""]], hostBindings: function CommaToDotDirective_HostBindings(rf, ctx) { if (rf & 1) {
            i0.ɵɵlistener("input", function CommaToDotDirective_input_HostBindingHandler($event) { return ctx.onInputChange($event.target.value); });
        } }, outputs: { ngModelChange: "ngModelChange" } }); }
}
exports.CommaToDotDirective = CommaToDotDirective;
