"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LyricsModule = void 0;
const common_1 = require("@angular/common");
const lyrics_component_1 = require("./lyrics.component");
const angular_1 = require("@ionic/angular");
const i0 = require("@angular/core");
class LyricsModule {
    static { this.ɵfac = function LyricsModule_Factory(t) { return new (t || LyricsModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: LyricsModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [common_1.CommonModule,
            angular_1.IonicModule] }); }
}
exports.LyricsModule = LyricsModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(LyricsModule, { declarations: [lyrics_component_1.LyricsComponent], imports: [common_1.CommonModule,
        angular_1.IonicModule], exports: [lyrics_component_1.LyricsComponent] }); })();
