"use strict";
// Difference between this file global.ts and global.service.ts is that service.ts stores data .ts
// Are just functions, .ts is similar to helperFuncs.ts but relevant only to this application  
Object.defineProperty(exports, "__esModule", { value: true });
exports.test1 = exports.mGlobal = void 0;
const environment_1 = require("../../environments/environment");
const neverthrow_1 = require("neverthrow");
const enums_shared_enum_1 = require("../../../backend/classes-enums-interfaces-types/enums/enums.shared.enum");
const enums_enum_1 = require("./classes-enums-interfaces-types/enums/enums.enum");
let debugTune;
let debugTunes;
const fadeInDuration = 100; //Update NVC.java
const fadeOutDuration = 300; //Update NVC.java
let shortestTotalTuneLength;
if (environment_1.ENV.DEBUG) {
    //shortestTotalTuneLength = 1000
    shortestTotalTuneLength = fadeOutDuration + fadeInDuration + 500; //Update NVC.java
}
else {
    shortestTotalTuneLength = fadeOutDuration + fadeInDuration + 500; //Update NVC.java
}
const fONoS = 6;
const fINoS = 3;
const test1 = 'ttt';
exports.test1 = test1;
const mGlobal = {
    useWebSdk: false,
    debugTune: debugTune,
    debugTunes: debugTunes,
    //Read in new fadeCurveOptions()
    fadeParams: {
        fadeInDuration: fadeInDuration,
        fadeOutDuration: fadeOutDuration,
        lowestVolumePercentOfOrig: 0.3,
        fadeCurveType: enums_shared_enum_1.FadeCurveTypesE.linear,
        shortestTotalTuneLength: shortestTotalTuneLength,
        fONoS: fONoS,
        fINoS: fINoS,
        fOSP: fadeOutDuration / fONoS,
        fISP: fadeInDuration / fINoS
    },
    isAdmin: !environment_1.ENV.PRODUCTION && true,
    //isAdmin: true, 
    debug: environment_1.ENV.DEBUG,
    isDebugIos: environment_1.ENV.IS_DEBUG_IOS,
    prod: environment_1.ENV.PRODUCTION,
    // assumes tune and not selectedTune $2? Types pls
    validateTune: (tune) => {
        // #Todo also validate on server
        let msg; //= null
        if (!tune.startTime && tune.startTime !== 0) {
            msg = enums_enum_1.TuneValidationError.noStartTime;
        }
        else if (!tune.stopTime) {
            msg = enums_enum_1.TuneValidationError.noStopTime;
        }
        else if (tune.stopTime - tune.startTime < shortestTotalTuneLength) {
            msg = enums_enum_1.TuneValidationError.tooShort;
        }
        else if (!tune?.triggers?.[0]?.triggerSentence && !tune?.triggers?.[0]?.notApplicable) {
            msg = enums_enum_1.TuneValidationError.noTrigger;
        } /* else if(! ((tune?.lyrics?.wordRange?.startIndex || tune?.lyrics?.wordRange?.startIndex===0) || tune?.lyrics?.lyricsStr) ){
            msg = TuneValidationError.noLyrics
        } */
        if (msg)
            return (0, neverthrow_1.err)([msg]);
        /*             throw new MError({
                        mMessage:"Error validating Tune",
                        mReason:msg
                    }) */
        return (0, neverthrow_1.ok)(true);
    },
    userId: '61d18a396f0ce84a84932510',
    userDisplayName: 'user1Name'
    // INLINE https://commons.wikimedia.org/wiki/Category:Images#/media/File:Charles_Tr%C3%BCb_Le_Mont_Fangas.jpg
};
exports.mGlobal = mGlobal;
