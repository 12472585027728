"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MyServerService = void 0;
const enums_enum_1 = require("./../../classes-enums-interfaces-types/enums/enums.enum");
const mhttp_service_1 = require("./../helper-functions/mhttp/mhttp.service");
const environment_1 = require("../../../../environments/environment");
const http_1 = require("@angular/common/http");
const rxjs_1 = require("rxjs");
const player_service_1 = require("../music/player.service");
const http_2 = require("@capacitor-community/http");
const keycodes_1 = require("@angular/cdk/keycodes");
const neverthrow_1 = require("neverthrow");
const angular_1 = require("@ionic/angular");
const interfaces_shared_1 = require("../../../../../backend/classes-enums-interfaces-types/interfaces/interfaces.shared");
const error_handler_service_1 = require("../error-handler/error-handler.service");
const global_service_1 = require("../global.service");
const i0 = require("@angular/core");
const i1 = require("../music/player.service");
const i2 = require("@angular/common/http");
const i3 = require("./../helper-functions/mhttp/mhttp.service");
const i4 = require("@ionic/angular");
const i5 = require("../error-handler/error-handler.service");
const i6 = require("../global.service");
class MyServerService {
    constructor(ps, http, mhttp, toast, eh, gs) {
        this.ps = ps;
        this.http = http;
        this.mhttp = mhttp;
        this.toast = toast;
        this.eh = eh;
        this.gs = gs;
        this.backendRedirectPath = 'backend-redirect-iab';
        this.browsers = [];
    }
    getCategories() {
        return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + "/get-categories");
    }
    getSlimTunesInCat(catParent, catChild, fetchOffset, fetchLimit) {
        // create httpParams from object below
        const params = new http_1.HttpParams()
            .set("catParent", catParent)
            .set("catChild", catChild)
            .set("offset", fetchOffset)
            .set("limit", fetchLimit);
        return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + "/get-slim-tunes-in-cat", { params: params });
    }
    getTracksTuneByTrackId(trackId, sendLyrics, sendStatus) {
        const params = new http_1.HttpParams()
            .set('trackId', trackId);
        if (sendLyrics == true) {
            params.set('sendLyrics', true);
            if (sendStatus) {
                return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/get-tracks-tune-by-track-id', {
                    params: params,
                    //sendStatus : sendStatus
                });
            }
            else {
                return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/get-tracks-tune-by-track-id', {
                    params: params,
                });
            }
        }
        else {
            if (sendStatus) {
                return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/get-tracks-tune-by-track-id', {
                    params: params,
                    observe: 'response'
                });
            }
            else {
                return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/get-tracks-tune-by-track-id', {
                    params: params,
                });
            }
        }
    }
    getLyricsFromTuneId(tuneId) {
        const params = new http_1.HttpParams()
            .set("tuneId", tuneId);
        return this.http.get(`${environment_1.ENV.DOMAIN_OF_BACKEND}/get-lyrics-from-tune-id`, { params: params });
    }
    getLyricsFromTuneTrackId(tuneTrackId) {
        const params = new http_1.HttpParams()
            .set("tuneTrackId", tuneTrackId);
        return this.http.get(`${environment_1.ENV.DOMAIN_OF_BACKEND}/get-lyrics-from-tune-track-id`, { params: params });
    }
    getLyricsFromGeniusFromTrackAndArtistName(trackName, artistName, trackId) {
        const params = new http_1.HttpParams()
            .set("trackName", trackName)
            .set("artistName", artistName)
            .set("trackId", trackId);
        return this.http.get(`${environment_1.ENV.DOMAIN_OF_BACKEND}/get-tracks-lyrics-and-create-tune-track-if-needed`, { params: params, observe: 'response' });
    }
    getTracksFirstTuneByTrackNameAndArtistName(trackName, artistName, sendLyrics, sendStatus) {
        let params = new http_1.HttpParams()
            .set('trackName', trackName)
            .set('artistName', artistName);
        if (sendLyrics == true) {
            params = params.set('sendLyrics', true);
            if (sendStatus) {
                return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/get-tracks-first-tune-by-track-name-and-artist-name', {
                    params: params,
                    observe: 'response'
                });
            }
            else {
                return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/get-tracks-first-tune-by-track-name-and-artist-name', {
                    params: params,
                });
            }
        }
        else {
            if (sendStatus) {
                return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/get-tracks-first-tune-by-track-name-and-artist-name', {
                    params: params,
                    observe: 'response'
                });
            }
            else {
                return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/get-tracks-first-tune-by-track-name-and-artist-name', {
                    params: params,
                });
            }
        }
    }
    getTracksTunesByTrackNameAndArtistName(trackName, artistName, sendStatus) {
        let params = new http_1.HttpParams()
            .set('trackName', trackName)
            .set('artisName', artistName);
        if (sendStatus) {
            return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/get-tracks-tunes-by-track-name-and-artist-name', {
                params: params,
                observe: 'response'
            });
        }
        else {
            return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/get-tracks-tunes-by-track-name-and-artist-name', {
                params: params,
            });
        }
    }
    getTuneById(tuneId) {
        let params = new http_1.HttpParams()
            .set("tuneId", tuneId);
        return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/get-tune-by-id', { params: params });
    }
    getTuneByNumber(tuneNumber, observeRes) {
        const params = new http_1.HttpParams()
            .set('tuneNumber', tuneNumber);
        if (observeRes) {
            return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/get-tune-by-number', { params: params, observe: 'response' });
        }
        else {
            return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/get-tune-by-number', { params: params });
        }
    }
    getTunesWithTag(tagName, observe) {
        // convert CHttpparams to HttpParams
        const params = new http_1.HttpParams()
            .set('tagName', tagName);
        if (observe) {
            return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/get-tunes-with-tag', { params: params, observe: 'response' });
        }
        else {
            return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/get-tunes-with-tag', { params: params });
        }
    }
    getTunesByQuery(queryStr, offset = 0) {
        //query = 'field:value' 
        // let queryObj
        // if(queryStr)
        // 	queryObj = JSON.parse(queryStr)
        // else
        // 	queryObj = null
        const params = new http_1.HttpParams()
            .set('dbQuery', queryStr)
            .set('offset', offset);
        return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/get-tunes-by-query', { params: params });
    }
    //#todo stay in EditTA after save instead of update, return tune instead
    saveTune(tune) {
        const body = {
            accessToken: this.ps.getSpotAccessToken(),
            tune: tune,
        };
        const promise = new Promise((resolve, reject) => {
            this.http.post(environment_1.ENV.DOMAIN_OF_BACKEND + "/save-tune", body)
                .subscribe((tune) => {
                console.log(`tuneNumber: ${tune.tuneNumber}`);
                resolve(tune);
            }, e => {
                console.error("Error server save tune", e);
                this.eh.logSentryError(e);
                reject(e);
            });
        });
        //Opt could error of something else than tuneError
        return neverthrow_1.ResultAsync.fromPromise(promise, (e) => [enums_enum_1.SaveTuneError.saveTuneServerError]);
    }
    updateTune(tuneId, tuneUpdate) {
        const body = {
            tuneId: tuneId,
            tuneUpdate: tuneUpdate
        };
        const promise = new Promise((resolve, reject) => {
            this.http.patch(environment_1.ENV.DOMAIN_OF_BACKEND + "/update-owning-tune", body)
                .subscribe((res) => {
                console.log(`tuneNumber: ${res.tune.tuneNumber}`);
                resolve(res.tune);
            }, e => {
                console.error("Error update tune", e);
                reject('Server Error');
            });
        });
        return neverthrow_1.ResultAsync.fromPromise(promise, (err) => [enums_enum_1.UpdateTuneError.updateTuneError]);
    }
    getContactByDisplayName(contactDisplayName) {
        const params = new http_1.HttpParams()
            .set('contactDisplayName', contactDisplayName);
        return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/get-contact-by-display-name', { params: params });
    }
    initializeConversation(user) {
        return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/initialize-conversation');
    }
    //# decap from Cap Http
    setBackendCookies(cookies, frontendRedirectPath) {
        /*this.http.post(`${ENV.DOMAIN_OF_BACKEND}/set-cookies`,{cookies:cookies},{withCredentials:true})
                .subscribe( () => {
                    console.log('FE, did set cookies BE')
                    this.router.navigateByUrl(frontendRedirectPath)
                    this.closeBrowsers()
                }, (e) => {
                    console.error('FE, error setting cookies BE',e)
                     this.router.navigateByUrl('login')
                     this.closeBrowsers()
                }) */
        const options = {
            url: `${environment_1.ENV.DOMAIN_OF_BACKEND}/set-cookies`,
            data: { cookies: cookies },
        };
        http_2.Http.post(options).then((res) => {
            console.log('cap http plugin ');
            console.log(res);
        }).catch((e) => {
            console.log('cap http plugin error ');
            console.log(keycodes_1.E);
            this.eh.logSentryError(e);
        });
    }
    saveTuneToUserLib(tuneId) {
        this.http.put(`${environment_1.ENV.DOMAIN_OF_BACKEND}/user/save-tune-to-lib`, { tuneId: tuneId }).subscribe(async (res) => {
            console.log(`save-tune-to-lib Res: ${res}`);
            if (typeof res === 'string') {
                this.gs.showToast({ msg: res, duration: 4000 });
            }
        }), e => {
            console.error('Error saving tune to user lib', e);
            this.eh.logSentryError(e);
        };
    }
    getUserTuneLists(offset = 0) {
        const params = new http_1.HttpParams()
            .set('offset', offset);
        return this.http.get(`${environment_1.ENV.DOMAIN_OF_BACKEND}/user/tune-lists`);
    }
    createTuneList(tuneListName, tunes) {
        return this.http.put(`${environment_1.ENV.DOMAIN_OF_BACKEND}/user/tune-list/create`, { tuneListName: tuneListName, tunes: tunes });
    }
    // allow duplicates were allowed but is not anymore post 2408
    addTuneToTuneList(tuneListId, tuneId, observe, allowDuplicate) {
        if (observe) {
            return this.http.post(`${environment_1.ENV.DOMAIN_OF_BACKEND}/user/tune-list/add-tune`, { tuneListId: tuneListId, tuneId: tuneId, allowDuplicate: allowDuplicate }, { observe: 'response' });
        }
        else {
            return this.http.post(`${environment_1.ENV.DOMAIN_OF_BACKEND}/user/tune-list/add-tune`, { tuneListId: tuneListId, tuneId: tuneId, allowDuplicate: allowDuplicate });
        }
    }
    removeTrigger(triggerId) {
        return this.http.patch(`${environment_1.ENV.DOMAIN_OF_BACKEND}/user/remove-trigger`, { triggerId: triggerId });
    }
    removeTuneFromTuneList(tuneListId, tuneId) {
        return this.http.patch(`${environment_1.ENV.DOMAIN_OF_BACKEND}/user/tune-list/remove-tune`, { tuneListId: tuneListId, tuneId: tuneId });
    }
    renameTuneList(tuneListId, tuneListName) {
        return this.http.patch(`${environment_1.ENV.DOMAIN_OF_BACKEND}/user/tune-list/rename`, { tuneListId: tuneListId, tuneListName: tuneListName });
    }
    removeCreatedTuneList(tuneListId) {
        return this.http.delete(`${environment_1.ENV.DOMAIN_OF_BACKEND}/user/tune-list/remove/created`, { params: { tuneListId: tuneListId } });
    }
    removeSavedTuneList(tuneListId) {
        return this.http.delete(`${environment_1.ENV.DOMAIN_OF_BACKEND}/user/tune-list/remove/saved`, { params: { tuneListId: tuneListId } });
    }
    sendFeErrorToServer(body, observeRes = false) {
        if (observeRes) {
            return this.http.post(`${environment_1.ENV.DOMAIN_OF_BACKEND}/log-error-from-fe`, body, { observe: 'response' });
        }
        else {
            return this.http.post(`${environment_1.ENV.DOMAIN_OF_BACKEND}/log-error-from-fe`, body);
        }
    }
    getTuneListByNumber(tuneListNumber, offset = 0, limit = 15, observeRes) {
        const params = new http_1.HttpParams()
            .set('tuneListNumber', tuneListNumber)
            .set('offset', offset)
            .set('limit', limit);
        if (observeRes) {
            return this.http.get(`${environment_1.ENV.DOMAIN_OF_BACKEND}/get-tune-list-by-number`, { observe: 'response', params: params });
        }
        else {
            return this.http.get(`${environment_1.ENV.DOMAIN_OF_BACKEND}/get-tune-list-by-number`, { params: params });
        }
    }
    saveTuneList(tuneListId, observeRes = false) {
        const body = {
            tuneListId: tuneListId,
        };
        if (observeRes) {
            return this.http.post(`${environment_1.ENV.DOMAIN_OF_BACKEND}/user/tune-lists/save`, body, { observe: 'response' });
        }
        else {
            return this.http.post(`${environment_1.ENV.DOMAIN_OF_BACKEND}/user/tune-lists/save`, body);
        }
    }
    getUsersTriggers(offset = 0, limit = 10) {
        const params = new http_1.HttpParams()
            .set('offset', offset)
            .set('limit', limit);
        return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/user/triggers', { params: params });
    }
    getUsersTriggersFromSearch(query, offsetTuneId = null) {
        let params = new http_1.HttpParams()
            .set('searchQuery', query);
        if (offsetTuneId)
            params = params.set('offsetTuneId', offsetTuneId);
        return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/user/triggers-search', { params: params });
    }
    getTrackTunes(trackId) {
        const params = new http_1.HttpParams()
            .set('trackId', trackId);
        return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/track/tunes', { params: params });
    }
    getTrackHasMultipleTunes(spotTrackId) {
        const params = new http_1.HttpParams()
            .set('spotTrackId', spotTrackId);
        return this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/track/has-multiple-tunes', { params: params });
    }
    requestTuneListReorder(tuneListId, tuneArrayWithIdsReordered) {
        const body = {
            tuneListId: tuneListId,
            tuneArrayWithIdsReordered: tuneArrayWithIdsReordered
        };
        return this.http.patch(environment_1.ENV.DOMAIN_OF_BACKEND + '/user/tune-list/reorder', body, { observe: 'response' });
    }
    static { this.ɵfac = function MyServerService_Factory(t) { return new (t || MyServerService)(i0.ɵɵinject(i1.PlayerService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.MHttpService), i0.ɵɵinject(i4.ToastController), i0.ɵɵinject(i5.ErrorHandlerService), i0.ɵɵinject(i6.GlobalService)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: MyServerService, factory: MyServerService.ɵfac, providedIn: 'root' }); }
}
exports.MyServerService = MyServerService;
