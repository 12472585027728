"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FooterBarModule = exports.SaveSendAction = void 0;
const footer_bar_routing_module_1 = require("./footer-bar-routing.module");
const angular_1 = require("@ionic/angular");
const footer_bar_component_1 = require("./footer-bar.component");
const common_1 = require("@angular/common");
const i0 = require("@angular/core");
var SaveSendAction;
(function (SaveSendAction) {
    SaveSendAction["save"] = "SAVE";
    SaveSendAction["send"] = "SEND";
    SaveSendAction["saveAndSend"] = "SAVE_AND_SEND";
})(SaveSendAction = exports.SaveSendAction || (exports.SaveSendAction = {}));
class FooterBarModule {
    static { this.ɵfac = function FooterBarModule_Factory(t) { return new (t || FooterBarModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: FooterBarModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [common_1.CommonModule,
            angular_1.IonicModule,
            footer_bar_routing_module_1.FooterBarRoutingModule] }); }
}
exports.FooterBarModule = FooterBarModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(FooterBarModule, { declarations: [footer_bar_component_1.FooterBarComponent], imports: [common_1.CommonModule,
        angular_1.IonicModule,
        footer_bar_routing_module_1.FooterBarRoutingModule], exports: [footer_bar_component_1.FooterBarComponent] }); })();
