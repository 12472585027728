"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findTuneRouteGuard = void 0;
const core_1 = require("@angular/core");
const router_1 = require("@angular/router");
const find_tune_state_service_1 = require("../../services/states/find-tune/find-tune-state.service");
const enums_enum_1 = require("../../classes-enums-interfaces-types/enums/find-tune/enums.enum");
const findTuneRouteGuard = (activeRoute, state) => {
    const hasSubPath = activeRoute.children[0]?.url?.length; //? '' : activeRoute.children[0].url[0].path
    if (hasSubPath) { // '' -> falsey
        return true;
    }
    else {
        let urlTree;
        const router = (0, core_1.inject)(router_1.Router);
        const findTuneService = (0, core_1.inject)(find_tune_state_service_1.FindTuneStateService);
        switch (findTuneService.segment) {
            case enums_enum_1.SegmentStatesEnum.search:
                //#opt return url tree instead for proper ng, tried once was irritating
                //this.router.navigateByUrl('/find-tune/search')
                urlTree = router.createUrlTree(['find-tune', 'search']);
                return urlTree;
                break;
            case enums_enum_1.SegmentStatesEnum.select:
                urlTree = router.createUrlTree(['find-tune', 'select']);
                return urlTree;
                break;
            default:
                //router.navigateByUrl('/find-tune/search')
                break;
        }
    }
};
exports.findTuneRouteGuard = findTuneRouteGuard;
