"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FunnelComponent = void 0;
const i0 = require("@angular/core");
class FunnelComponent {
    constructor() { }
    ngOnInit() {
    }
    funnelToMessengerClick() {
        //this.fb.
    }
    static { this.ɵfac = function FunnelComponent_Factory(t) { return new (t || FunnelComponent)(); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: FunnelComponent, selectors: [["app-funnel"]], decls: 2, vars: 0, consts: [[3, "click"]], template: function FunnelComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "button", 0);
            i0.ɵɵlistener("click", function FunnelComponent_Template_button_click_0_listener() { return ctx.funnelToMessengerClick(); });
            i0.ɵɵtext(1, " Share in Messenger\n");
            i0.ɵɵelementEnd();
        } } }); }
}
exports.FunnelComponent = FunnelComponent;
