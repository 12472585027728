"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoopProgressBarModule = void 0;
const common_1 = require("@angular/common");
const loop_progress_bar_component_1 = require("./loop-progress-bar.component");
const angular_1 = require("@ionic/angular");
const i0 = require("@angular/core");
class LoopProgressBarModule {
    static { this.ɵfac = function LoopProgressBarModule_Factory(t) { return new (t || LoopProgressBarModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: LoopProgressBarModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [common_1.CommonModule,
            angular_1.IonicModule] }); }
}
exports.LoopProgressBarModule = LoopProgressBarModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(LoopProgressBarModule, { declarations: [loop_progress_bar_component_1.LoopProgressBarComponent], imports: [common_1.CommonModule,
        angular_1.IonicModule], exports: [loop_progress_bar_component_1.LoopProgressBarComponent] }); })();
