"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FcmService = void 0;
const global_service_1 = require("./../global.service");
const environment_1 = require("../../../../environments/environment");
//import { AngularFireMessaging } from '@angular/fire/messaging';
const http_1 = require("@angular/common/http");
const push_notifications_1 = require("@capacitor/push-notifications");
const rxjs_1 = require("rxjs");
const i0 = require("@angular/core");
const i1 = require("@angular/common/http");
const i2 = require("./../global.service");
class FcmService {
    constructor(
    //private afMessaging: AngularFireMessaging,
    http, gS) {
        this.http = http;
        this.gS = gS;
        this.incomingNotification$ = new rxjs_1.Subject();
    }
    /*
        initFcm(){
            this.listen()
        }

        listen() {
            this.afMessaging.messages
            .subscribe((message) => { console.log(message); });
        }
    
        async requestPushPermission() : Promise<any> {
            console.log('break')
            this.afMessaging.requestToken
            .subscribe(
                (token) => {
                    console.log('Permission granted! Save to the server!', token);
                    this.registationToken = token
                    return 'ok'
                },
                (error) => {
                    console.error(error);
                    return error
                },
            );
        }

        getAfMessaging() : any {
            return this.afMessaging
        }
    */
    initFcm() {
        if (this.gS.isNativeCapPlatform()) {
            this.addListenersasync();
        }
    }
    async addListenersasync() {
        await push_notifications_1.PushNotifications.addListener('registration', token => {
            this.registationToken = token.value;
            console.info('Registration token: ', token.value);
        });
        await push_notifications_1.PushNotifications.addListener('registrationError', err => {
            console.error('Registration error: ', err.error);
        });
        await push_notifications_1.PushNotifications.addListener('pushNotificationReceived', notification => {
            console.log('Push notification received: ', notification);
            if (notification.data.type == 'message') {
                this.incomingNotification$.next(notification);
                this.cachedChatListMessages.push(notification);
            }
        });
        await push_notifications_1.PushNotifications.addListener('pushNotificationActionPerformed', notification => {
            console.log('Push notification action performed', notification.actionId, notification.inputValue);
        });
    }
    async registerNotifications() {
        let permStatus = await push_notifications_1.PushNotifications.checkPermissions();
        if (permStatus.receive === 'prompt') {
            permStatus = await push_notifications_1.PushNotifications.requestPermissions();
        }
        if (permStatus.receive !== 'granted') {
            throw new Error('User denied permissions!');
        }
        await push_notifications_1.PushNotifications.register();
    }
    async getDeliveredNotifications() {
        const notificationList = await push_notifications_1.PushNotifications.getDeliveredNotifications();
        console.log('delivered notifications', notificationList);
    }
    requestReturnPushMsgFcm() {
        const body = {
            registationToken: this.registationToken
        };
        this.http.put(environment_1.ENV.DOMAIN_OF_BACKEND + '/return-test-fcm-message', body).subscribe((res) => {
            console.log(res);
        }, e => {
            console.error(e);
        });
    }
    getCachedChatListMessages() {
        return this.cachedChatListMessages;
        this.cachedChatListMessages = [];
    }
    static { this.ɵfac = function FcmService_Factory(t) { return new (t || FcmService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.GlobalService)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: FcmService, factory: FcmService.ɵfac, providedIn: 'root' }); }
}
exports.FcmService = FcmService;
