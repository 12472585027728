"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MyMetaService = void 0;
const platform_browser_1 = require("@angular/platform-browser");
const i0 = require("@angular/core");
const i1 = require("@angular/platform-browser");
class MyMetaService {
    constructor(meta) {
        this.meta = meta;
    }
    mAddMeta() {
        this.meta.addTags([
            { name: 'og:title', content: 'Tune it' },
            { name: 'og:description', content: 'Description' },
            { name: 'og-image', content: 'https://trudd-ae.ey.r.appspot.com/logo' },
            { name: 'og-url', content: 'https://trudd-ae.ey.r.appspot.com' }
        ]);
    }
    static { this.ɵfac = function MyMetaService_Factory(t) { return new (t || MyMetaService)(i0.ɵɵinject(i1.Meta)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: MyMetaService, factory: MyMetaService.ɵfac, providedIn: 'root' }); }
}
exports.MyMetaService = MyMetaService;
