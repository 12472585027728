"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FindTunePageModule = void 0;
const pwa_install_prompt_ios_module_1 = require("./../components/pwa-install-prompt/ios/pwa-install-prompt-ios.module");
const find_tune_routing_module_1 = require("./find-tune-routing.module");
const forms_1 = require("@angular/forms");
const icon_1 = require("@angular/material/icon");
const footer_bar_module_1 = require("./../components/footer-bar/footer-bar.module");
const category_handler_module_1 = require("./category-handler/category-handler.module");
const selected_track_module_1 = require("../components/selected-track/selected-track.module");
const find_tune_page_1 = require("./find-tune.page");
const search_handler_module_1 = require("./search-handler/search-handler.module");
const button_1 = require("@angular/material/button");
const loop_progress_bar_module_1 = require("../components/loop-progress-bar/loop-progress-bar.module");
const lyrics_module_1 = require("../components/lyrics/lyrics.module");
const shared_module_1 = require("../shared/shared.module");
const i0 = require("@angular/core");
class FindTunePageModule {
    static { this.ɵfac = function FindTunePageModule_Factory(t) { return new (t || FindTunePageModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: FindTunePageModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [find_tune_routing_module_1.FindTuneRoutingModule,
            shared_module_1.SharedModule,
            selected_track_module_1.SelectedTrackModule,
            category_handler_module_1.CategoryHandlerModule,
            footer_bar_module_1.FooterBarModule,
            search_handler_module_1.SearchHandlerModule,
            icon_1.MatIconModule,
            button_1.MatButtonModule,
            forms_1.FormsModule,
            loop_progress_bar_module_1.LoopProgressBarModule,
            lyrics_module_1.LyricsModule,
            pwa_install_prompt_ios_module_1.PwaInstallPromptIosModule] }); }
}
exports.FindTunePageModule = FindTunePageModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(FindTunePageModule, { declarations: [find_tune_page_1.FindTunePage], imports: [find_tune_routing_module_1.FindTuneRoutingModule,
        shared_module_1.SharedModule,
        selected_track_module_1.SelectedTrackModule,
        category_handler_module_1.CategoryHandlerModule,
        footer_bar_module_1.FooterBarModule,
        search_handler_module_1.SearchHandlerModule,
        icon_1.MatIconModule,
        button_1.MatButtonModule,
        forms_1.FormsModule,
        loop_progress_bar_module_1.LoopProgressBarModule,
        lyrics_module_1.LyricsModule,
        pwa_install_prompt_ios_module_1.PwaInstallPromptIosModule], exports: [find_tune_page_1.FindTunePage] }); })();
