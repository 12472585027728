"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WaitListPage = void 0;
const http_1 = require("@angular/common/http");
const environment_1 = require("../../../environments/environment");
const global_service_1 = require("../services/global.service");
const enums_enum_1 = require("../classes-enums-interfaces-types/enums/enums.enum");
const error_handler_service_1 = require("../services/error-handler/error-handler.service");
const i0 = require("@angular/core");
const i1 = require("@angular/common/http");
const i2 = require("../services/global.service");
const i3 = require("../services/error-handler/error-handler.service");
const i4 = require("@angular/forms");
const i5 = require("@ionic/angular");
class WaitListPage {
    constructor(http, gs, eh) {
        this.http = http;
        this.gs = gs;
        this.eh = eh;
        this.logoSrc = `${environment_1.ENV.DOMAIN_OF_BACKEND}/logo/subtitle`;
        this.email = '';
        this.version = environment_1.ENV.DEPLOY_VERSION_MAJOR + '.' + environment_1.ENV.DEPLOY_VERSION_MINOR;
    }
    ngOnInit() {
    }
    sendClicked() {
        if (this.email) {
            console.log('sendClicked');
            this.http.post(`${environment_1.ENV.DOMAIN_OF_BACKEND}/wait-list-sign-up`, { email: this.email }, { observe: 'response' }).subscribe(async (res) => {
                if (res.status == 200) {
                    this.gs.showToast({
                        type: enums_enum_1.ToastEnum.success,
                        msg: 'You have been signed up to the wait list! No confirmation email will be sent, fyi',
                        duration: 7000
                    });
                }
            }, async (e) => {
                console.error(e);
                this.eh.logSentryError(e);
                this.gs.showToast({
                    msg: 'Error signing up for wait list',
                    duration: 7000,
                    type: enums_enum_1.ToastEnum.error
                });
            });
        }
    }
    static { this.ɵfac = function WaitListPage_Factory(t) { return new (t || WaitListPage)(i0.ɵɵdirectiveInject(i1.HttpClient), i0.ɵɵdirectiveInject(i2.GlobalService), i0.ɵɵdirectiveInject(i3.ErrorHandlerService)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: WaitListPage, selectors: [["app-wait-list"]], decls: 17, vars: 2, consts: [["lines", "full"], ["label", "", "type", "text", "placeholder", "Type email here", 3, "ngModel", "ngModelChange"], ["id", "buttonRow"], [3, "click"], ["id", "imgRow"], [3, "src"]], template: function WaitListPage_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "ion-header")(1, "ion-toolbar")(2, "ion-title");
            i0.ɵɵtext(3, "Tune it wait list");
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(4, "ion-content")(5, "ion-grid")(6, "ion-row")(7, "ion-card");
            i0.ɵɵtext(8, " Enter your email below to be notified when a stable-ish version of Tune it is launched! ");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(9, "ion-row")(10, "ion-item", 0)(11, "ion-input", 1);
            i0.ɵɵlistener("ngModelChange", function WaitListPage_Template_ion_input_ngModelChange_11_listener($event) { return ctx.email = $event; });
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(12, "ion-row", 2)(13, "ion-button", 3);
            i0.ɵɵlistener("click", function WaitListPage_Template_ion_button_click_13_listener() { return ctx.sendClicked(); });
            i0.ɵɵtext(14, " SEND ");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(15, "ion-row", 4);
            i0.ɵɵelement(16, "ion-img", 5);
            i0.ɵɵelementEnd()()();
        } if (rf & 2) {
            i0.ɵɵadvance(11);
            i0.ɵɵproperty("ngModel", ctx.email);
            i0.ɵɵadvance(5);
            i0.ɵɵpropertyInterpolate("src", ctx.logoSrc);
        } }, dependencies: [i4.NgControlStatus, i4.NgModel, i5.IonButton, i5.IonCard, i5.IonContent, i5.IonGrid, i5.IonHeader, i5.IonImg, i5.IonInput, i5.IonItem, i5.IonRow, i5.IonTitle, i5.IonToolbar, i5.TextValueAccessor], styles: ["ion-grid[_ngcontent-%COMP%] {\n  display: flex;\n  align-content: center;\n  justify-content: center;\n  flex-direction: column;\n  height: 90dvh;\n  width: 90vw;\n}\n\nion-header[_ngcontent-%COMP%] {\n  height: 10dvh;\n}\n\nion-row[_ngcontent-%COMP%] {\n  text-align: center;\n  line-height: 1.5;\n  display: flex;\n  justify-content: center;\n}\n\n#buttonRow[_ngcontent-%COMP%] {\n  margin-top: 2dvh;\n}\n\nion-item[_ngcontent-%COMP%] {\n  margin-top: 3dvh;\n  --border-color:white ;\n}\n\nion-card[_ngcontent-%COMP%] {\n  width: 90vw;\n  padding: 3dvh;\n  font-size: large;\n  --color:var(--ion-text-color);\n  border-color: var(--ion-color-primary);\n  border-style: solid;\n  border-width: 2px;\n}\n\nion-input[_ngcontent-%COMP%] {\n  --border-style:solid;\n  --border-color:red;\n  --border-width:3px;\n  width: 80vw;\n}\n\n#imgRow[_ngcontent-%COMP%] {\n  align-self: center;\n  justify-self: flex-start;\n  width: 80%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  flex-grow: 1;\n  max-height: 40dvh;\n}"] }); }
}
exports.WaitListPage = WaitListPage;
