"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mCanActivateService = void 0;
const auth_service_1 = require("./auth/auth.service");
const my_server_service_1 = require("../services/my-server/my-server.service");
const router_1 = require("@angular/router");
const global_service_1 = require("./global.service");
const environment_1 = require("../../../environments/environment");
const common_1 = require("@angular/common");
const ngx_cookie_service_1 = require("ngx-cookie-service");
const player_service_1 = require("./music/player.service");
const helper_functions_service_1 = require("./helper-functions/helper-functions.service");
const rxjs_1 = require("rxjs");
const find_tune_state_service_1 = require("./states/find-tune/find-tune-state.service");
const i0 = require("@angular/core");
const i1 = require("@angular/router");
const i2 = require("@angular/common");
const i3 = require("./music/player.service");
const i4 = require("./helper-functions/helper-functions.service");
const i5 = require("../services/my-server/my-server.service");
const i6 = require("./auth/auth.service");
const i7 = require("ngx-cookie-service");
const i8 = require("./global.service");
const i9 = require("./states/find-tune/find-tune-state.service");
/*
preserveFragment?: boolean
check if last was this navication
eager / deferead loading
onSameUrlNavigation <-
return url tree without fragment
1) check if contains fragment consume it, 2) send new url tree without
for now before login handle, just check for route then fragment
*/
class mCanActivateService {
    constructor(router, location, ps, hF, mServer, auth, cookieService, gS, findTuneService) {
        this.router = router;
        this.location = location;
        this.ps = ps;
        this.hF = hF;
        this.mServer = mServer;
        this.auth = auth;
        this.cookieService = cookieService;
        this.gS = gS;
        this.findTuneService = findTuneService;
        this.prevNav = null;
        this.freshSpotTokenFetched$ = new rxjs_1.BehaviorSubject({
            displayName: ''
        });
    }
    async canActivate(routeSnapshot, state) {
        //return true
        /*
        better to use snapshout and state inputs than router as it is not updateed yet
        state is field of snapshot
         
        //Dont want apks out there with infinite permission or have to change key in spot dev dashboard
        if(this.gS.isNativeCapPlatform()){
            //TODO handle error
            this.sd.setSpotAccessToken((await NativeVolumeControl.getSpotAccessToken()).spotAccessToken)
            console.log(`spotAccessToken: ${this.sd.getSpotAccessToken()}`)
            return true
        }  */
        // if(ENV.DEBUG)
        //     return true
        //return true
        //let toppath : string =  state.url 
        const topPath = "/" + routeSnapshot.url[0].path;
        console.log(environment_1.ENV);
        /*if(!ENV.PRODUCTION && ENV.DOMAIN_OF_FRONTEND == 'http://localhost:8100' && routeSnapshot.fragment ){
            const fragment : string = routeSnapshot.fragment!
            const spotTokens = new URLSearchParams(fragment).get('spotTokens')
            this.cookieService.put('spotTokens', spotTokens)
        } */
        console.log('got to mCanActivate');
        console.log(this.router.url);
        console.log(topPath);
        /* if (path == '/dev') {
            return true
        } */
        if (topPath.includes('/sign-up')) {
            return true;
        }
        else if (topPath.includes('/play-tune')) {
            if (this.ps.isCurrentSpotTokenValid()) {
                // console.log('got to delay init spot')
                // setTimeout( () => {
                // 	this.ps.initSpotifyPlayer()
                // }, 7000 )
                this.ps.initSpotifyPlayer();
                return true;
            }
            else {
                let queryParams = routeSnapshot.queryParams;
                const tuneNumber = queryParams?.tune;
                if (!tuneNumber)
                    throw ('No Tune number avaiable for play tune route');
                if (!this.ps.getSpotAccessToken()) {
                    this.auth.login({ redirectPath: topPath, tune: tuneNumber, calledFromGui: true });
                }
                else if (this.ps.hasCurrentSpotTokenExpired()) {
                    this.auth.login({ redirectPath: topPath, tune: tuneNumber });
                }
            }
        }
        else if (topPath.includes('/tune-list')) {
            if (this.ps.isCurrentSpotTokenValid()) {
                // console.log('got to delay init spot')
                // setTimeout( () => {
                // 	this.ps.initSpotifyPlayer()
                // }, 7000 )
                this.ps.initSpotifyPlayer();
                return true;
            }
            else {
                let queryParams = routeSnapshot.queryParams;
                const tuneListNumber = queryParams?.list;
                if (!tuneListNumber)
                    throw ('No Tune list number avaiable for play tune route');
                if (!this.ps.getSpotAccessToken()) {
                    this.auth.login({ redirectPath: topPath, list: tuneListNumber, calledFromGui: true });
                }
                else if (this.ps.hasCurrentSpotTokenExpired()) {
                    this.auth.login({ redirectPath: topPath, list: tuneListNumber });
                }
            }
        }
        else {
            // if(ENV.DEBUG){
            // 	return true
            // }
            if (!this.cookieService.get('isSignedUp')) {
                if (this.gS.isRunningAsPwa()) {
                    this.router.navigateByUrl('/sign-up');
                }
                else {
                    this.router.navigateByUrl('/sign-up');
                    //this.router.navigateByUrl('/wait-list')
                }
            }
            else if (this.ps.isCurrentSpotTokenValid()) {
                console.log(`spotAccessToken fethed: ${this.ps.getSpotAccessToken()}`);
                this.ps.initSpotifyPlayer();
                // setTimeout( () => {
                // 	this.ps.initSpotifyPlayer()
                // },7000)
                this.freshSpotTokenFetched$.next({
                    displayName: this.cookieService.get('displayName')
                });
                this.gS.setSpotIdFromCookie();
                return true;
                //this.router.navigateByUrl('/login')
            }
            else {
                this.ps.setWebSdkPlayerNeedConstruction(true);
                this.auth.login({ redirectPath: topPath });
                //this.router.navigateByUrl('/login')
            }
        }
    }
    static { this.ɵfac = function mCanActivateService_Factory(t) { return new (t || mCanActivateService)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Location), i0.ɵɵinject(i3.PlayerService), i0.ɵɵinject(i4.HelperFunctionsService), i0.ɵɵinject(i5.MyServerService), i0.ɵɵinject(i6.AuthService), i0.ɵɵinject(i7.CookieService), i0.ɵɵinject(i8.GlobalService), i0.ɵɵinject(i9.FindTuneStateService)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: mCanActivateService, factory: mCanActivateService.ɵfac, providedIn: 'root' }); }
}
exports.mCanActivateService = mCanActivateService;
