"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const __NgCli_bootstrap_1 = require("@angular/platform-browser");
const core_1 = require("@angular/core");
const app_module_1 = require("./app/app.module");
const environment_1 = require(".././environments/environment");
const error_handler_service_1 = require("./app/services/error-handler/error-handler.service");
const Sentry = require("@sentry/angular-ivy");
if (environment_1.ENV.PRODUCTION) {
    (0, core_1.enableProdMode)();
}
//enableProdMode();
__NgCli_bootstrap_1.platformBrowser()
    .bootstrapModule(app_module_1.AppModule)
    .then(ref => {
    let customLogItr = 0;
    // ['log','debug','info','warn','error'].forEach(function (verb) {
    // console[verb] = (function (method, verb) {
    //     return function () {
    //         method.apply(console, arguments);
    // 		const log = document.querySelector('#log');
    // 		if(log){
    // 			var msg = document.createElement('div');
    // 			msg.classList.add(...[verb, 'custom-log-message']);
    // 			if(verb != 'log'){
    // 				msg.textContent = customLogItr +  ' ' + verb + ': ' + Array.prototype.slice.call(arguments).join(' ');
    // 			} else {
    // 				msg.textContent = customLogItr +  ' ' + Array.prototype.slice.call(arguments).join(' ');
    // 			}
    // 			log.appendChild(msg);
    // 		}
    // 		customLogItr++
    //     };
    // 	})(console[verb], verb);
    // });
    Sentry.init({
        // Called for message and error events
        beforeSend: async (event) => {
            // Modify or drop the event here
            try {
                const eh = ref.injector.get(error_handler_service_1.ErrorHandlerService);
                //const tuneItContext : IFeLogErrorSentry | IFeSetSentryContextError = await eh.getTuneItSentryContext()
                // buggy PoS should work, known problem
                //Sentry.setContext("tuneItContext", tuneItContext); 
                const tuneItContext = await eh.getTuneItSentryContext();
                event.contexts.tuneItContext = tuneItContext;
                return event;
            }
            catch (e) {
                console.error("Sentry beforeSend error", e);
            }
        },
        dsn: environment_1.ENV.SENTRY_DSN,
        integrations: [
            // Registers and configures the Tracing integration,
            // which automatically instruments your application to monitor its
            // performance, including custom Angular routing instrumentation
            /* new Sentry.BrowserTracing({
                //tracePropagationTargets: ["localhost", "https:tune-it.app"],
                routingInstrumentation: Sentry.routingInstrumentation,
            }), */
            // Registers the Replay integration,
            // which automatically captures Session Replays
            new Sentry.Replay({
                // Additional SDK configuration goes in here, for example:
                maskAllText: false,
                blockAllMedia: false,
                maskAllInputs: false,
                block: [],
                mask: [],
                ignore: []
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.0,
        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.0,
        replaysOnErrorSampleRate: 1.0,
        normalizeDepth: 10
    });
    success => console.log(`Bootstrap Sentry success`);
})
    .catch(err => console.error("Boostrap Sentry error", err));
