"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppStateService = void 0;
const rxjs_1 = require("rxjs");
const i0 = require("@angular/core");
class AppStateService {
    constructor() {
        this.appStateActive$ = new rxjs_1.Subject();
    }
    appStateActiveChange(isActive) {
        this.appStateActive$.next(isActive);
    }
    static { this.ɵfac = function AppStateService_Factory(t) { return new (t || AppStateService)(); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: AppStateService, factory: AppStateService.ɵfac, providedIn: 'root' }); }
}
exports.AppStateService = AppStateService;
