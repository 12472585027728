"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RenameTuneListComponent = void 0;
const angular_1 = require("@ionic/angular");
const enums_enum_1 = require("../../../classes-enums-interfaces-types/enums/enums.enum");
const i0 = require("@angular/core");
const i1 = require("@ionic/angular");
const i2 = require("@angular/forms");
class RenameTuneListComponent {
    constructor(modalCtrl) {
        this.modalCtrl = modalCtrl;
    }
    ngOnInit() { }
    confirmRename() {
        return this.modalCtrl.dismiss(this.updatedListName, 'confirm', enums_enum_1.ModalsE.renameTuneList);
    }
    cancelRename() {
        this.modalCtrl.dismiss(null, 'cancel', enums_enum_1.ModalsE.renameTuneList);
    }
    static { this.ɵfac = function RenameTuneListComponent_Factory(t) { return new (t || RenameTuneListComponent)(i0.ɵɵdirectiveInject(i1.ModalController)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: RenameTuneListComponent, selectors: [["app-rename-tune-list"]], decls: 13, vars: 2, consts: [["id", "container-div"], ["slot", "start"], ["color", "medium", 3, "click"], ["slot", "end"], ["color", "primary", 3, "strong", "click"], ["id", "list-name-grid"], ["id", "input-row"], ["placeholder", "List name", "aria-label", "List name", "inputmode", "text", 3, "ngModel", "ngModelChange"]], template: function RenameTuneListComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "ion-toolbar")(2, "ion-buttons", 1)(3, "ion-button", 2);
            i0.ɵɵlistener("click", function RenameTuneListComponent_Template_ion_button_click_3_listener() { return ctx.cancelRename(); });
            i0.ɵɵtext(4, "Cancel");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(5, "ion-title");
            i0.ɵɵtext(6, "Rename");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(7, "ion-buttons", 3)(8, "ion-button", 4);
            i0.ɵɵlistener("click", function RenameTuneListComponent_Template_ion_button_click_8_listener() { return ctx.confirmRename(); });
            i0.ɵɵtext(9, "Done");
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(10, "ion-grid", 5)(11, "ion-row", 6)(12, "ion-input", 7);
            i0.ɵɵlistener("ngModelChange", function RenameTuneListComponent_Template_ion_input_ngModelChange_12_listener($event) { return ctx.updatedListName = $event; });
            i0.ɵɵelementEnd()()()();
        } if (rf & 2) {
            i0.ɵɵadvance(8);
            i0.ɵɵproperty("strong", true);
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("ngModel", ctx.updatedListName);
        } }, dependencies: [i2.NgControlStatus, i2.NgModel, i1.IonButton, i1.IonButtons, i1.IonGrid, i1.IonInput, i1.IonRow, i1.IonTitle, i1.IonToolbar, i1.TextValueAccessor], styles: ["a[_ngcontent-%COMP%]   #list-name-item[_ngcontent-%COMP%] {\n  margin: 2dvh;\n}\n\nion-row[_ngcontent-%COMP%] {\n  justify-content: center;\n}\n\nion-grid[_ngcontent-%COMP%] {\n  justify-content: center;\n}\n\nion-input[_ngcontent-%COMP%] {\n  --highlight-color-focused:green;\n  --placeholder-font-style:italic;\n  font-size: larger;\n  --highlight-color-focused:var(--ion-color-primary);\n  --color:var(--ion-text-color);\n  margin-left: 3dvw;\n  margin-right: 3dvw;\n  text-align: center;\n}"] }); }
}
exports.RenameTuneListComponent = RenameTuneListComponent;
