"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectedTrackModule = void 0;
const selected_track_routing_module_1 = require("./selected-track-routing.module");
const common_1 = require("@angular/common");
const angular_1 = require("@ionic/angular");
const selected_track_component_1 = require("./selected-track.component");
const directives_module_1 = require("../../directives/directives.module");
const powered_by_row_module_1 = require("../powered-by-row/powered-by-row.module");
const i0 = require("@angular/core");
class SelectedTrackModule {
    static { this.ɵfac = function SelectedTrackModule_Factory(t) { return new (t || SelectedTrackModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: SelectedTrackModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [common_1.CommonModule,
            angular_1.IonicModule,
            selected_track_routing_module_1.SelectedTrackRoutingModule,
            directives_module_1.DirectivesModule,
            powered_by_row_module_1.PoweredByRowModule] }); }
}
exports.SelectedTrackModule = SelectedTrackModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(SelectedTrackModule, { declarations: [selected_track_component_1.SelectedTrackComponent], imports: [common_1.CommonModule,
        angular_1.IonicModule,
        selected_track_routing_module_1.SelectedTrackRoutingModule,
        directives_module_1.DirectivesModule,
        powered_by_row_module_1.PoweredByRowModule], exports: [selected_track_component_1.SelectedTrackComponent] }); })();
