"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KnownShortcomingsPageModule = void 0;
const footer_bar_module_1 = require("./../components/footer-bar/footer-bar.module");
const common_1 = require("@angular/common");
const forms_1 = require("@angular/forms");
const angular_1 = require("@ionic/angular");
const known_shortcomings_routing_module_1 = require("./known-shortcomings-routing.module");
const known_shortcomings_page_1 = require("./known-shortcomings.page");
const i0 = require("@angular/core");
class KnownShortcomingsPageModule {
    static { this.ɵfac = function KnownShortcomingsPageModule_Factory(t) { return new (t || KnownShortcomingsPageModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: KnownShortcomingsPageModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [common_1.CommonModule,
            forms_1.FormsModule,
            angular_1.IonicModule,
            known_shortcomings_routing_module_1.KnownShortcomingsPageRoutingModule,
            footer_bar_module_1.FooterBarModule] }); }
}
exports.KnownShortcomingsPageModule = KnownShortcomingsPageModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(KnownShortcomingsPageModule, { declarations: [known_shortcomings_page_1.KnownShortcomingsPage], imports: [common_1.CommonModule,
        forms_1.FormsModule,
        angular_1.IonicModule,
        known_shortcomings_routing_module_1.KnownShortcomingsPageRoutingModule,
        footer_bar_module_1.FooterBarModule] }); })();
