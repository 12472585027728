"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditTuneModule = void 0;
const loop_progress_bar_module_1 = require("../components/loop-progress-bar/loop-progress-bar.module");
const directives_module_1 = require("../directives/directives.module");
const footer_bar_module_1 = require("../components/footer-bar/footer-bar.module");
const icon_1 = require("@angular/material/icon");
const edit_tune_routing_module_1 = require("./edit-tune-routing.module");
const edit_tune_page_1 = require("./edit-tune.page");
const shared_module_1 = require("../shared/shared.module");
const radio_1 = require("@angular/material/radio");
const checkbox_1 = require("@angular/material/checkbox");
const select_1 = require("@angular/material/select");
const text_field_1 = require("@angular/cdk/text-field");
const forms_1 = require("@angular/forms");
const slider_1 = require("@angular/material/slider");
const chips_1 = require("@angular/material/chips");
const accordion_1 = require("@angular/cdk/accordion");
const powered_by_row_module_1 = require("../components/powered-by-row/powered-by-row.module");
const i0 = require("@angular/core");
class EditTuneModule {
    static { this.ɵfac = function EditTuneModule_Factory(t) { return new (t || EditTuneModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: EditTuneModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [shared_module_1.SharedModule,
            edit_tune_routing_module_1.EditTuneRoutingModule,
            radio_1.MatRadioModule,
            checkbox_1.MatCheckboxModule,
            select_1.MatSelectModule,
            text_field_1.TextFieldModule,
            forms_1.FormsModule,
            slider_1.MatSliderModule,
            chips_1.MatChipsModule,
            icon_1.MatIconModule,
            footer_bar_module_1.FooterBarModule,
            accordion_1.CdkAccordionModule,
            loop_progress_bar_module_1.LoopProgressBarModule,
            directives_module_1.DirectivesModule,
            powered_by_row_module_1.PoweredByRowModule] }); }
}
exports.EditTuneModule = EditTuneModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(EditTuneModule, { declarations: [edit_tune_page_1.EditTunePage], imports: [shared_module_1.SharedModule,
        edit_tune_routing_module_1.EditTuneRoutingModule,
        radio_1.MatRadioModule,
        checkbox_1.MatCheckboxModule,
        select_1.MatSelectModule,
        text_field_1.TextFieldModule,
        forms_1.FormsModule,
        slider_1.MatSliderModule,
        chips_1.MatChipsModule,
        icon_1.MatIconModule,
        footer_bar_module_1.FooterBarModule,
        accordion_1.CdkAccordionModule,
        loop_progress_bar_module_1.LoopProgressBarModule,
        directives_module_1.DirectivesModule,
        powered_by_row_module_1.PoweredByRowModule], exports: [edit_tune_page_1.EditTunePage] }); })();
