"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DevPage = void 0;
const enums_enum_1 = require("./../classes-enums-interfaces-types/enums/enums.enum");
const global_service_1 = require("./../services/global.service");
const mhttp_service_1 = require("./../services//helper-functions/mhttp/mhttp.service");
const http_1 = require("@angular/common/http");
const native_volume_control_npm_package_name_1 = require("native-volume-control-npm-package-name");
const environment_1 = require("../../../environments/environment");
const player_service_1 = require("../services/music/player.service");
const mglobal_1 = require("../mglobal");
const fcm_service_1 = require("../services/fcm/fcm.service");
const data_service_1 = require("../services/music/data.service");
const helper_functions_service_1 = require("../services/helper-functions/helper-functions.service");
const http_2 = require("@capacitor-community/http");
const ngx_1 = require("@awesome-cordova-plugins/in-app-browser/ngx");
const keycodes_1 = require("@angular/cdk/keycodes");
const find_tune_state_service_1 = require("../services/states/find-tune/find-tune-state.service");
const neverthrow_1 = require("neverthrow");
const enums_enum_2 = require("../classes-enums-interfaces-types/enums/enums.enum");
const angular_1 = require("@ionic/angular");
const add_tune_to_list_component_1 = require("../components/add-tune-to-list/add-tune-to-list.component");
const lyrics_component_1 = require("../components/lyrics/lyrics.component");
const i0 = require("@angular/core");
const i1 = require("../services/states/find-tune/find-tune-state.service");
const i2 = require("../services/music/player.service");
const i3 = require("../services/music/data.service");
const i4 = require("../services/fcm/fcm.service");
const i5 = require("../services/helper-functions/helper-functions.service");
const i6 = require("@awesome-cordova-plugins/in-app-browser/ngx");
const i7 = require("@angular/common/http");
const i8 = require("./../services//helper-functions/mhttp/mhttp.service");
const i9 = require("@ionic/angular");
const i10 = require("./../services/global.service");
const i11 = require("@angular/common");
const i12 = require("@angular/forms");
const i13 = require("../components/footer-bar/footer-bar.component");
const i14 = require("@angular/material/input");
const i15 = require("@angular/material/form-field");
const i16 = require("../directives/long-press/long-press.directive");
const i17 = require("../components/selected-track/selected-track.component");
function DevPage_ng_template_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "ion-content", 15);
    i0.ɵɵtext(1, "Hello World!");
    i0.ɵɵelementEnd();
} }
function DevPage_ion_row_85_ng_container_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const testElem_r4 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", testElem_r4.value, " ");
} }
function DevPage_ion_row_85_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "ion-row");
    i0.ɵɵtemplate(1, DevPage_ion_row_85_ng_container_1_Template, 2, 1, "ng-container", 16);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const testElem_r4 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", testElem_r4.show == true);
} }
function DevPage_app_selected_track_97_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "app-selected-track", 17);
} }
function DevPage_ng_template_99_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "ion-list", 18)(1, "ion-item", 19);
    i0.ɵɵtext(2, " Edit Tune ");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "ion-item", 19);
    i0.ɵɵtext(4, " Save Tune ");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "ion-item", 20);
    i0.ɵɵtext(6, " Open Spotify ");
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("button", true);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("button", true);
} }
class DevPage {
    async testToast() {
        await this.gs.showToast({ msg: "testMsg0", duration: 15000 });
    }
    testDecimalChange() {
        let value = this.startTimeS.replace(/,/g, '.');
        // Splitting into two string and replacing all the dots (.'s) in the second string
        let firstOccuranceIndex = value.search(/\./) + 1; // Index of first occurance of (.)
        let resultStr = value.substr(0, firstOccuranceIndex) + value.slice(firstOccuranceIndex).replace(/\./g, '');
        let resultNbr = Number(resultStr);
        //#opt will still brake if trying to make something stupid like pasting text
        let startTimeSNum;
        if (typeof this.startTimeS === 'string') {
            startTimeSNum = Number(this.startTimeS);
            //startTimeSNum = this.startTimeS.replace(',')
            if (startTimeSNum < 0) {
                //startTimeS = 0
            }
        }
        //this.startTimeS = Math.round(this.startTimeS*100)/100
        //this.tune.startTime = this.startTimeS * 1000
    }
    constructor(findTuneState, ps, ds, fcm, hF, iab, http, mhttp, animationCtrl, menuCtrl, modalCtrl, gs) {
        this.findTuneState = findTuneState;
        this.ps = ps;
        this.ds = ds;
        this.fcm = fcm;
        this.hF = hF;
        this.iab = iab;
        this.http = http;
        this.mhttp = mhttp;
        this.animationCtrl = animationCtrl;
        this.menuCtrl = menuCtrl;
        this.modalCtrl = modalCtrl;
        this.gs = gs;
        this.startTimeS = '3,55,,,,7';
        this.customBackIcon = '../../assets/arrow-back-outline.svg';
        this.customMenu = '../../../assets/menu.svg';
        this.echoRes = 'initEcho';
        this.clickCounter = 0;
        this.fetchedVolume = 333;
        this.volumeToSet = 0;
        this.seekTrackTime = 10000;
        this.spotAccessToken = undefined;
        this.spotWebSdkVol = .5;
        this.displayError = 'de';
        this.sdkPlayerName = 'devPlayer';
        this.volume = 0.5;
        this.backendDomainRemote = 'https://tune-it.app';
        this.backendDomainLocal = environment_1.ENV.DOMAIN_OF_BACKEND;
        this.backendDomain = this.backendDomainRemote;
        this.fruits = [{ name: 'Lemon' }, { name: 'Lime' }, { name: 'Apple' }];
        this.addOnBlur = true;
        this.separatorKeysCodes = [keycodes_1.ENTER, keycodes_1.COMMA];
        this.pressInc = 0;
        this.ngIfTestA = [
            { value: "1", show: true },
            { value: "2", show: false },
            { value: "3", show: true }
        ];
        this.toastItr = 0;
        this.lyrics = 'test lyrics';
        this.lpItr = 0;
    }
    ngOnInit() {
        this.ps.selectedTune$.subscribe((selectedTune) => {
            if (selectedTune)
                this.isTuneSelected = true;
        });
        this.testTune1 = mglobal_1.mGlobal.debugTunes[0];
        this.testTune2 = mglobal_1.mGlobal.debugTunes[1];
        this.initAsync();
        //this.menuButtonClicked()
    }
    ionViewDidEnter() {
        this.gs.setPendingHistoryBackClick(false);
    }
    printVisItr() {
        console.log(`visItr: ${this.gs.visibilityChangeVisItr}`);
        console.log(`hiddebnItr: ${this.gs.visibilityChangeHiddenItr}`);
    }
    spotDL1() {
        window.open("https://open.spotify.com/track/3lA6AlyXVUaYF6Sc9pIqjb?si=0d5b35aadff6412b", '_blank');
    }
    anim() {
        this.animation.play();
    }
    async presentModal() {
        const modal = await this.modalCtrl.create({
            component: lyrics_component_1.LyricsComponent,
            breakpoints: [0.7],
            initialBreakpoint: 0.7,
            id: 'DEBUG'
        });
        await modal.present();
    }
    ngAfterViewInit() {
        this.animation = this.animationCtrl.create()
            .addElement(document.querySelector('#animTest'))
            .duration(1000)
            .fromTo('opacity', '1', '0.1');
    }
    async initAsync() {
        //const errA = [errAsync(ValidateTuneError.TooShort), errAsync(SaveTuneError.validateTuneError)] 
        /* const resA = await ResultAsync.combineWithAllErrors(errA) // as ResultAsync<Tune,any[]>
        const res1 : any[] = []
        const res2 = resA.mapErr(e=> {
            res1.push(e)
            console.log(e)
        })
        console.log(res2) */
        const testOk = (0, neverthrow_1.okAsync)('this is ok');
        const shouldBeNull = testOk.mapErr(e => {
            return e;
        });
        console.log(shouldBeNull);
        //const o : ModalOptions
        const modal = await this.modalCtrl.create({
            component: add_tune_to_list_component_1.AddTuneToListComponent,
            componentProps: { 'tune': this.testTune1 },
            breakpoints: [0.7],
            initialBreakpoint: 0.7,
            id: enums_enum_2.ModalsE.addToTuneList
        });
        //modal.present();
        //   const { data, role } = await modal.onWillDismiss();
        //   if (role === 'confirm') {
        //   }
    }
    longPress() {
        this.timeoutHandler = setInterval(() => {
            this.pressInc++;
            console.log(this.pressInc);
        }, 300);
    }
    onLongPress() {
        console.log('LP');
        this.lpItr++;
    }
    onTap() {
        console.log('Tap');
    }
    endCount() {
        console.log("endCounttt");
        if (this.timeoutHandler) {
            clearTimeout(this.timeoutHandler);
            this.timeoutHandler = null;
        }
    }
    throwSentryError() {
        console.log('throwSentryError');
        throw new Error("Sentry Test Error");
    }
    getLyrics() {
        const trackName = 'Wind Of Chang';
        const artistName = 'Scorpions';
        const params = new http_1.HttpParams()
            .set("trackName", trackName)
            .set("artistName", artistName);
        this.http.get(`${environment_1.ENV.DOMAIN_OF_BACKEND}/get-lyrics`, { params: params }).subscribe(lyrics => {
            console.log(lyrics);
        }, e => {
            console.error("Error getting lyrics", e);
        });
    }
    toggleLoop(n) {
        try {
            let tune;
            if (n == 1) {
                tune = this.testTune1;
            }
            else if (n == 2) {
                tune = this.testTune2;
            }
            this.ps.loopTuneRequest(tune);
        }
        catch (e) {
            console.error('Error toggle loop dev', e);
        }
    }
    remove(fruit) {
        const index = this.fruits.indexOf(fruit);
        if (index >= 0) {
            this.fruits.splice(index, 1);
        }
    }
    testInAppBrowser() {
        const inAppBrowserOptions = {
            toolbarposition: 'top',
            toolbar: 'yes',
            //closebuttoncolor:'#FF0000',
            location: 'yes',
            toolbartranslucent: 'no'
        };
        const browser = this.iab.create('https://google.com', '_inAppBrowser', inAppBrowserOptions);
        browser.show();
    }
    testWindowHref() {
        window.location.href = 'https://google.com';
    }
    injectWebSdk() {
        this.ps.initSpotifyPlayer(this.sdkPlayerName);
        //this.spotWebSdk.injectSpotifyWebSdk(this.sdkPlayerName)
    }
    async getDeviceId() {
        this.deviceId = this.ps.getNvcDeviceId();
        console.log('ps device id', this.deviceId);
    }
    testButtonOneClick() {
        console.log('testt button clicked');
        this.clickCounter++;
        this.asyncTestButtonOneClick();
    }
    async asyncTestButtonOneClick() {
        let echoResObj = await native_volume_control_npm_package_name_1.NativeVolumeControl.echo({
            value: this.clickCounter.toString() + 1
        });
        this.echoRes = echoResObj.value;
        console.log(this.echoRes);
    }
    async setVolumeClick() {
        native_volume_control_npm_package_name_1.NativeVolumeControl.setVolume({ volume: this.volumeToSet });
    }
    async decVolumeClick() {
        native_volume_control_npm_package_name_1.NativeVolumeControl.decVolume();
    }
    async incVolumeClick() {
        native_volume_control_npm_package_name_1.NativeVolumeControl.incVolume();
    }
    loopTuneClick() {
        this.ps.loopTuneRequest(mglobal_1.mGlobal.debugTune);
    }
    resumeNVC() {
        native_volume_control_npm_package_name_1.NativeVolumeControl.resumeTrack();
    }
    async playTimeNVC() {
        native_volume_control_npm_package_name_1.NativeVolumeControl.playTrackIdTime({
            trackId: "71CFZjdSm2WSh5S6TPd69A",
            time: 10000
        });
    }
    async playNVC() {
        native_volume_control_npm_package_name_1.NativeVolumeControl.playTrackId({ trackId: "71CFZjdSm2WSh5S6TPd69A" });
    }
    async pauseNVC() {
        native_volume_control_npm_package_name_1.NativeVolumeControl.pauseTrack();
    }
    async seekTrackAbsolute() {
        native_volume_control_npm_package_name_1.NativeVolumeControl.seekTrackAbsolute({
            absoluteTime: this.seekTrackTime
        });
    }
    async playId(id) {
        try {
            await this.ps.playTrackId(id);
        }
        catch (e) {
            console.error(e);
        }
    }
    async playIdAndResume(id) {
        try {
            this.ps.nvcWeb.activatePlayerElement();
            await this.ps.nvcWeb.playTrackIdTime({ trackId: "3jG2DVbOteREs74udyVzJG", time: 7000 });
            //await this.hF.delay(3000)
            await this.ps.nvcWeb.resumeTrack();
            await this.ps.nvcWeb.playTrackIdTime({ trackId: id, time: 11000 });
        }
        catch (e) {
            console.error(e);
        }
    }
    async playIdTime() {
        try {
            await this.ps.nvcWeb.playTrackIdTime({ trackId: "3jG2DVbOteREs74udyVzJG", time: 7000 });
        }
        catch (e) {
            console.error(e);
        }
    }
    constructWebPlayer() {
        try {
            this.ps.constructSpotWebPlayer();
        }
        catch (e) {
            console.error('Error const. web player', e);
        }
    }
    async resume() {
        try {
            await this.ps.nvcWeb.resumeTrack();
        }
        catch (e) {
            console.error(e);
        }
    }
    async pause() {
        try {
            this.ps.pauseTrack();
        }
        catch (e) {
            console.error(e);
        }
    }
    activateElem() {
        try {
            this.ps.nvcWeb.activatePlayerElement();
            console.log('activateElem');
        }
        catch (e) {
            console.error(e);
        }
    }
    webApiTransferPlayBack() {
        this.ps.nvcWeb.webApiTransferPlayback();
    }
    getVolume() {
        this.ps.getVolume();
    }
    limitVolume() {
        if (this.volume < 0) {
            this.volume = 0;
        }
        else if (this.volume > 1) {
            this.volume = 1;
        }
        console.log(this.volume);
    }
    decVol() {
        this.volume -= .1;
        this.limitVolume();
        native_volume_control_npm_package_name_1.NativeVolumeControl.setVolume({
            volume: this.volume
        });
    }
    incVol() {
        this.volume += .1;
        this.limitVolume();
        native_volume_control_npm_package_name_1.NativeVolumeControl.setVolume({
            volume: this.volume
        });
    }
    loop() {
        this.ps.loopTuneRequest(mglobal_1.mGlobal.debugTune);
    }
    webApiPlay() {
        this.ps.webApiPlay().subscribe(res => {
            console.log(res);
        }, e => {
            console.error('Error web api play', e);
        });
    }
    async requestFcmPushPermission() {
        this.fcm.registerNotifications();
        /*this.fcm.getAfMessaging().requestToken
        .subscribe(
          (token) => {
            this.displayMsg = token
            alert(token)
          },
          (error) => {
              console.error(error);
              alert(error)
              this.displayMsg = error
          },
        ); */
    }
    requestReturnPushMsgFcm() {
        this.fcm.requestReturnPushMsgFcm();
    }
    setCookieBackend() {
        const options = {
            url: `${this.backendDomain}/set-cookies`,
            headers: { 'Content-Type': 'application/json' },
            data: { foo: {
                    nestedObj: 'nestedObjValue'
                }, cool: true },
        };
        http_2.Http.post(options).then((res) => {
            console.log('cap http plugin ');
            console.log(res);
        }).catch((e) => {
            console.log('cap http plugin error ');
            console.log(e);
        });
    }
    async getCookies() {
        try {
            const cookies = await http_2.Http.getCookies({ url: environment_1.ENV.DOMAIN_OF_BACKEND });
            console.log('gottenCookies');
            console.log(cookies);
        }
        catch (e) {
            console.error('Get cookies', e);
        }
    }
    async setCookieHttpPlugin() {
        try {
            const options = {
                url: this.backendDomain,
                key: 'language',
                value: 'en',
            };
            await http_2.Http.setCookie(options);
        }
        catch (e) {
            console.log(e);
        }
    }
    toggleBackend() {
        if (this.backendDomain == this.backendDomainLocal) {
            this.backendDomain = this.backendDomainRemote;
        }
        else {
            this.backendDomain = this.backendDomainLocal;
        }
    }
    async onlineCheckN() {
        try {
            const data = await http_2.Http.get({
                url: environment_1.ENV.DOMAIN_OF_BACKEND + '/online-check',
                webFetchExtra: {
                    credentials: 'include'
                }
            });
            console.log(data);
        }
        catch (e) {
            console.error('onlineCheckN', e);
        }
    }
    async onlineCheckWeb() {
        this.http.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/online-check', { withCredentials: true }).subscribe(res => {
            console.log(res);
        });
    }
    async onlineCheckMyHttp() {
        this.mhttp.get(environment_1.ENV.DOMAIN_OF_BACKEND + '/online-check').subscribe(res => {
            console.log(res);
        }), e => {
            console.error('mHttp test', e);
        };
    }
    async clearAllCookiesN() {
        await http_2.Http.clearAllCookies();
    }
    async testSlugTrigger() {
        console.log('testing slug');
        /*         const capBrowser = await Browser.open({
                    url : 'http://192.168.43.89:8080/test-deeplink'
                    //url : 'http://192.168.43.210:8080/test-deeplink'
                })
        */
    }
    testPostM() {
        this.mhttp.post(environment_1.ENV.DOMAIN_OF_BACKEND + '/test-post', { testBody: 'testBodyValue' }).subscribe(res => {
            console.log(res);
        }, e => {
            console.error('error test post', e);
        });
    }
    async menuButtonClicked() {
        //const toggleRes = await this.menuCtrl.toggle() 
        //const openRes =  await this.menuCtrl.open()
        //console.log(openRes)
        this.gs.requestSideMenuToggle(true);
    }
    installPwa() {
        const dp = this.gs.getDeferredPrompt();
        console.log('install pwa');
        if (dp) {
            console.log('dp not null');
            dp.prompt().then((choiceResult => {
                console.log('instal prompt clicked');
                console.log(choiceResult);
                this.gs.deferredPrompt = null;
            }));
        }
    }
    setIncorrectSpotToken() {
        this.ps.setNvcSpotAccessToken('jfng');
    }
    async getSpotTokenNVC() {
        const spotAccessToken = await this.ps.getNvcSpotAccessToken();
        console.log(`spotAccessTokenNVC: ${spotAccessToken}`);
    }
    async getSpotDevices() {
        const spotDevices = await this.ps.getSpotDevices();
        console.log('SpotDevices:');
        console.log(spotDevices);
    }
    async playTrackIdPs() {
        await this.ps.playTrackId(this.testTune1.slimTuneTrack.trackId);
    }
    showToastError() {
        this.gs.showToast({ header: enums_enum_1.SaveOrUpdateTuneError.validateTuneError, msg: "Tune needs to have a message when it should be suggested", type: enums_enum_1.ToastEnum.error, duration: 50000 });
        this.toastItr++;
    }
    async searchPlaylist() {
        const searchStr = "Goa bitar";
        this.ds.search(searchStr, 'playlist', 0, 20).subscribe(res => {
            console.log(res);
        });
    }
    static { this.ɵfac = function DevPage_Factory(t) { return new (t || DevPage)(i0.ɵɵdirectiveInject(i1.FindTuneStateService), i0.ɵɵdirectiveInject(i2.PlayerService), i0.ɵɵdirectiveInject(i3.DataService), i0.ɵɵdirectiveInject(i4.FcmService), i0.ɵɵdirectiveInject(i5.HelperFunctionsService), i0.ɵɵdirectiveInject(i6.InAppBrowser), i0.ɵɵdirectiveInject(i7.HttpClient), i0.ɵɵdirectiveInject(i8.MHttpService), i0.ɵɵdirectiveInject(i9.AnimationController), i0.ɵɵdirectiveInject(i9.MenuController), i0.ɵɵdirectiveInject(i9.ModalController), i0.ɵɵdirectiveInject(i10.GlobalService)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: DevPage, selectors: [["app-dev"]], decls: 100, vars: 5, consts: [["id", "main-content"], ["id", "context-menu-trigger"], ["trigger", "context-menu-trigger", "triggerAction", "context-menu"], ["long-press", "", 3, "press", "tap"], [1, "lp-test", 2, "height", "10vh", "border-style", "solid", "border-color", "red"], ["size", "large", 3, "click"], ["id", "animTest"], ["label", "", 3, "ngModel", "ngModelChange"], ["id", "log", 1, "custom-log"], [4, "ngFor", "ngForOf"], [2, "height", "10vh", "width", "100%"], ["appearance", "fill"], ["matInput", "", "placeholder", "Placeholder"], ["id", "selected-track-component", 4, "ngIf"], ["id", "pop-over-selected-track"], [1, "ion-padding"], [4, "ngIf"], ["id", "selected-track-component"], [1, "pop-over-list"], [1, "pop-over-item", 3, "button"], ["lines", "none", 1, "pop-over-item"]], template: function DevPage_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "ion-content", 0)(1, "ion-button", 1);
            i0.ɵɵtext(2, "Right-Click Me");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(3, "ion-popover", 2);
            i0.ɵɵtemplate(4, DevPage_ng_template_4_Template, 2, 0, "ng-template");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(5, "ion-button", 3);
            i0.ɵɵlistener("press", function DevPage_Template_ion_button_press_5_listener() { return ctx.onLongPress(); })("tap", function DevPage_Template_ion_button_tap_5_listener() { return ctx.onTap(); });
            i0.ɵɵtext(6);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(7, "ion-row", 4);
            i0.ɵɵtext(8, " LP ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(9, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_9_listener() { return ctx.setIncorrectSpotToken(); });
            i0.ɵɵtext(10, " Spot token err ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(11, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_11_listener() { return ctx.showToastError(); });
            i0.ɵɵtext(12, " toast error ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(13, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_13_listener() { return ctx.toggleLoop(1); });
            i0.ɵɵtext(14, " ToogleLoop 1 ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(15, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_15_listener() { return ctx.toggleLoop(2); });
            i0.ɵɵtext(16, " ToogleLoop 2 ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(17, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_17_listener() { return ctx.getSpotDevices(); });
            i0.ɵɵtext(18, " Get DEvices ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(19, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_19_listener() { return ctx.presentModal(); });
            i0.ɵɵtext(20, " Modal\n");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(21, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_21_listener() { return ctx.getSpotTokenNVC(); });
            i0.ɵɵtext(22, " Get spot token ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(23, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_23_listener() { return ctx.playTrackIdPs(); });
            i0.ɵɵtext(24, " playTrackId_Ps ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(25, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_25_listener() { return ctx.spotDL1(); });
            i0.ɵɵtext(26, " spotDL1 ");
            i0.ɵɵelementEnd();
            i0.ɵɵelement(27, "div", 6);
            i0.ɵɵelementStart(28, "ion-input", 7);
            i0.ɵɵlistener("ngModelChange", function DevPage_Template_ion_input_ngModelChange_28_listener($event) { return ctx.sdkPlayerName = $event; });
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(29, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_29_listener() { return ctx.constructWebPlayer(); });
            i0.ɵɵtext(30, " const. webP ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(31, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_31_listener() { return ctx.activateElem(); });
            i0.ɵɵtext(32, " A Spot Elem ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(33, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_33_listener() { return ctx.playId("3jG2DVbOteREs74udyVzJG"); });
            i0.ɵɵtext(34, " playIdA ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(35, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_35_listener() { return ctx.playId("2lnzGkdtDj5mtlcOW2yRtG"); });
            i0.ɵɵtext(36, " playIdB ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(37, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_37_listener() { return ctx.playIdAndResume("3jG2DVbOteREs74udyVzJG"); });
            i0.ɵɵtext(38, " play&ResumeA ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(39, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_39_listener() { return ctx.playIdAndResume("2lnzGkdtDj5mtlcOW2yRtG"); });
            i0.ɵɵtext(40, " play&ResumeB ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(41, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_41_listener() { return ctx.playIdTime(); });
            i0.ɵɵtext(42, " playIdTime ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(43, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_43_listener() { return ctx.resume(); });
            i0.ɵɵtext(44, " resume ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(45, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_45_listener() { return ctx.pause(); });
            i0.ɵɵtext(46, " pause ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(47, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_47_listener() { return ctx.webApiTransferPlayBack(); });
            i0.ɵɵtext(48, " TransferPlayBack ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(49, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_49_listener() { return ctx.anim(); });
            i0.ɵɵtext(50, " anim ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(51, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_51_listener() { return ctx.printVisItr(); });
            i0.ɵɵtext(52, " VisItr ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(53, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_53_listener() { return ctx.installPwa(); });
            i0.ɵɵtext(54, " installPWA ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(55, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_55_listener() { return ctx.injectWebSdk(); });
            i0.ɵɵtext(56, " initSdkPlayer ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(57, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_57_listener() { return ctx.playNVC(); });
            i0.ɵɵtext(58, " PlayNVC ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(59, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_59_listener() { return ctx.pauseNVC(); });
            i0.ɵɵtext(60, " PauseNVC ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(61, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_61_listener() { return ctx.playTimeNVC(); });
            i0.ɵɵtext(62, " playTimeNVC ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(63, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_63_listener() { return ctx.resumeNVC(); });
            i0.ɵɵtext(64, " resumeNVC ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(65, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_65_listener() { return ctx.loopTuneClick(); });
            i0.ɵɵtext(66, " LoopWeb ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(67, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_67_listener() { return ctx.getVolume(); });
            i0.ɵɵtext(68, " getVol ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(69, "ion-row");
            i0.ɵɵelement(70, "div", 8);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(71, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_71_listener() { return ctx.decVol(); });
            i0.ɵɵtext(72, " decVol ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(73, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_73_listener() { return ctx.incVol(); });
            i0.ɵɵtext(74, " incVol ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(75, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_75_listener() { return ctx.getSpotTokenNVC(); });
            i0.ɵɵtext(76, " Get spot token ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(77, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_77_listener() { return ctx.playTrackIdPs(); });
            i0.ɵɵtext(78, " playTrackId_Ps ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(79, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_79_listener() { return ctx.webApiPlay(); });
            i0.ɵɵtext(80, " PlayWebApi ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(81, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_81_listener() { return ctx.onlineCheckMyHttp(); });
            i0.ɵɵtext(82, " onlineCheckM ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(83, "ion-button", 5);
            i0.ɵɵlistener("click", function DevPage_Template_ion_button_click_83_listener() { return ctx.getDeviceId(); });
            i0.ɵɵtext(84, " GetPSdeviceID ");
            i0.ɵɵelementEnd();
            i0.ɵɵtemplate(85, DevPage_ion_row_85_Template, 2, 1, "ion-row", 9);
            i0.ɵɵelementStart(86, "p");
            i0.ɵɵtext(87, "deviceId:");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(88, "p");
            i0.ɵɵtext(89);
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(90, "div", 10)(91, "mat-form-field", 11)(92, "mat-label");
            i0.ɵɵtext(93, "Standard form field");
            i0.ɵɵelementEnd();
            i0.ɵɵelement(94, "input", 12);
            i0.ɵɵelementStart(95, "mat-hint");
            i0.ɵɵtext(96, "Hint");
            i0.ɵɵelementEnd()()();
            i0.ɵɵtemplate(97, DevPage_app_selected_track_97_Template, 1, 0, "app-selected-track", 13);
            i0.ɵɵelement(98, "app-footer-bar");
            i0.ɵɵtemplate(99, DevPage_ng_template_99_Template, 7, 2, "ng-template", 14);
        } if (rf & 2) {
            i0.ɵɵadvance(6);
            i0.ɵɵtextInterpolate1(" LP BUT ", ctx.lpItr, " ");
            i0.ɵɵadvance(22);
            i0.ɵɵproperty("ngModel", ctx.sdkPlayerName);
            i0.ɵɵadvance(57);
            i0.ɵɵproperty("ngForOf", ctx.ngIfTestA);
            i0.ɵɵadvance(4);
            i0.ɵɵtextInterpolate1(" ", ctx.deviceId, "");
            i0.ɵɵadvance(8);
            i0.ɵɵproperty("ngIf", ctx.isTuneSelected);
        } }, dependencies: [i11.NgForOf, i11.NgIf, i12.NgControlStatus, i12.NgModel, i9.IonButton, i9.IonContent, i9.IonInput, i9.IonItem, i9.IonList, i9.IonRow, i9.IonPopover, i9.TextValueAccessor, i13.FooterBarComponent, i14.MatInput, i15.MatFormField, i15.MatLabel, i15.MatHint, i16.LongPressDirective, i17.SelectedTrackComponent], styles: ["\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n#animTest[_ngcontent-%COMP%] {\n  width: 50%;\n  height: 5dvh;\n  border-style: solid;\n  border-color: red;\n}\n\n.example-chip-list[_ngcontent-%COMP%] {\n  width: 100%;\n}\n\nion-toolbar[_ngcontent-%COMP%] {\n  display: flex;\n  flex-direction: row;\n}\n\nion-item[_ngcontent-%COMP%] {\n  margin-bottom: 1dvh;\n  --border-radius: 1dvh;\n  --inner-padding-start: 1vw;\n}\n\nion-list[_ngcontent-%COMP%] {\n  background-color: var(--background-color) ion-bac;\n}\n\nion-menu[_ngcontent-%COMP%] {\n  --max-width:70vw ;\n}\n\n#lyrics[_ngcontent-%COMP%] {\n  width: 100vw;\n  align-self: center;\n  justify-self: center;\n  margin: 0;\n  display: flex;\n}"] }); }
}
exports.DevPage = DevPage;
