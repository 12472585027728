"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LongPressDirective = void 0;
const core_1 = require("@angular/core");
const angular_1 = require("@ionic/angular");
const i0 = require("@angular/core");
const i1 = require("@ionic/angular");
// src : https://forum.ionicframework.com/t/ionic-5-long-press-gesture-example/188746/6
class LongPressDirective {
    static { this.eventAllowedToStart = true; }
    constructor(el, gestureCtrl, zone) {
        this.el = el;
        this.gestureCtrl = gestureCtrl;
        this.zone = zone;
        this.tap = new core_1.EventEmitter();
        this.press = new core_1.EventEmitter();
        this.pressInit = new core_1.EventEmitter();
        this.delay = 800;
        this.disableDir = false;
        // needed to prevent event on elem2 that shows up at after click on elem1 at same place
        // to get trigger by same userAction 
        this.minDelayBetweenTaps = 100;
        this.positions = {
            start: {
                x: undefined,
                y: undefined
            },
            current: {
                x: undefined,
                y: undefined,
            }
        };
        this.longPressDurationReached = false;
        this.longPressActive = false;
        // used to not trigger tap when scrolling 
        this.dragLimitX = 15;
        this.dragLimitY = 10;
    }
    ngAfterViewInit() {
        this.loadLongPressOnElement();
    }
    loadLongPressOnElement() {
        this.gesture = this.gestureCtrl.create({
            el: this.el.nativeElement,
            threshold: 0,
            gestureName: 'long-press',
            canStart: (gd) => {
                const target = gd.event.target;
                const canStart = LongPressDirective.eventAllowedToStart &&
                    // for child click not to cause parent long press directive
                    // #opt maybe more efficient to add long press on all other elems than child button click override
                    !target.classList.contains('ignore-long-press-directive');
                return canStart;
            },
            onStart: ev => {
                console.log('gest start');
                this.longPressActive = true;
                this.positions = {
                    start: { x: ev.startX, y: ev.startY },
                    current: { x: ev.currentX, y: ev.currentY }
                };
                this.longPressAction();
            },
            onMove: ev => {
                this.positions.current = { x: ev.currentX, y: ev.currentY };
            },
            onEnd: ev => {
                if (this.longPressDurationReached) {
                    console.log("long press");
                    this.press.emit(ev);
                }
                else {
                    const xDistance = Math.abs(this.positions.start.x - this.positions.current.x);
                    const yDistance = Math.abs(this.positions.start.y - this.positions.current.y);
                    if (xDistance > this.dragLimitX || yDistance > this.dragLimitY) {
                        console.log('dragged');
                        // User dragged finger
                        return;
                    }
                    console.log('tap');
                    this.tap.emit(ev);
                }
                if (this.action) {
                    clearInterval(this.action);
                }
                this.longPressDurationReached = false;
                console.log('gest end');
                this.eventAllowedToStartTimeOut();
            }
        });
        this.gesture.enable(!this.disableDir);
    }
    ngOnDestroy() {
        this.gesture.destroy();
    }
    longPressAction() {
        if (this.action) {
            clearInterval(this.action);
        }
        this.action = setTimeout(() => {
            this.zone.run(() => {
                // Check distance
                const xDistance = Math.abs(this.positions.start.x - this.positions.current.x);
                const yDistance = Math.abs(this.positions.start.y - this.positions.current.y);
                if (xDistance > 15 || yDistance > 15) {
                    // User dragged finger
                    console.log('dragged');
                    return;
                }
                console.log("press init");
                this.pressInit.emit();
                this.longPressDurationReached = true;
            });
        }, this.delay);
    }
    eventAllowedToStartTimeOut() {
        LongPressDirective.eventAllowedToStart = false;
        if (LongPressDirective.eventAllowedToStartTimeout) {
            clearInterval(LongPressDirective.eventAllowedToStartTimeout);
        }
        LongPressDirective.eventAllowedToStartTimeout = setTimeout(() => {
            LongPressDirective.eventAllowedToStart = true;
        }, this.minDelayBetweenTaps);
    }
    static { this.ɵfac = function LongPressDirective_Factory(t) { return new (t || LongPressDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.GestureController), i0.ɵɵdirectiveInject(i0.NgZone)); }; }
    static { this.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: LongPressDirective, selectors: [["", "long-press", ""]], inputs: { delay: "delay", disableDir: "disableDir" }, outputs: { tap: "tap", press: "press", pressInit: "pressInit" } }); }
}
exports.LongPressDirective = LongPressDirective;
