"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PoweredByRowModule = void 0;
const common_1 = require("@angular/common");
const powered_by_row_component_1 = require("./powered-by-row.component");
const angular_1 = require("@ionic/angular");
const i0 = require("@angular/core");
class PoweredByRowModule {
    static { this.ɵfac = function PoweredByRowModule_Factory(t) { return new (t || PoweredByRowModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: PoweredByRowModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [common_1.CommonModule,
            angular_1.IonicModule] }); }
}
exports.PoweredByRowModule = PoweredByRowModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(PoweredByRowModule, { declarations: [powered_by_row_component_1.PoweredByRowComponent], imports: [common_1.CommonModule,
        angular_1.IonicModule], exports: [powered_by_row_component_1.PoweredByRowComponent] }); })();
