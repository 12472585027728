"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackPageModule = void 0;
const forms_1 = require("@angular/forms");
const footer_bar_module_1 = require("../components/footer-bar/footer-bar.module");
const shared_module_1 = require("../shared/shared.module");
const track_routing_module_1 = require("./track-routing.module");
const powered_by_row_module_1 = require("../components/powered-by-row/powered-by-row.module");
const track_page_1 = require("./track.page");
const loop_progress_bar_module_1 = require("../components/loop-progress-bar/loop-progress-bar.module");
const lyrics_module_1 = require("../components/lyrics/lyrics.module");
const i0 = require("@angular/core");
class TrackPageModule {
    static { this.ɵfac = function TrackPageModule_Factory(t) { return new (t || TrackPageModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: TrackPageModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [forms_1.FormsModule,
            track_routing_module_1.TrackPageRoutingModule,
            loop_progress_bar_module_1.LoopProgressBarModule,
            powered_by_row_module_1.PoweredByRowModule,
            shared_module_1.SharedModule,
            footer_bar_module_1.FooterBarModule,
            lyrics_module_1.LyricsModule] }); }
}
exports.TrackPageModule = TrackPageModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(TrackPageModule, { declarations: [track_page_1.TrackPage], imports: [forms_1.FormsModule,
        track_routing_module_1.TrackPageRoutingModule,
        loop_progress_bar_module_1.LoopProgressBarModule,
        powered_by_row_module_1.PoweredByRowModule,
        shared_module_1.SharedModule,
        footer_bar_module_1.FooterBarModule,
        lyrics_module_1.LyricsModule] }); })();
