"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppModule = void 0;
const unsupported_in_app_browser_component_1 = require("./components/modals/unsupported-in-app-browser/unsupported-in-app-browser.component");
const pwa_install_prompt_ios_module_1 = require("./components/pwa-install-prompt/ios/pwa-install-prompt-ios.module");
const mhttp_service_1 = require("./services/helper-functions/mhttp/mhttp.service");
const app_state_service_1 = require("./services/app-state.service");
const m_can_activate_service_1 = require("./services/m-can-activate.service");
const platform_browser_1 = require("@angular/platform-browser");
const core_1 = require("@angular/core");
const http_1 = require("@angular/common/http");
const router_1 = require("@angular/router");
const radio_1 = require("@angular/material/radio");
const checkbox_1 = require("@angular/material/checkbox");
const select_1 = require("@angular/material/select");
const text_field_1 = require("@angular/cdk/text-field");
const input_1 = require("@angular/material/input");
const form_field_1 = require("@angular/material/form-field");
const forms_1 = require("@angular/forms");
const snack_bar_1 = require("@angular/material/snack-bar");
const slider_1 = require("@angular/material/slider");
//import { FlexLayoutModule } from '@angular/flex-layout';
const app_component_1 = require("./app.component");
/* import { EditTuneAdminComponent } from './edit-tune/edit-tune.component'; */
const animations_1 = require("@angular/platform-browser/animations");
const funnel_component_1 = require("./funnel/funnel.component");
const tabs_1 = require("@angular/material/tabs");
const angular_1 = require("@ionic/angular");
const chips_1 = require("@angular/material/chips");
const http_2 = require("@angular/common/http");
const app_routing_module_1 = require("./app-routing.module");
const auth_http_interceptor_service_1 = require("./services/http-interceptors/auth/auth-http-interceptor.service");
const ngx_cookie_service_1 = require("ngx-cookie-service");
const global_service_1 = require("./services/global.service");
const compat_1 = require("@angular/fire/compat");
const messaging_1 = require("@angular/fire/compat/messaging");
const environment_1 = require("../../environments/environment");
const ngx_1 = require("@awesome-cordova-plugins/in-app-browser/ngx");
const service_worker_1 = require("@angular/service-worker");
const add_tune_to_list_component_1 = require("./components/add-tune-to-list/add-tune-to-list.component");
const rename_tune_list_component_1 = require("./components/modals/rename-tune-list/rename-tune-list.component");
const Sentry = require("@sentry/angular-ivy");
const shared_module_1 = require("./shared/shared.module");
const search_bar_component_1 = require("./components/search-bar/search-bar.component");
const accordion_1 = require("@angular/cdk/accordion");
const not_recommended_browser_component_1 = require("./components/modals/not-recommended-browser/not-recommended-browser.component");
const fullscreen_spinner_component_1 = require("./components/modals/fullscreen-spinner/fullscreen-spinner.component");
const i0 = require("@angular/core");
const i1 = require("@angular/fire/compat");
const i2 = require("@ionic/angular");
const i3 = require("@angular/service-worker");
class AppModule {
    constructor() { }
    static { this.ɵfac = function AppModule_Factory(t) { return new (t || AppModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: AppModule, bootstrap: [app_component_1.AppComponent] }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ providers: // services that will be injected in compnents
        [
            ngx_cookie_service_1.CookieService,
            global_service_1.GlobalService,
            m_can_activate_service_1.mCanActivateService,
            ngx_1.InAppBrowser,
            mhttp_service_1.MHttpService,
            app_state_service_1.AppStateService,
            {
                provide: router_1.RouteReuseStrategy,
                useClass: angular_1.IonicRouteStrategy
            },
            {
                provide: http_2.HTTP_INTERCEPTORS,
                useClass: auth_http_interceptor_service_1.authHttpInterceptorService,
                multi: true
            },
            {
                provide: core_1.ErrorHandler,
                useValue: Sentry.createErrorHandler({
                    showDialog: false,
                }),
            },
            // MAT_RADIO_DEFAULT_OPTIONS,npm install --save @sentry/browser
        ], imports: [compat_1.AngularFireModule.initializeApp(environment_1.ENV.FIRE_BASE_APP_CONFIG),
            messaging_1.AngularFireMessagingModule,
            // Modules that are inported with also have a decleration array
            // All components above have access to these features
            platform_browser_1.BrowserModule,
            http_1.HttpClientModule,
            //NoopAnimationsModule,
            radio_1.MatRadioModule,
            checkbox_1.MatCheckboxModule,
            select_1.MatSelectModule,
            text_field_1.TextFieldModule,
            input_1.MatInputModule,
            form_field_1.MatFormFieldModule,
            animations_1.BrowserAnimationsModule,
            forms_1.FormsModule,
            snack_bar_1.MatSnackBarModule,
            slider_1.MatSliderModule,
            tabs_1.MatTabsModule,
            chips_1.MatChipsModule,
            pwa_install_prompt_ios_module_1.PwaInstallPromptIosModule,
            angular_1.IonicModule.forRoot(),
            app_routing_module_1.AppRoutingModule,
            shared_module_1.SharedModule,
            angular_1.IonicModule,
            accordion_1.CdkAccordionModule,
            service_worker_1.ServiceWorkerModule.register('ngsw-worker.js', {
                enabled: environment_1.ENV.PRODUCTION,
                // Register the ServiceWorker as soon as the application is stable
                // or after 30 seconds (whichever comes first).
                registrationStrategy: 'registerWhenStable:30000'
            })] }); }
}
exports.AppModule = AppModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(AppModule, { declarations: [app_component_1.AppComponent,
        /*     EditTuneAdminComponent, */
        //FindTunePage,
        funnel_component_1.FunnelComponent,
        add_tune_to_list_component_1.AddTuneToListComponent,
        rename_tune_list_component_1.RenameTuneListComponent,
        unsupported_in_app_browser_component_1.UnsupportedInAppBrowserComponent,
        not_recommended_browser_component_1.NotRecommendedBrowserComponent,
        fullscreen_spinner_component_1.FullscreenSpinnerComponent], imports: [i1.AngularFireModule, messaging_1.AngularFireMessagingModule,
        // Modules that are inported with also have a decleration array
        // All components above have access to these features
        platform_browser_1.BrowserModule,
        http_1.HttpClientModule,
        //NoopAnimationsModule,
        radio_1.MatRadioModule,
        checkbox_1.MatCheckboxModule,
        select_1.MatSelectModule,
        text_field_1.TextFieldModule,
        input_1.MatInputModule,
        form_field_1.MatFormFieldModule,
        animations_1.BrowserAnimationsModule,
        forms_1.FormsModule,
        snack_bar_1.MatSnackBarModule,
        slider_1.MatSliderModule,
        tabs_1.MatTabsModule,
        chips_1.MatChipsModule,
        pwa_install_prompt_ios_module_1.PwaInstallPromptIosModule, i2.IonicModule, app_routing_module_1.AppRoutingModule,
        shared_module_1.SharedModule,
        angular_1.IonicModule,
        accordion_1.CdkAccordionModule, i3.ServiceWorkerModule], exports: [search_bar_component_1.SearchBarComponent] }); })();
