"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MHttpService = void 0;
const auth_service_1 = require("./../../auth/auth.service");
const router_1 = require("@angular/router");
const http_1 = require("@angular/common/http");
const core_1 = require("@angular/core");
const http_2 = require("@capacitor-community/http");
const rxjs_1 = require("rxjs");
const environment_1 = require("../../../../../environments/environment");
const i0 = require("@angular/core");
const i1 = require("@angular/common/http");
const i2 = require("@angular/router");
class MHttpService {
    // only reason for using obs instead of plain promise is to learn and be 
    // more stream compatible later, and also easier conversion
    // #OPT This overloading must be possible to do better with more TS knowledge, e.g don't use any
    constructor(http, router, injector) {
        this.http = http;
        this.router = router;
        this.injector = injector;
        this.first401 = true;
    }
    get(url, options) {
        //(Observable<T> | Observable<MHttpReturn<T>>) {
        let obs$;
        const httpOptions = {
            url: url,
            method: 'GET',
            webFetchExtra: {
                credentials: 'include'
            }
        };
        if (options && "params" in options) {
            httpOptions.params = options.params;
        }
        /*obs$ = from(Http.get({url:url}))
            .pipe(
                map(res => {
                    if(res.status > 299){
                        //#FIX
                        throw Error(
                            `status: ${res.status}
                            error: ${res.data}`
                        )
                    }
                    return res.data
                })
        ) */
        /*try{
                const data = await Http.get(httpOptions)
                subscriber.next(data)
            } catch(e){
                subscriber.error(e)
            } */
        /*obs$ = from(
            Http.get({url:url})).pipe(
                map(res => {
                    if(res.status > 299){
                        //#FIX
                        throwError('innerError')
                    } else{
                        return res.data
                    }
                })
        ) */
        //Http.get()
        if (options && "params" in options && options?.params) {
            httpOptions.params = options.params;
        }
        //}
        if (options && "sendStatus" in options && options?.sendStatus) {
            /* obs$ = new Observable( (subsriber) => {
                let tee : any
                subsriber.next({
                    status:1,
                    data:tee
                })
            }) */
            obs$ = this.getHttp$(httpOptions, true);
        }
        else {
            obs$ = this.getHttp$(httpOptions);
            /* obs$ = new Observable( (subsriber) => {
                let tee : any
                subsriber.next(tee)
            }) */
        }
        return obs$;
    }
    //ost<T>(url:string, httpOptions:HttpPostOptions, mOptions:MHttpOptions) : 
    //post<T>(url:string,body:object, mOptions:MHttpOptions, params:HttpParams) : MHttpReturn<T> 
    //post<T>(url:string, body: object, options:MHttpPostOptions) = Observable< options.sendStatus ? T : MHttpReturn<T>> 
    post(url, body, options = null) {
        let obs$;
        const httpOptions = {
            url: url,
            data: body,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            webFetchExtra: {
                credentials: 'include'
            }
        };
        //if(typeof options !== null){
        //if(options){
        if (options && "params" in options) {
            httpOptions.params = options.params;
        }
        //}
        if (options && "sendStatus" in options) {
            /* obs$ = new Observable( (subsriber) => {
                let tee : any
                subsriber.next({
                    status:1,
                    data:tee
                })
            }) */
            obs$ = this.getHttp$(httpOptions, true);
        }
        else {
            obs$ = this.getHttp$(httpOptions);
            /* obs$ = new Observable( (subsriber) => {
                let tee : any
                subsriber.next(tee)
            }) */
        }
        return obs$;
    }
    patch(url, body, params) {
        let obs$;
        const httpOptions = {
            url: url,
            method: 'PATCH',
            data: body,
            headers: { 'Content-Type': 'application/json' },
            webFetchExtra: {
                credentials: 'include'
            }
        };
        if (params) {
            httpOptions.params = params;
        }
        obs$ = this.getHttp$(httpOptions);
        return obs$;
    }
    getHttp$(httpOptions, sendStatus) {
        return new rxjs_1.Observable((subscriber) => {
            http_2.Http.request(httpOptions).then(res => {
                if (!environment_1.ENV.DEBUG && res.status == 401) {
                    if (this.first401) {
                        this.first401 = false;
                        //To avoid Circular Dependency
                        this.injector.get(auth_service_1.AuthService).login({ redirectPath: this.router.url });
                    }
                    else {
                        this.first401 = true;
                        this.router.navigateByUrl('/login');
                    }
                }
                else if (res.status > 299) {
                    subscriber.error({
                        status: res.status,
                        message: res.data
                    });
                }
                else {
                    if (sendStatus) {
                        subscriber.next({
                            status: res.status,
                            data: res.data
                        });
                    }
                    else {
                        subscriber.next(res.data);
                    }
                    //#Fix if want to use data streams
                }
                subscriber.complete();
            }).catch(e => {
                subscriber.error(e);
            });
        });
    }
    static { this.ɵfac = function MHttpService_Factory(t) { return new (t || MHttpService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i0.Injector)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: MHttpService, factory: MHttpService.ɵfac, providedIn: 'root' }); }
}
exports.MHttpService = MHttpService;
