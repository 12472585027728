"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectivesModule = void 0;
const common_1 = require("@angular/common");
const long_press_directive_1 = require("./long-press/long-press.directive");
const comma_to_dot_directive_1 = require("./commaToDot/comma-to-dot.directive");
const i0 = require("@angular/core");
class DirectivesModule {
    static { this.ɵfac = function DirectivesModule_Factory(t) { return new (t || DirectivesModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: DirectivesModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [common_1.CommonModule] }); }
}
exports.DirectivesModule = DirectivesModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DirectivesModule, { declarations: [long_press_directive_1.LongPressDirective,
        comma_to_dot_directive_1.CommaToDotDirective], imports: [common_1.CommonModule], exports: [long_press_directive_1.LongPressDirective] }); })();
