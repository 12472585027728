"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginPageModule = void 0;
const common_1 = require("@angular/common");
const forms_1 = require("@angular/forms");
const angular_1 = require("@ionic/angular");
const login_routing_module_1 = require("./login-routing.module");
const footer_bar_module_1 = require("../../components/footer-bar/footer-bar.module");
const login_page_1 = require("./login.page");
const i0 = require("@angular/core");
class LoginPageModule {
    static { this.ɵfac = function LoginPageModule_Factory(t) { return new (t || LoginPageModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: LoginPageModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [common_1.CommonModule,
            forms_1.FormsModule,
            angular_1.IonicModule,
            login_routing_module_1.LoginPageRoutingModule,
            footer_bar_module_1.FooterBarModule] }); }
}
exports.LoginPageModule = LoginPageModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(LoginPageModule, { declarations: [login_page_1.LoginPage], imports: [common_1.CommonModule,
        forms_1.FormsModule,
        angular_1.IonicModule,
        login_routing_module_1.LoginPageRoutingModule,
        footer_bar_module_1.FooterBarModule] }); })();
