"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharedModule = void 0;
const angular_1 = require("@ionic/angular");
const common_1 = require("@angular/common");
const search_bar_component_1 = require("../components/search-bar/search-bar.component");
const forms_1 = require("@angular/forms");
const i0 = require("@angular/core");
class SharedModule {
    static { this.ɵfac = function SharedModule_Factory(t) { return new (t || SharedModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: SharedModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [common_1.CommonModule,
            angular_1.IonicModule,
            forms_1.FormsModule, common_1.CommonModule,
            angular_1.IonicModule] }); }
}
exports.SharedModule = SharedModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(SharedModule, { declarations: [search_bar_component_1.SearchBarComponent], imports: [common_1.CommonModule,
        angular_1.IonicModule,
        forms_1.FormsModule], exports: [common_1.CommonModule,
        angular_1.IonicModule,
        search_bar_component_1.SearchBarComponent] }); })();
