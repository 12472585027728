"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FindTuneStateService = void 0;
const environment_1 = require("../../../../../environments/environment");
const enums_enum_1 = require("../../../classes-enums-interfaces-types/enums/find-tune/enums.enum");
const interfaces_1 = require("../../../classes-enums-interfaces-types/interfaces/interfaces");
const my_server_service_1 = require("../../my-server/my-server.service");
const enums_enum_2 = require("../../../classes-enums-interfaces-types/enums/find-tune/enums.enum");
const core_1 = require("@angular/core");
const router_1 = require("@angular/router");
const rxjs_1 = require("rxjs");
const enums_shared_enum_1 = require("../../../../../../backend/classes-enums-interfaces-types/enums/enums.shared.enum");
//import { mGlobal } from '../../mGlobal.js';
const mglobal_1 = require("../../../mglobal");
const player_service_1 = require("../../music/player.service");
const common_1 = require("@angular/common");
//import type {SearchStatesEnum} from './../../classes-enums-interfaces-types/interfaces';
const Sentry = require("@sentry/angular-ivy");
const error_handler_service_1 = require("../../error-handler/error-handler.service");
const i0 = require("@angular/core");
const i1 = require("@angular/router");
const i2 = require("../../music/player.service");
const i3 = require("../../my-server/my-server.service");
const i4 = require("@angular/common");
const i5 = require("../../error-handler/error-handler.service");
// Local states is not sufficient, this service main task is to handle back clicks while keeping the correct state.
var selectStateInit;
if (environment_1.ENV.PRODUCTION) {
    selectStateInit = enums_enum_1.SelectStatesEnum.catOne;
}
else {
    selectStateInit = enums_enum_1.SelectStatesEnum.catOne;
}
class FindTuneStateService {
    constructor(router, ps, mServer, location, eh, ngZone) {
        this.router = router;
        this.ps = ps;
        this.mServer = mServer;
        this.location = location;
        this.eh = eh;
        this.ngZone = ngZone;
        this.elemSelected = false;
        this.selectedTune = null;
        this.searchText = 'love';
        this.segment = enums_enum_2.SegmentStatesEnum.search;
        this.segmentPrev = null;
        this.segmentChanged$ = new rxjs_1.Subject();
        ///selectedElemSubj = new BehaviorSubject(null);
        this.searchB$ = new rxjs_1.BehaviorSubject('');
        this.selectedTuneObs = null;
        this.select = {
            listElemClicked: (listElem) => {
                //Todo, make instanceof work by creating TuneV? from server objcet
                if (listElem.type == enums_shared_enum_1.ListElementTypesEnum.tune) {
                    this.ps.loopTuneRequest(listElem);
                }
                else {
                    // happens for slimSpotTrack
                    const parentThis = this;
                    this.mServer
                        //.getTracksTuneByTrackId(listElem.trackId)
                        .getTracksFirstTuneByTrackNameAndArtistName(listElem.trackName, listElem.artistName)
                        .subscribe((tune) => {
                        //#fix
                        // if (tune == this.selectedTune && tune){
                        // 	this.ps.pauseTrack()
                        // 	this.router.navigate(
                        // 		['/edit-tune'],
                        // 		{ state: {slimSpotTrack : listElem}}
                        // 	)
                        // }
                        if (tune) {
                            //this.selectedTune = tune;
                            this.ps.loopTuneRequest(tune);
                            // this.selectedElemSubj.next(
                            // 	this.selectedTune
                            // );
                        }
                        else {
                            //should never happen
                            parentThis.router.navigate(['/edit-tune'], { state: { slimSpotTrack: listElem } });
                        }
                    }, (e) => {
                        if (e.status == 404) {
                            parentThis.router.navigate(['/edit-tune'], { state: { slimSpotTrack: listElem, } });
                        }
                        else {
                            console.error('getTracksTune error', e);
                            this.eh.logSentryError(e);
                        }
                    });
                }
            },
            //state: SelectStatesEnum.catOne,
            state: selectStateInit,
            statePrevs: [],
            stateB$: new rxjs_1.BehaviorSubject({ state: selectStateInit, statePrev: null }),
        };
        this.search = {
            hasSearchedB$: new rxjs_1.BehaviorSubject(false),
            listElemClicked: (listElem) => {
                console.log('containerTune');
                if (listElem.type == enums_shared_enum_1.ListElementTypesEnum.slimSpotTrack) {
                    if (mglobal_1.mGlobal.isAdmin && false) {
                        this.router.navigate(['/edit-tune'], {
                            state: { slimSpotTrack: listElem },
                        });
                    }
                    else {
                        const parentThis = this;
                        this.mServer
                            //.getTracksTuneByTrackId(listElem.trackId)
                            .getTracksFirstTuneByTrackNameAndArtistName(listElem.trackName, listElem.artistName)
                            .subscribe((tune) => {
                            // will be tune or slimSpotTrack from user playlist
                            if (tune) {
                                //this.selectedTune = tune;
                                this.ps.loopTuneRequest(tune);
                                // this.selectedElemSubj.next(
                                //     this.selectedTune
                                // );
                            }
                            else {
                                this.ps.pauseTrack();
                                this.ngZone.run(() => {
                                    this.router.navigate(['/edit-tune'], { state: { slimSpotTrack: listElem } });
                                });
                            }
                        }, (e) => {
                            //this.eh.logSentryError(e)
                            if (e.status == 404) {
                                this.ps.pauseTrack();
                                this.ngZone.run(() => {
                                    this.router.navigate(['/edit-tune'], { state: { slimSpotTrack: listElem, } });
                                });
                            }
                            else {
                                this.eh.logSentryError(e);
                                console.error('getTracksTune error', e);
                            }
                        });
                    }
                    //will never currently happen tunes are not pre loaded
                }
                else if (listElem.type == enums_shared_enum_1.ListElementTypesEnum.tune) {
                    if (this.selectedTune == listElem && this.selectedTune) {
                        this.ps.pauseTrack();
                        this.mServer.getTrackHasMultipleTunes(this.selectedTune.slimTuneTrack.trackId).subscribe((hasMultipleTunes) => {
                            let redirectRoute;
                            if (hasMultipleTunes) {
                                redirectRoute = '/track';
                            }
                            else {
                                redirectRoute = '/edit-tune';
                            }
                            this.ngZone.run(() => {
                                this.router.navigateByUrl(redirectRoute, { state: { slimTuneTrack: this.selectedTune.slimTuneTrack } });
                            });
                        }, e => {
                            console.log('error getting track has multiple tunes', e);
                            this.eh.logSentryError(e);
                        });
                    }
                    else {
                        this.ps.loopTuneRequest(listElem);
                    }
                    //const tune = this.getTuneFromTrigger(trigger)
                    //this.ps.nextLoopItr(1)
                    this.selectedTune = listElem;
                    ///this.selectedElemSubj.next(this.selectedTune);
                }
            },
            state: enums_enum_1.SearchStatesEnum.noSearch,
            statePrevs: [],
            stateB$: new rxjs_1.BehaviorSubject(enums_enum_1.SearchStatesEnum.noSearch),
        };
        console.log(mglobal_1.test1);
    }
    //not accourding to protocol state handling, should interact with any routing from any route and reset state then
    initState() {
        this.elemSelected = false;
        //this.selectedTune = null
        //this.selectedElemSubj.next(null);
        if (environment_1.ENV.DEBUG) {
            this.selectedTune = mglobal_1.mGlobal.debugTune;
            ///this.selectedElemSubj.next(mGlobal.debugTune);
        }
        this.initSegment();
        this.searchText = '';
        this.searchB$.next(this.searchText);
        this.select.state = selectStateInit;
        this.select.statePrevs = [];
        this.select.stateB$.next({ state: selectStateInit, statePrev: null });
        this.search.state = enums_enum_1.SearchStatesEnum.noSearch;
        this.search.statePrevs = [];
        this.search.stateB$.next(enums_enum_1.SearchStatesEnum.noSearch);
        this.searchB$.next('');
        this.search.hasSearchedB$.next(false);
        if (this.selectedTuneObs == null) {
            this.selectedTuneObs = this.ps.selectedTune$.subscribe((tune) => {
                if (tune)
                    this.selectedTune = tune;
            });
        }
    }
    initSegment() {
        this.router.navigateByUrl(`/find-tune/search`);
        //this.router.navigateByUrl(`/find-tune/select`);
        this.segmentPrev = null;
        this.segment = enums_enum_2.SegmentStatesEnum.search;
        this.segmentChanged$.next(this.segment);
    }
    searchEntered(searchText) {
        this.searchText = searchText;
        if (this.segment == enums_enum_2.SegmentStatesEnum.select && this.select.state == enums_enum_1.SelectStatesEnum.listSpotPlaylistAll) {
            this.setSelectState(enums_enum_1.SelectStatesEnum.spotPlaylistsSearch);
        }
        else {
            this.setSegment(enums_enum_2.SegmentStatesEnum.search);
            if (searchText.charAt(0) == '#') {
                this.setSearchState(enums_enum_1.SearchStatesEnum.tunes);
            }
            else {
                this.setSearchState(enums_enum_1.SearchStatesEnum.tracks);
            }
            this.search.hasSearchedB$.next(true);
        }
        this.searchB$.next(searchText);
        console.log(`searchText: ${searchText}`);
    }
    hardBack() {
        Sentry.addBreadcrumb({
            category: "pauseUndefined",
            message: "hardBack",
            level: "info",
        });
        //this.ps.pauseTrack()
        const hasHistory = this.router.navigated;
        if (hasHistory)
            this.location.back();
    }
    sendTune() { }
    backClick() {
        if (this.segment == enums_enum_2.SegmentStatesEnum.select) {
            if (this.select.state == enums_enum_1.SelectStatesEnum.catOne) {
                this.setSegmentBack();
            }
            else {
                this.setSelectStateToPrev();
            }
        }
        else if (this.segment == enums_enum_2.SegmentStatesEnum.search) {
            if (!this.search.statePrevs || this.search.statePrevs.length == 0) {
                this.setSegmentBack();
            }
            else {
                this.setSearchStateToPrev();
            }
        }
    }
    getSearch() {
        return this.searchText;
    }
    setSegmentBack() {
        if (this.segmentPrev != null) {
            this.setSegment(this.segmentPrev);
            this.segmentPrev = null;
            this.segmentChanged$.next(this.segment);
        }
        else {
            this.hardBack();
        }
    }
    setSegment(segment) {
        if (this.segment != segment) {
            let lowerCaseSegmentStr = segment.toLocaleLowerCase();
            this.router.navigateByUrl(`/find-tune/${lowerCaseSegmentStr}`);
            this.segmentPrev = this.segment;
            this.segment = segment;
        }
        this.segmentChanged$.next(segment);
    }
    setSelectState(state) {
        const statePrev = this.select.state;
        this.select.statePrevs.push(this.select.state);
        this.select.state = state;
        this.select.stateB$.next({ state: this.select.state, statePrev: statePrev });
    }
    setSearchState(state) {
        if (this.search.state != enums_enum_1.SearchStatesEnum.noSearch) {
            this.search.statePrevs.push(this.search.state);
        }
        this.search.state = state;
        this.search.stateB$.next(state);
    }
    setSelectStateToPrev() {
        const statePrev = this.select.state;
        this.select.state = this.select.statePrevs.pop();
        this.select.stateB$.next({ state: this.select.state, statePrev: statePrev });
    }
    setSearchStateToPrev() {
        this.search.state = this.search.statePrevs.pop();
        this.search.stateB$.next(this.search.state);
    }
    static { this.ɵfac = function FindTuneStateService_Factory(t) { return new (t || FindTuneStateService)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.PlayerService), i0.ɵɵinject(i3.MyServerService), i0.ɵɵinject(i4.Location), i0.ɵɵinject(i5.ErrorHandlerService), i0.ɵɵinject(i0.NgZone)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: FindTuneStateService, factory: FindTuneStateService.ɵfac, providedIn: 'root' }); }
}
exports.FindTuneStateService = FindTuneStateService;
