"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WaitListPageModule = void 0;
const common_1 = require("@angular/common");
const forms_1 = require("@angular/forms");
const angular_1 = require("@ionic/angular");
const wait_list_routing_module_1 = require("./wait-list-routing.module");
const wait_list_page_1 = require("./wait-list.page");
const i0 = require("@angular/core");
class WaitListPageModule {
    static { this.ɵfac = function WaitListPageModule_Factory(t) { return new (t || WaitListPageModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: WaitListPageModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [common_1.CommonModule,
            forms_1.FormsModule,
            angular_1.IonicModule,
            wait_list_routing_module_1.WaitListPageRoutingModule] }); }
}
exports.WaitListPageModule = WaitListPageModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(WaitListPageModule, { declarations: [wait_list_page_1.WaitListPage], imports: [common_1.CommonModule,
        forms_1.FormsModule,
        angular_1.IonicModule,
        wait_list_routing_module_1.WaitListPageRoutingModule] }); })();
