"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DevPageRoutingModule = void 0;
const router_1 = require("@angular/router");
const dev_page_1 = require("./dev.page");
const i0 = require("@angular/core");
const i1 = require("@angular/router");
const routes = [
    {
        path: '',
        component: dev_page_1.DevPage
    }
];
class DevPageRoutingModule {
    static { this.ɵfac = function DevPageRoutingModule_Factory(t) { return new (t || DevPageRoutingModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: DevPageRoutingModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [router_1.RouterModule.forChild(routes), router_1.RouterModule] }); }
}
exports.DevPageRoutingModule = DevPageRoutingModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DevPageRoutingModule, { imports: [i1.RouterModule], exports: [router_1.RouterModule] }); })();
