"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HelperFunctionsService = void 0;
const angular_1 = require("@ionic/angular");
const i0 = require("@angular/core");
const i1 = require("@ionic/angular");
class HelperFunctionsService {
    constructor(toastController) {
        this.toastController = toastController;
    }
    objectToQueryParams(queryObject) {
        return Object.keys(queryObject).map(key => key + '=' + queryObject[key]).join('&');
    }
    //#opt make deep
    setHasObjectWithProperty(set, propertyName, valueToMatch) {
        for (let item of set) {
            if (item[propertyName] == valueToMatch && valueToMatch)
                return true;
        }
        return false;
    }
    //#Opt shouldntbe in service
    push(baseArray, elemsToPush) {
        // the array can be null, hence object is needed
        // #opt throw if not array
        if (Array.isArray(elemsToPush)) {
            baseArray.push(...elemsToPush);
        }
        else {
            baseArray.push(elemsToPush);
        }
    }
    delay(ms) {
        return new Promise(resolve => {
            setTimeout(resolve, ms);
        });
    }
    isStringInEnum(value, enumType) {
        return Object.values(enumType).includes(value);
    }
    tryParseBoolean(str) {
        const lowerStr = str.toLowerCase();
        if (lowerStr === "true") {
            return { isBoolean: true, value: true };
        }
        else if (lowerStr === "false") {
            return { isBoolean: true, value: false };
        }
        else {
            return { isBoolean: false, value: false };
        }
    }
    consoleLog(msg, shouldLog = true, guiOutput = false) {
        if (shouldLog) {
            console.log(msg);
        }
        // if(guiOutput){
        // 	const log = document.querySelector('#log');
        // 	const msg = document.createElement('div');
        //     //msg.classList.add(verb);
        //     msg.textContent = verb + ': ' + Array.prototype.slice.call(arguments).join(' ');
        //     log.appendChild(msg);
        // }
    }
    static { this.ɵfac = function HelperFunctionsService_Factory(t) { return new (t || HelperFunctionsService)(i0.ɵɵinject(i1.ToastController)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: HelperFunctionsService, factory: HelperFunctionsService.ɵfac, providedIn: 'root' }); }
}
exports.HelperFunctionsService = HelperFunctionsService;
