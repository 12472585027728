"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatPageModule = void 0;
const chat_message_component_1 = require("./chat-message/chat-message.component");
const common_1 = require("@angular/common");
const forms_1 = require("@angular/forms");
const angular_1 = require("@ionic/angular");
const chat_routing_module_1 = require("./chat-routing.module");
const chat_page_1 = require("./chat.page");
const footer_bar_module_1 = require("../../components/footer-bar/footer-bar.module");
const i0 = require("@angular/core");
class ChatPageModule {
    static { this.ɵfac = function ChatPageModule_Factory(t) { return new (t || ChatPageModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: ChatPageModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [common_1.CommonModule,
            forms_1.FormsModule,
            angular_1.IonicModule,
            chat_routing_module_1.ChatPageRoutingModule,
            footer_bar_module_1.FooterBarModule] }); }
}
exports.ChatPageModule = ChatPageModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(ChatPageModule, { declarations: [chat_page_1.ChatPage,
        chat_message_component_1.ChatMessageComponent], imports: [common_1.CommonModule,
        forms_1.FormsModule,
        angular_1.IonicModule,
        chat_routing_module_1.ChatPageRoutingModule,
        footer_bar_module_1.FooterBarModule] }); })();
