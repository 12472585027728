"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MyLibraryPageRoutingModule = void 0;
const router_1 = require("@angular/router");
const my_library_page_1 = require("./my-library.page");
const i0 = require("@angular/core");
const i1 = require("@angular/router");
const routes = [
    {
        path: '',
        component: my_library_page_1.MyLibraryPage,
        //canActivate:[mCanActivateService],
    },
    {
        path: '**',
        redirectTo: ''
    }
];
class MyLibraryPageRoutingModule {
    static { this.ɵfac = function MyLibraryPageRoutingModule_Factory(t) { return new (t || MyLibraryPageRoutingModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: MyLibraryPageRoutingModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [router_1.RouterModule.forChild(routes), router_1.RouterModule] }); }
}
exports.MyLibraryPageRoutingModule = MyLibraryPageRoutingModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(MyLibraryPageRoutingModule, { imports: [i1.RouterModule], exports: [router_1.RouterModule] }); })();
