"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchHandlerModule = void 0;
const angular_1 = require("@ionic/angular");
const search_handler_routing_module_1 = require("./search-handler-routing.module");
const common_1 = require("@angular/common");
const search_handler_component_1 = require("./search-handler.component");
const list_element_module_1 = require("../../components/list-element/list-element.module");
const i0 = require("@angular/core");
class SearchHandlerModule {
    static { this.ɵfac = function SearchHandlerModule_Factory(t) { return new (t || SearchHandlerModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: SearchHandlerModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [common_1.CommonModule,
            search_handler_routing_module_1.SearchHandlerRoutingModule,
            angular_1.IonicModule,
            list_element_module_1.ListElementModule] }); }
}
exports.SearchHandlerModule = SearchHandlerModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(SearchHandlerModule, { declarations: [search_handler_component_1.SearchHandlerComponent], imports: [common_1.CommonModule,
        search_handler_routing_module_1.SearchHandlerRoutingModule,
        angular_1.IonicModule,
        list_element_module_1.ListElementModule], exports: [search_handler_component_1.SearchHandlerComponent] }); })();
