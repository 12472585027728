"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DevRouterPageModule = void 0;
const pwa_install_prompt_ios_module_1 = require("./../components/pwa-install-prompt/ios/pwa-install-prompt-ios.module");
const common_1 = require("@angular/common");
const forms_1 = require("@angular/forms");
const angular_1 = require("@ionic/angular");
const dev_router_routing_module_1 = require("./dev-router-routing.module");
const dev_router_page_1 = require("./dev-router.page");
const chips_1 = require("@angular/material/chips");
const form_field_1 = require("@angular/material/form-field");
const input_1 = require("@angular/material/input");
const i0 = require("@angular/core");
class DevRouterPageModule {
    static { this.ɵfac = function DevRouterPageModule_Factory(t) { return new (t || DevRouterPageModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: DevRouterPageModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [common_1.CommonModule,
            forms_1.FormsModule,
            angular_1.IonicModule,
            dev_router_routing_module_1.DevRouterPageRoutingModule,
            chips_1.MatChipsModule,
            input_1.MatInputModule,
            form_field_1.MatFormFieldModule,
            pwa_install_prompt_ios_module_1.PwaInstallPromptIosModule] }); }
}
exports.DevRouterPageModule = DevRouterPageModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DevRouterPageModule, { declarations: [dev_router_page_1.DevRouterPage], imports: [common_1.CommonModule,
        forms_1.FormsModule,
        angular_1.IonicModule,
        dev_router_routing_module_1.DevRouterPageRoutingModule,
        chips_1.MatChipsModule,
        input_1.MatInputModule,
        form_field_1.MatFormFieldModule,
        pwa_install_prompt_ios_module_1.PwaInstallPromptIosModule] }); })();
