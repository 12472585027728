"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorHandlerService = void 0;
const Sentry = require("@sentry/angular-ivy");
const global_service_1 = require("../global.service");
const player_service_1 = require("../music/player.service");
const i0 = require("@angular/core");
const i1 = require("../global.service");
const i2 = require("../music/player.service");
class ErrorHandlerService {
    constructor(gs, ps) {
        this.gs = gs;
        this.ps = ps;
        this.showSentryLoggedToast = true;
    }
    setShowSentryLoggedToast(value) {
        this.showSentryLoggedToast = value;
    }
    getShowSentryLoggedToast() {
        return this.showSentryLoggedToast;
    }
    async getTuneItSentryContext() {
        let spotActiveDeviceId;
        let spotDevices;
        let tuneItContext;
        try {
            try {
                spotActiveDeviceId = await this.ps.getNvcDeviceId();
            }
            catch (e) {
                console.error('error getting activeDeviceId sentryContext', e);
                spotActiveDeviceId = e.toString();
            }
            try {
                spotDevices = await this.ps.getSpotDevices();
            }
            catch (e) {
                console.error('error getting spotDevices sentryContext', e);
                spotDevices = e.toString();
            }
            tuneItContext = {
                dateOfOccurence: new Date(),
                contextData: {
                    isPwa: this.gs.isRunningAsPwa(),
                    spot: {
                        id: this.gs.getSpotId(),
                        spotAciveDeviceId: spotActiveDeviceId,
                        spotDevices: spotDevices
                    },
                    tuneItVersion: this.gs.getTuneItVersion(),
                    userAgent: this.gs.getUserAgent()
                },
                user: this.gs.getSpotId()
            };
        }
        catch (e) {
            tuneItContext = { setContextError: e.toString() };
        }
        if (this.showSentryLoggedToast) {
            this.gs.showToast({ msg: "Error log sent to server" });
        }
        return tuneItContext;
    }
    async logSentryError(e) {
        Sentry.captureException(e);
    }
    static { this.ɵfac = function ErrorHandlerService_Factory(t) { return new (t || ErrorHandlerService)(i0.ɵɵinject(i1.GlobalService), i0.ɵɵinject(i2.PlayerService)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ErrorHandlerService, factory: ErrorHandlerService.ɵfac, providedIn: 'root' }); }
}
exports.ErrorHandlerService = ErrorHandlerService;
