"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Message = exports.SlimSpotArtistV1 = exports.slimSpotTrackToSlimTuneTrack = exports.SlimSpotTrackV1 = exports.User = exports.TriggerSlim = exports.Tune = exports.FadeOptions = exports.TuneTrackV1 = exports.SlimTuneTrackV1 = exports.MTrackV1 = exports.MImageV1 = exports.MArtistV1 = exports.MAlbumV1 = exports.CategoryV2 = exports.ElemType = exports._id = exports.MHttpError = void 0;
//Version number starts on 1, it shouldn't...
const mglobal_1 = require("../../mglobal");
const enums_shared_enum_1 = require("../../../../../backend/classes-enums-interfaces-types/enums/enums.shared.enum");
class MHttpError extends Error {
    constructor(message, statusCode) {
        super(message);
        this.statusCode = statusCode;
    }
}
exports.MHttpError = MHttpError;
class _id {
    constructor() {
        this._id = null;
    }
}
exports._id = _id;
class ElemType extends _id {
    constructor() { super(); }
}
exports.ElemType = ElemType;
class CategoryV2 extends _id {
    constructor() {
        super();
        this.child = null; //#fix replace to children
        this.createdAt = new Date();
        this.name = null;
        this.parent = null;
    }
}
exports.CategoryV2 = CategoryV2;
class MAlbumV1 extends _id {
    constructor(id, images) {
        super();
        this.id = id;
        this.images = images;
    }
}
exports.MAlbumV1 = MAlbumV1;
class MArtistV1 extends _id {
    constructor(id, name, tunes, type) {
        super();
        this.createdAt = new Date();
        this.id = id;
        this.name = name;
        this.tunes = tunes;
        this.type = type;
    }
}
exports.MArtistV1 = MArtistV1;
//unessary but why not 
class MImageV1 extends _id {
    constructor() { super(); }
}
exports.MImageV1 = MImageV1;
class MTrackV1 extends _id {
    constructor(album, artists, availableMarkets, durationMs, id, language, popularity, // pop is dynamic and needs to be updated in intervals
    name) {
        super();
        //timestamp: Date
        this.createdAt = new Date(); // should be version 2
        this.album = album;
        this.artists = artists;
        this.availableMarkets = availableMarkets;
        this.durationMs = durationMs;
        this.id = id;
        this.language = language;
        this.popularity = popularity;
        this.name = name;
    }
}
exports.MTrackV1 = MTrackV1;
class SlimTuneTrackV1 {
    constructor(artistName = null, durationMs = -1, trackId = null, trackImg = null, trackName = null) {
        this.artistName = artistName,
            this.durationMs = durationMs,
            this.trackId = trackId,
            this.trackImg = trackImg,
            this.trackName = trackName;
    }
}
exports.SlimTuneTrackV1 = SlimTuneTrackV1;
class TuneTrackV1 extends _id {
    constructor() { super(); }
}
exports.TuneTrackV1 = TuneTrackV1;
class FadeOptions {
    constructor() {
        this.curveType = mglobal_1.mGlobal.fadeParams.fadeCurveType;
        this.fadeInDuration = mglobal_1.mGlobal.fadeParams.fadeInDuration;
        this.fadeOutDuration = mglobal_1.mGlobal.fadeParams.fadeOutDuration;
        this.lowestVolumePercent = mglobal_1.mGlobal.fadeParams.lowestVolumePercentOfOrig;
    }
}
exports.FadeOptions = FadeOptions;
class Tune extends _id {
    constructor() {
        super();
        this.categories = null;
        this.createdAt = new Date();
        this.createdBy = null;
        this.fadeOptions = new FadeOptions();
        this.instrumental = false;
        this.loops = 0;
        this.lyrics = null;
        this.popularity = 0;
        this.slimTuneTrack = new SlimTuneTrackV1();
        this.startTime = null;
        this.stopTime = null;
        this.tags = []; // either _ids or tags when lean, will be string array before saving from FE mem.
        this.tuneNumber = null; //#opt see backend model
        this.tuneTrack = null;
        this.triggers = [];
        this.type = null;
        this.version = null;
    }
}
exports.Tune = Tune;
class TriggerSlim extends _id {
    constructor() {
        super();
    }
}
exports.TriggerSlim = TriggerSlim;
class User {
    constructor(id, _token, _tokenExpirationDate) {
        this.id = id;
        this._token = _token;
        this._tokenExpirationDate = _tokenExpirationDate;
    }
    get token() {
        if (!this._tokenExpirationDate || new Date() > this._tokenExpirationDate) {
            return null;
        }
        return this._token;
    }
}
exports.User = User;
//////////////////////////// On DB ///////////////////
// On client only
class SlimSpotTrackV1 {
    constructor(artistId, artistName, durationMs, images, trackId, trackName, addedAt) {
        this.type = enums_shared_enum_1.ListElementTypesEnum.slimSpotTrack;
        this.artistId = artistId;
        this.artistName = artistName;
        this.durationMs = durationMs;
        this.trackId = trackId;
        this.images = images;
        this.trackName = trackName;
        if (addedAt)
            this.addedAt = new Date(addedAt);
        //this.type=type //ARTIST, TRACK.TUNE
    }
}
exports.SlimSpotTrackV1 = SlimSpotTrackV1;
function slimSpotTrackToSlimTuneTrack(slimSpotTrack) {
    return new SlimTuneTrackV1(slimSpotTrack.artistName, slimSpotTrack.durationMs, slimSpotTrack.trackId, slimSpotTrack.images[0].url, slimSpotTrack.trackName);
}
exports.slimSpotTrackToSlimTuneTrack = slimSpotTrackToSlimTuneTrack;
class SlimSpotArtistV1 {
    constructor(id, images, name, type) {
        this.id = id;
        this.images = images;
        this.name = name;
        this.type = type;
    }
}
exports.SlimSpotArtistV1 = SlimSpotArtistV1;
class Message {
    constructor() {
        this.dateSent = new Date();
    }
}
exports.Message = Message;
