"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpotifySearchTypesEnum = exports.SearchBarPlaceholdersEnum = exports.SegmentStatesEnum = exports.SelectStatesEnum = exports.SearchStatesEnum = exports.SearchHandlerState = void 0;
var SegmentStatesEnum;
(function (SegmentStatesEnum) {
    SegmentStatesEnum["select"] = "SELECT";
    SegmentStatesEnum["search"] = "SEARCH";
})(SegmentStatesEnum || (SegmentStatesEnum = {}));
exports.SegmentStatesEnum = SegmentStatesEnum;
var SelectStatesEnum;
(function (SelectStatesEnum) {
    SelectStatesEnum["catOne"] = "CATEGORY_ONE";
    SelectStatesEnum["catTwo"] = "CATEGORY_TWO";
    SelectStatesEnum["listTunes"] = "LIST_TUNES";
    SelectStatesEnum["listSpotPlaylistAll"] = "LIST_SPOT_PLAYLISTS_ALL";
    SelectStatesEnum["spotPlaylistsSearch"] = "LIST_SPOT_PLAYLISTS_SEARCH";
    SelectStatesEnum["spotPlaylist"] = "SPOT_PLAYLIST";
})(SelectStatesEnum || (SelectStatesEnum = {}));
exports.SelectStatesEnum = SelectStatesEnum;
;
var SearchStatesEnum;
(function (SearchStatesEnum) {
    SearchStatesEnum["noSearch"] = "NO_SEARCH";
    SearchStatesEnum["noTunesFound"] = "NO_TUNES_FOUND";
    SearchStatesEnum["noSpotTracksFound"] = "NO_SPOT_TRACKS_FOUND";
    SearchStatesEnum["artistsTracksMixed"] = "ARTIST_TRACKS_MIX";
    SearchStatesEnum["artists"] = "ARTISTS";
    SearchStatesEnum["tracks"] = "TRACKS";
    SearchStatesEnum["tunes"] = "TUNES";
})(SearchStatesEnum || (SearchStatesEnum = {}));
exports.SearchStatesEnum = SearchStatesEnum;
;
var SearchHandlerState;
(function (SearchHandlerState) {
    SearchHandlerState["noSearch"] = "NO_SEARCH";
    SearchHandlerState["searched"] = "SEARCHED";
})(SearchHandlerState = exports.SearchHandlerState || (exports.SearchHandlerState = {}));
var SearchBarPlaceholdersEnum;
(function (SearchBarPlaceholdersEnum) {
    SearchBarPlaceholdersEnum["searchHere"] = "Search here...";
    SearchBarPlaceholdersEnum["searchPlaylists"] = "Search playlists";
})(SearchBarPlaceholdersEnum || (SearchBarPlaceholdersEnum = {}));
exports.SearchBarPlaceholdersEnum = SearchBarPlaceholdersEnum;
// This is defined as unexported interface in official spot ts
// And needs to be enum to be used with spotTs searchResult type
// Used not to type manual string. Maybe more proper solutions can be done
var SpotifySearchTypesEnum;
(function (SpotifySearchTypesEnum) {
    SpotifySearchTypesEnum["album"] = "album";
    SpotifySearchTypesEnum["artist"] = "artist";
    SpotifySearchTypesEnum["track"] = "track";
    SpotifySearchTypesEnum["playlist"] = "playlist";
    SpotifySearchTypesEnum["show"] = "show";
    SpotifySearchTypesEnum["episode"] = "episode";
    SpotifySearchTypesEnum["audiobook"] = "audiobook";
})(SpotifySearchTypesEnum || (SpotifySearchTypesEnum = {}));
exports.SpotifySearchTypesEnum = SpotifySearchTypesEnum;
