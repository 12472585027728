"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayTuneRoutingModule = void 0;
const play_tune_component_1 = require("./play-tune.component");
const router_1 = require("@angular/router");
const i0 = require("@angular/core");
const i1 = require("@angular/router");
const routes = [
    {
        path: '',
        component: play_tune_component_1.PlayTuneComponent,
    },
];
class PlayTuneRoutingModule {
    static { this.ɵfac = function PlayTuneRoutingModule_Factory(t) { return new (t || PlayTuneRoutingModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: PlayTuneRoutingModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [router_1.RouterModule.forChild(routes), router_1.RouterModule] }); }
}
exports.PlayTuneRoutingModule = PlayTuneRoutingModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(PlayTuneRoutingModule, { imports: [i1.RouterModule], exports: [router_1.RouterModule] }); })();
