"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatListPageModule = void 0;
const footer_bar_module_1 = require("./../../components/footer-bar/footer-bar.module");
const common_1 = require("@angular/common");
const forms_1 = require("@angular/forms");
const angular_1 = require("@ionic/angular");
const chat_list_routing_module_1 = require("./chat-list-routing.module");
const chat_list_page_1 = require("./chat-list.page");
const i0 = require("@angular/core");
class ChatListPageModule {
    static { this.ɵfac = function ChatListPageModule_Factory(t) { return new (t || ChatListPageModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: ChatListPageModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [common_1.CommonModule,
            forms_1.FormsModule,
            angular_1.IonicModule,
            chat_list_routing_module_1.ChatListPageRoutingModule,
            footer_bar_module_1.FooterBarModule] }); }
}
exports.ChatListPageModule = ChatListPageModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(ChatListPageModule, { declarations: [chat_list_page_1.ChatListPage], imports: [common_1.CommonModule,
        forms_1.FormsModule,
        angular_1.IonicModule,
        chat_list_routing_module_1.ChatListPageRoutingModule,
        footer_bar_module_1.FooterBarModule] }); })();
