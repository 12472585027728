"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PwaInstallPromptIosModule = void 0;
const common_1 = require("@angular/common");
const pwa_install_prompt_ios_component_1 = require("./pwa-install-prompt-ios.component");
const angular_1 = require("@ionic/angular");
const i0 = require("@angular/core");
class PwaInstallPromptIosModule {
    static { this.ɵfac = function PwaInstallPromptIosModule_Factory(t) { return new (t || PwaInstallPromptIosModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: PwaInstallPromptIosModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [common_1.CommonModule,
            angular_1.IonicModule] }); }
}
exports.PwaInstallPromptIosModule = PwaInstallPromptIosModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(PwaInstallPromptIosModule, { declarations: [pwa_install_prompt_ios_component_1.PwaInstallPromptIosComponent], imports: [common_1.CommonModule,
        angular_1.IonicModule], exports: [pwa_install_prompt_ios_component_1.PwaInstallPromptIosComponent] }); })();
