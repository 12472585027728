"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FooterBarRoutingModule = void 0;
const router_1 = require("@angular/router");
const i0 = require("@angular/core");
const i1 = require("@angular/router");
const routes = [];
class FooterBarRoutingModule {
    static { this.ɵfac = function FooterBarRoutingModule_Factory(t) { return new (t || FooterBarRoutingModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: FooterBarRoutingModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [router_1.RouterModule.forChild(routes), router_1.RouterModule] }); }
}
exports.FooterBarRoutingModule = FooterBarRoutingModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(FooterBarRoutingModule, { imports: [i1.RouterModule], exports: [router_1.RouterModule] }); })();
