"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authHttpInterceptorService = void 0;
const http_1 = require("@angular/common/http");
const router_1 = require("@angular/router");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const environment_1 = require("../../../../../environments/environment");
const i0 = require("@angular/core");
const i1 = require("@angular/router");
class authHttpInterceptorService {
    constructor(router) {
        this.router = router;
    }
    intercept(req, next) {
        console.log('in interceptor');
        let ok;
        //#FIX check domain properly
        if (req.url.includes(environment_1.ENV.DOMAIN_OF_BACKEND)) {
            /*     req = req.clone({
                headers: new HttpHeaders({
                    'Authorization': 'Bearer ' +this.sd.spotAccessToken
                })
                }); */
            //#LU, safe?
            console.log('http intercept, was backend');
            req = req.clone({
                withCredentials: true
            });
        }
        return next.handle(req)
            .pipe((0, operators_1.catchError)((error) => {
            if (error.error instanceof ErrorEvent) {
                console.error('This is client side error', error.message);
            }
            else {
                console.error('This is server side error', error.message);
                if (error.status == 401) {
                    this.router.navigateByUrl('/login');
                }
            }
            //return throwError(() => new Error('crap'));
            return (0, rxjs_1.throwError)(() => error);
        }));
    }
    static { this.ɵfac = function authHttpInterceptorService_Factory(t) { return new (t || authHttpInterceptorService)(i0.ɵɵinject(i1.Router)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: authHttpInterceptorService, factory: authHttpInterceptorService.ɵfac, providedIn: 'root' }); }
}
exports.authHttpInterceptorService = authHttpInterceptorService;
