"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthService = void 0;
const mhttp_service_1 = require("./../helper-functions/mhttp/mhttp.service");
const global_service_1 = require("./../global.service");
const player_service_1 = require("./../music/player.service");
const ngx_cookie_service_1 = require("ngx-cookie-service");
const http_1 = require("@angular/common/http");
const router_1 = require("@angular/router");
const rxjs_1 = require("rxjs");
const environment_1 = require("../../../../environments/environment");
const core_1 = require("@capacitor/core");
const ngx_1 = require("@awesome-cordova-plugins/in-app-browser/ngx");
const http_2 = require("@capacitor-community/http");
const preferences_1 = require("@capacitor/preferences");
const angular_1 = require("@ionic/angular");
const enums_enum_1 = require("../../classes-enums-interfaces-types/enums/enums.enum");
const error_handler_service_1 = require("../error-handler/error-handler.service");
const i0 = require("@angular/core");
const i1 = require("./../helper-functions/mhttp/mhttp.service");
const i2 = require("@angular/common/http");
const i3 = require("@angular/router");
const i4 = require("ngx-cookie-service");
const i5 = require("@awesome-cordova-plugins/in-app-browser/ngx");
const i6 = require("./../music/player.service");
const i7 = require("./../global.service");
const i8 = require("@ionic/angular");
const i9 = require("../error-handler/error-handler.service");
class AuthService {
    constructor(mhttp, http, router, cookieService, iab, ps, gs, toastController, eh) {
        this.mhttp = mhttp;
        this.http = http;
        this.router = router;
        this.cookieService = cookieService;
        this.iab = iab;
        this.ps = ps;
        this.gs = gs;
        this.toastController = toastController;
        this.eh = eh;
        this.user = new rxjs_1.BehaviorSubject(null);
        this.hasLoggedIn = null; // a user w/o sign up code is still logged in
        this.isSignedUp = null;
        this.backendRedirectPath = 'backend-redirect-iab';
        this.browsers = [];
        this.inAppBrowserOptions = {
            toolbarposition: 'top',
            toolbar: 'yes',
            keyboardDisplayRequiresUserAction: 'yes',
            location: 'yes',
            toolbartranslucent: 'no',
        };
        this.loggedIn$ = new rxjs_1.BehaviorSubject(false);
    }
    async iabLoginCallback(browser) {
        browser.on('message').subscribe(params => {
            console.log('message callback');
            console.log(params);
        });
        browser.on('loaderror').subscribe(params => {
            console.log(params.url);
            console.log(params.message);
            console.log('close cordova browser');
            this.closeBrowsers();
        });
        browser.on('loadstart').subscribe(async (params) => {
            try {
                console.log(`onLoadstart`);
                console.log(params);
                if (params.url.includes(this.backendRedirectPath
                //'localhost'
                )) {
                    const url = new URL(params.url);
                    const search = new URLSearchParams(url.search);
                    const frontendRedirectPath = search.get('frontendRedirectPath') ?? 'find-tune';
                    console.log(`frontendRedirectPath: ${frontendRedirectPath}`);
                    if (frontendRedirectPath === 'login') {
                        await http_2.Http.clearAllCookies();
                        //this.ps.setSpotAccessToken(null)
                        this.closeBrowsers();
                        this.router.navigateByUrl('login');
                    }
                    else {
                        const hash = new URLSearchParams(url.hash.replace('#', ''));
                        const spotTokens = decodeURIComponent(hash.get('spotTokens') || '');
                        const connectSid = decodeURIComponent(hash.get('connectSid') || '');
                        if (spotTokens) {
                            await http_2.Http.setCookie({
                                url: environment_1.ENV.DOMAIN_OF_BACKEND,
                                key: 'spotTokens',
                                value: spotTokens
                            });
                            console.log(JSON.parse(spotTokens));
                            this.ps.setSpotAccessToken(JSON.parse(spotTokens));
                            this.sethasLoggedIn(true); // will redirect to sign up page on first load
                        }
                        if (connectSid) {
                            await http_2.Http.setCookie({
                                url: environment_1.ENV.DOMAIN_OF_BACKEND,
                                key: 'connect.sid',
                                value: connectSid
                            });
                        }
                        const domainOfBackend = environment_1.ENV.DOMAIN_OF_BACKEND;
                        //console.log(`domain of backend: ${domainOfBackend}`)
                        console.log(`Router url ${this.router.url}`);
                        console.log(`FE redirect ${frontendRedirectPath}`);
                        if (this.router.url == frontendRedirectPath) {
                            //QnD way to reattempt fetch of triggers in my library after discovery of first 
                            // 401
                            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                                console.log('skippedLocation');
                                this.router.navigate([frontendRedirectPath]);
                            });
                        }
                        else {
                            this.router.navigateByUrl(frontendRedirectPath);
                        }
                    }
                    this.closeBrowsers();
                }
            }
            catch (e) {
                console.error(`onHandleUrl: ${e}`);
                this.eh.logSentryError(e);
            }
        });
    }
    async signup(signUpDisplayName) {
        const url = new URL(environment_1.ENV.DOMAIN_OF_BACKEND);
        url.pathname = 'sign-up-backend';
        const search = new URLSearchParams();
        search.append('signUpDisplayName', signUpDisplayName);
        //search.append('signUpCode',signUpCode.toString())
        search.append('ngsw-bypass', 'true');
        if (this.gs.isNativeCapPlatform()) {
            search.append('platform', this.gs.getCapPlatform());
            url.search = search.toString();
            const browser = this.iab.create(url.toString(), '_blank', this.inAppBrowserOptions);
            this.browsers.push(browser);
            this.iabLoginCallback(browser);
        }
        else {
            url.search = search.toString();
            window.location.href = url.toString();
        }
    }
    async login(loginParams) {
        if (await this.gethasLoggedIn() || loginParams.calledFromGui ||
            loginParams.redirectPath.includes('/play-tune') ||
            loginParams.redirectPath.includes('/tune-list')) {
            //const queryStr = this.hF.objectToQueryParams(loginParams)
            const url = new URL(environment_1.ENV.DOMAIN_OF_BACKEND + '/login-backend');
            const search = new URLSearchParams(loginParams);
            search.append('ngsw-bypass', 'true');
            //const url2 = 'https://tune-it.app' +'/login?' +queryStr
            //this.router.navigateByUrl(url)
            //window.open('http://192.168.43.210:8080/login')
            console.log(this.gs.getCapPlatform());
            console.log(this.gs.isNativeCapPlatform());
            const capPlatform = this.gs.getCapPlatform();
            if (this.gs.isNativeCapPlatform() // || true
            ) {
                if (window.origin === environment_1.ENV.DOMAIN_OF_LR) {
                    search.append('liveReload', 'true');
                }
                search.append('platform', capPlatform);
                url.search = search.toString();
                console.log(url.toString());
                const browser = this.iab.create(url.toString(), '_blank', this.inAppBrowserOptions);
                this.browsers.push(browser);
                this.iabLoginCallback(browser);
            }
            else {
                url.search = search.toString();
                console.log(`window redirecit FE ${url.toString()}`);
                window.location.href = url.toString();
            }
        }
        else {
            console.log('Login else');
            this.router.navigateByUrl('/login');
        }
    }
    closeBrowsers() {
        console.log(`Nbr of browser: ${this.browsers?.length}`);
        this.browsers.forEach(browser => {
            if (browser) {
                browser.close();
            }
        });
        this.browsers = [];
    }
    async logout() {
        try {
            //Do the request to clear session aka http only cookie
            this.http.get(`${environment_1.ENV.DOMAIN_OF_BACKEND}/logout`).toPromise();
            http_2.Http.clearAllCookies();
            this.user.next(null);
            localStorage.removeItem('userData');
            if (this.tokenExpirationTimer) {
                clearTimeout(this.tokenExpirationTimer);
            }
            this.tokenExpirationTimer = null;
            //for native
            //await Preferences.set({key:'hasLoggedIn', value:'false'})
            this.sethasLoggedIn(false);
            this.gs.showToast({
                msg: `You've logged out`,
                duration: 4000,
                type: enums_enum_1.ToastEnum.success,
                header: 'Message'
            });
            this.router.navigateByUrl('/login');
        }
        catch (e) {
            console.error('Error logging out', e);
            this.eh.logSentryError(e);
            this.gs.showToast({ duration: 5000, msg: 'Error logging out', type: enums_enum_1.ToastEnum.error });
        }
    }
    autoRefreshToken(expirationDuration) {
        this.tokenExpirationTimer = setTimeout(() => {
            this.refreshToken();
        }, expirationDuration);
    }
    saveAuthCookie() {
    }
    refreshToken() {
        const refreshToken = JSON.parse(localStorage.getItem('userData')).refreshToken;
        if (!refreshToken) {
            return;
        }
        let body = {
            grant_type: 'refresh_token',
            refresh_token: refreshToken
        };
        this.http.post(`${environment_1.ENV.DOMAIN_OF_BACKEND}/refresh-token`, body);
    }
    async sethasLoggedIn(hasLoggedIn) {
        this.loggedIn$.next(hasLoggedIn);
        this.hasLoggedIn = hasLoggedIn;
        //#FIX what if error
        if (core_1.Capacitor.isNativePlatform()) {
            await preferences_1.Preferences.set({ key: 'hasLoggedIn', value: 'true' });
        }
        else {
            const hasLoggedInStr = hasLoggedIn ? 'true' : 'false';
            this.cookieService.set('hasLoggedIn', hasLoggedInStr);
            this.hasLoggedIn = hasLoggedIn;
        }
    }
    gethasLoggedIn() {
        if (this.hasLoggedIn == null) {
            // if(Capacitor.isNativePlatform()){
            //     const {value} = await Preferences.get({key:'hasLoggedIn'})
            //     this.hasLoggedIn = (value == 'true') ? true : false
            // } else {
            this.hasLoggedIn = this.cookieService.get('hasLoggedIn') == 'true' ? true : false;
        }
        return this.hasLoggedIn;
    }
    getIsSignedUp() {
        if (this.isSignedUp == null) {
            // if(Capacitor.isNativePlatform()){
            //     const {value} = await Preferences.get({key:'hasLoggedIn'})
            //     this.hasLoggedIn = (value == 'true') ? true : false
            // } else {
            this.isSignedUp = this.cookieService.get('isSignedUp') == 'true' ? true : false;
        }
        return this.isSignedUp;
    }
    static { this.ɵfac = function AuthService_Factory(t) { return new (t || AuthService)(i0.ɵɵinject(i1.MHttpService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.CookieService), i0.ɵɵinject(i5.InAppBrowser), i0.ɵɵinject(i6.PlayerService), i0.ɵɵinject(i7.GlobalService), i0.ɵɵinject(i8.ToastController), i0.ɵɵinject(i9.ErrorHandlerService)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: AuthService, factory: AuthService.ɵfac, providedIn: 'root' }); }
}
exports.AuthService = AuthService;
