"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppRoutingModule = exports.routes = void 0;
const router_1 = require("@angular/router");
/*
import { AuthComponent } from "./auth/auth.component";
import { EditTuneComponent } from "./edit-tune/edit-tune.component";
import { FunnelComponent } from "./funnel/funnel.component";
import { PlayTuneComponent } from "./play-tune/play-tune.component"; */
const m_can_activate_service_1 = require("./services/m-can-activate.service");
const environment_1 = require("../../environments/environment");
const i0 = require("@angular/core");
const i1 = require("@angular/router");
exports.routes = [
    //{ path: 'logged-in', component: LoggedInComponent},
    {
        path: '',
        //component:FindTunePage, 
        //loadChildren: () => import('./find-tune/find-tune.module').then(c => c.FindTunePageModule),
        pathMatch: 'full',
        redirectTo: environment_1.ENV.DEBUG ? 'dev-router' : 'find-tune'
        //redirectTo: '/find-tune'
        //redirectTo: '/dev-router'
        //canActivate:[mCanActivateService]
    },
    {
        path: 'play-tune',
        loadChildren: () => Promise.resolve().then(() => require('./play-tune/play-tune.module')).then(c => c.PlayTuneModule),
        canActivate: [m_can_activate_service_1.mCanActivateService]
    },
    {
        path: 'dev',
        loadChildren: () => Promise.resolve().then(() => require('./dev/dev.module')).then(c => c.DevPageModule),
        canActivate: [m_can_activate_service_1.mCanActivateService]
    },
    {
        path: 'edit-tune',
        loadChildren: () => Promise.resolve().then(() => require('./edit-tune/edit-tune.module')).then(m => m.EditTuneModule),
        canActivate: [m_can_activate_service_1.mCanActivateService]
    },
    {
        path: 'dev',
        loadChildren: () => Promise.resolve().then(() => require('./dev/dev.module')).then(m => m.DevPageModule),
        //canActivate: [mCanActivateService]
    },
    {
        path: 'find-tune',
        loadChildren: () => Promise.resolve().then(() => require('./find-tune/find-tune.module')).then(c => c.FindTunePageModule),
        canActivate: [m_can_activate_service_1.mCanActivateService]
    },
    /*   { path: 'funnel', component: FunnelComponent},
      { path: 'sign-up', component: AuthComponent}, */
    {
        path: 'chat',
        loadChildren: () => Promise.resolve().then(() => require('./chat-folder/chat/chat.module')).then(m => m.ChatPageModule)
    },
    {
        path: 'chat-list',
        loadChildren: () => Promise.resolve().then(() => require('./chat-folder/chat-list/chat-list.module')).then(m => m.ChatListPageModule)
    },
    {
        path: 'dev-router',
        loadChildren: () => Promise.resolve().then(() => require('./dev-router/dev-router.module')).then(m => m.DevRouterPageModule)
    },
    {
        path: 'sign-up',
        loadChildren: () => Promise.resolve().then(() => require('./auth/sign-up/sign-up.module')).then(m => m.SignUpPageModule)
    },
    {
        path: 'login',
        loadChildren: () => Promise.resolve().then(() => require('./auth/login/login.module')).then(m => m.LoginPageModule),
    },
    {
        path: 'my-library',
        loadChildren: () => Promise.resolve().then(() => require('./my-library/my-library.module')).then(m => m.MyLibraryPageModule),
        canActivate: [m_can_activate_service_1.mCanActivateService]
    },
    {
        path: 'track',
        loadChildren: () => Promise.resolve().then(() => require('./track/track.module')).then(m => m.TrackPageModule),
        canActivate: [m_can_activate_service_1.mCanActivateService]
    },
    {
        path: 'wait-list',
        loadChildren: () => Promise.resolve().then(() => require('./wait-list/wait-list.module')).then(m => m.WaitListPageModule)
    },
    {
        path: 'waitlist',
        redirectTo: '/wait-list',
    },
    {
        path: 'known-shortcomings',
        loadChildren: () => Promise.resolve().then(() => require('./known-shortcomings/known-shortcomings.module')).then(m => m.KnownShortcomingsPageModule)
    },
    {
        path: 'knownshortcomings',
        redirectTo: '/known-shortcomings',
    },
    {
        path: 'tune-list',
        loadChildren: () => Promise.resolve().then(() => require('./tune-list/tune-list.module')).then(m => m.TuneListPageModule),
        canActivate: [m_can_activate_service_1.mCanActivateService]
    },
    {
        path: '**',
        //component:FindTunePage, 
        //loadChildren: () => import('./find-tune/find-tune.module').then(c => c.FindTunePageModule),
        pathMatch: 'full',
        redirectTo: '/find-tune'
        //canActivate:[mCanActivateService]
    },
];
//{ path: 'logged-in', component: LoggedIn];
class AppRoutingModule {
    static { this.ɵfac = function AppRoutingModule_Factory(t) { return new (t || AppRoutingModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: AppRoutingModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [router_1.RouterModule.forRoot(exports.routes, {
                useHash: false,
                preloadingStrategy: router_1.PreloadAllModules,
                urlUpdateStrategy: 'eager'
            }), router_1.RouterModule] }); }
}
exports.AppRoutingModule = AppRoutingModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(AppRoutingModule, { imports: [i1.RouterModule], exports: [router_1.RouterModule] }); })();
