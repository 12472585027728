"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalService = void 0;
const not_recommended_browser_component_1 = require("./../components/modals/not-recommended-browser/not-recommended-browser.component");
const enums_enum_1 = require("./../classes-enums-interfaces-types/enums/enums.enum");
const angular_1 = require("@ionic/angular");
const core_1 = require("@angular/core");
const core_2 = require("@capacitor/core");
const ngx_cookie_service_1 = require("ngx-cookie-service");
const rxjs_1 = require("rxjs");
const interfaces_1 = require("../classes-enums-interfaces-types/interfaces/interfaces");
const preferences_1 = require("@capacitor/preferences");
const router_1 = require("@angular/router");
const environment_1 = require("../../../environments/environment");
const Sentry = require("@sentry/angular-ivy");
const helper_functions_service_1 = require("./helper-functions/helper-functions.service");
const error_handler_service_1 = require("./error-handler/error-handler.service");
const keyboard_1 = require("@capacitor/keyboard");
const i0 = require("@angular/core");
const i1 = require("ngx-cookie-service");
const i2 = require("@ionic/angular");
const i3 = require("@angular/router");
const i4 = require("./helper-functions/helper-functions.service");
class GlobalService {
    constructor(cookieService, toastController, router, injector, helper, modalControl) {
        this.cookieService = cookieService;
        this.toastController = toastController;
        this.router = router;
        this.injector = injector;
        this.helper = helper;
        this.modalControl = modalControl;
        this.sideMenuToggle$ = new rxjs_1.BehaviorSubject({ open: false, menuId: null });
        this.mNgTheme = enums_enum_1.MNgThemesE.dark;
        this.darkLightMode$ = new rxjs_1.BehaviorSubject(enums_enum_1.ColorThemeE.dark);
        this.showPwaPrompt$ = new rxjs_1.BehaviorSubject(null);
        this.debug = environment_1.ENV.DEBUG;
        this.visibilityChangeVisItr = 0;
        this.visibilityChangeHiddenItr = 0;
        this.isToastShowing = false;
        this.silentTrackId = '5XSKC4d0y0DfcGbvDOiL93';
        this.pwaUpdateAvailable$ = new rxjs_1.BehaviorSubject({ pwaReadyForInstall: false });
        this.userSettingsDefault = {
            prefersColorTheme: enums_enum_1.ColorThemeE.dark,
            showLyricsOnFirstLoop: false,
            hideLyricsAlways: false,
            experimentalLooping: true
        };
        this.localStorageFlags = null;
        this.localStorageFlagsDefault = {
            hasDismissedBrowserRecommendation: false,
            hasDismissedPwaInstallPrompt: false
        };
        this.userSettings$ = new rxjs_1.BehaviorSubject(null);
        this.mRouterEventParms = {
            // to not reload ui if action in lower page was canceled e.g. edit tune, no need to reload gui tune lib list
            pendingHistoryBackClick: false,
            previousUrl: null
        };
        this.currentUrl = null;
        this.spotAccessToken = null;
        this.globalError$ = new rxjs_1.BehaviorSubject({
            error: null,
            forRoute: null,
        });
        this.capPlatform = core_2.Capacitor.getPlatform();
        this.webOS = this.getDevicePlatform();
        this.initAsync();
        //this.platform = 'ios'
        this.router.events //.pairwise()
            .subscribe((event) => {
            //console.log('gService router event')
            //console.log(event)
            if (event instanceof router_1.NavigationStart) {
                if (event.navigationTrigger === 'popstate') {
                    // circular depency
                    Sentry.addBreadcrumb({
                        category: "pauseUndefined",
                        message: "global popstate",
                        level: "info",
                    });
                }
            }
            if (event instanceof router_1.NavigationEnd) {
                //this.mRouterEventParms.pendingHistoryBackClick = false
                //console.log("mmm navEnd")
                //this.injector.get(PlayerService).pauseTrack()
                this.mRouterEventParms.previousUrl = this.currentUrl;
                this.currentUrl = event.urlAfterRedirects;
                if (event.urlAfterRedirects == '/sign-up' || event.urlAfterRedirects == '/login') {
                    console.log('sign up router reg.');
                    setTimeout(() => {
                        console.log(`devicePlatform: ${this.getDevicePlatform()}`);
                        if (this.getDevicePlatform() == enums_enum_1.DevicePlatformE.android) {
                            console.log(this.deferredPrompt);
                            if (this.deferredPrompt) {
                                this.deferredPrompt
                                    .prompt()
                                    .then((userChoice) => {
                                    console.log(`Pwa user prompt outcome: ${userChoice?.outcome}`);
                                });
                            }
                        }
                        else if ((this.getDevicePlatform() == enums_enum_1.DevicePlatformE.ios && !this.isRunningAsPwa()) || this.debug) {
                            this.showPwaPrompt$.next({ devicePlatform: enums_enum_1.DevicePlatformE.ios, show: true });
                        }
                    }, 800);
                }
                this.runningRecommendedBrowserCheck(event.urlAfterRedirects);
            }
        });
    }
    async initAsync() {
        // use a key like a value and stringify with JSON if more settings need to be added
        try {
            const settings = (await preferences_1.Preferences.get({ key: "userSettings" })).value;
            this.userSettings = JSON.parse(settings);
            if (this.userSettings) {
                for (const key in this.userSettingsDefault) {
                    if (!this.userSettings.hasOwnProperty(key)) {
                        this.userSettings[key] = this.userSettingsDefault[key];
                    }
                }
            }
            else {
                this.userSettings = this.userSettingsDefault;
                this.userSettingUpdatePreferences();
            }
            this.setDarkLightMode(this.userSettings.prefersColorTheme);
            this.userSettings$.next(this.userSettings);
            const flags = (await preferences_1.Preferences.get({ key: "flags" })).value;
            this.localStorageFlags = JSON.parse(flags);
            if (this.localStorageFlags) {
                for (const key in this.localStorageFlags) {
                    if (!this.localStorageFlags.hasOwnProperty(key)) {
                        this.localStorageFlags[key] = this.localStorageFlags[key];
                    }
                }
            }
            else {
                this.localStorageFlags = this.localStorageFlagsDefault;
                this.storageFlagsUpdatePreferences();
            }
            this.runningRecommendedBrowserCheck(this.currentUrl);
        }
        catch (e) {
            console.error(`Error parsing user settings from JSON`);
            this.injector.get(error_handler_service_1.ErrorHandlerService).logSentryError(e);
        }
    }
    runningRecommendedBrowserCheck(route) {
        if (!route.includes('/play-tune') || !route.includes('/tune-list')) {
            if (this.localStorageFlags != null && !this.localStorageFlags.hasDismissedBrowserRecommendation && !this.isRunningRecommendedBrowser()) {
                let modal;
                setTimeout(() => {
                    modal = this.modalControl.create({
                        component: not_recommended_browser_component_1.NotRecommendedBrowserComponent,
                        id: enums_enum_1.ModalsE.notRecommendedBrowser,
                        cssClass: 'ion-modal-default'
                    }).then(modal => {
                        modal.onDidDismiss().then(() => {
                            this.localStorageFlags.hasDismissedBrowserRecommendation = true;
                            this.storageFlagsUpdatePreferences();
                        });
                        modal.present();
                    });
                }, 1000);
            }
        }
    }
    isNativeCapPlatform() {
        return this.capPlatform == 'web' ? false : true;
    }
    isRunningRecommendedBrowser() {
        if (this.getDevicePlatform() == enums_enum_1.DevicePlatformE.ios) {
            if (this.isRunningInBrowserName(enums_enum_1.BrowsersE.safari)) {
                return true;
            }
        }
        else if (this.getDevicePlatform() == enums_enum_1.DevicePlatformE.android) {
            if (this.isRunningInBrowserName(enums_enum_1.BrowsersE.chrome)) {
                return true;
            }
        }
        else {
            // better to not be annoying if unexptec behavuior
            return true;
        }
    }
    getCapPlatform() {
        return this.capPlatform;
    }
    getDevicePlatform() {
        const userAgent = navigator.userAgent;
        //console.log('userAgent')
        //console.log(userAgent)
        if (/android/i.test(userAgent)) {
            return enums_enum_1.DevicePlatformE.android;
        }
        else if (/iPad|iPhone|iPod/i.test(userAgent)) {
            return enums_enum_1.DevicePlatformE.ios;
        }
        else {
            return enums_enum_1.DevicePlatformE.unknown;
        }
    }
    isRunningIos() {
        return this.getDevicePlatform() == enums_enum_1.DevicePlatformE.ios;
    }
    isRunningInMessengerBrowser() {
        var ua = navigator.userAgent || navigator.vendor;
        return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
    }
    isRunningInChromeSim() {
        const isChrome = window.navigator.userAgent.indexOf('Mobile') !== -1;
        return isChrome;
    }
    hasActiveSession() {
        return this.cookieService.check('sid') ? true : false;
    }
    isRunningOnAndroid() {
        return /Android/i.test(navigator.userAgent);
    }
    isRunningInBrowserName(browserName) {
        const userAgent = navigator.userAgent;
        const vendor = navigator.vendor;
        switch (browserName) {
            case enums_enum_1.BrowsersE.chrome:
                return /Chrome/.test(userAgent) && /Google Inc/.test(vendor);
            case enums_enum_1.BrowsersE.safari:
                // Note: Safari's user agent also includes "Version/" prefix
                return /Safari/.test(userAgent) && /Apple Computer/.test(vendor);
            // Add more cases as needed for other browsers
            default:
                return false;
        }
    }
    //can only clear non httpOnly cookies, httpOnly is cleared on backend
    clearAllCookies() {
        this.cookieService.deleteAll();
    }
    getUserId() {
        //#opt error handling
        return this.cookieService.get('userId');
    }
    // only supports two toast at the same time, will bug nicely if more than 2 toast or different duration
    // #OPT input parametes should be able to do cleaner w/o o.<>
    async showToast(
    // msg: string, 
    // header: string = null,
    // duration: number = 3000,
    // color: string = 'dark',
    // type:  ToastEnum = ToastEnum.default 
    o) {
        o.duration = o.duration ?? 3000;
        o.type = o.type ?? enums_enum_1.ToastEnum.default;
        const toast = await this.toastController.create({
            color: o.color,
            duration: o.duration,
            header: o.header,
            message: o.msg,
        });
        const cssClasses = [];
        if (o.type == enums_enum_1.ToastEnum.warning && o.color != 'dark') {
            toast.color = 'warning';
            cssClasses.push('toast-white-border');
        }
        else if (o.type == enums_enum_1.ToastEnum.error && o.color != 'dark') {
            toast.header = o.header ?? "Error";
            toast.color = "danger";
            cssClasses.push('toast-white-border');
        }
        else {
            cssClasses.push('toast-with-border');
        }
        if (this.isToastShowing) {
            cssClasses.push('ion-toast-double-space');
        }
        toast.cssClass = cssClasses;
        toast.present();
        this.isToastShowing = !this.isToastShowing;
        setTimeout(() => {
            this.isToastShowing = !this.isToastShowing;
        }, o.duration);
    }
    getUserAgent() {
        return window.navigator.userAgent;
    }
    requestSideMenuToggle(open, menuId) {
        this.sideMenuToggle$.next({ open: open, menuId: menuId });
    }
    getDeferredPrompt() {
        return this.deferredPrompt;
    }
    setDeferredPrompt(dP) {
        if (dP)
            this.deferredPrompt = dP;
    }
    /*
    setAuthToken(newAuthToken){

        console.log("New Auth Token " + newAuthToken);
        this.authToken = newAuthToken.split("=")[1].split("&")[0];
        
    }; */
    setDarkLightMode(mode) {
        if (mode == enums_enum_1.ColorThemeE.light || mode == enums_enum_1.ColorThemeE.dark) {
            this.userSettings.prefersColorTheme = mode;
            this.userSettingUpdatePreferences();
            if (mode == enums_enum_1.ColorThemeE.light) {
                document.body.classList.remove('m-ionic-dm');
                document.body.classList.add('m-ionic-lm');
                this.mNgTheme = enums_enum_1.MNgThemesE.light;
            }
            else if (mode == enums_enum_1.ColorThemeE.dark) {
                document.body.classList.remove('m-ionic-lm');
                document.body.classList.add('m-ionic-dm');
                this.mNgTheme = enums_enum_1.MNgThemesE.dark;
            }
            this.userSettings$.next(this.userSettings);
        }
    }
    setShowLyricsOnFirstLoop(show) {
        if (show !== null) {
            this.userSettings.showLyricsOnFirstLoop = show;
            this.userSettingUpdatePreferences();
            this.userSettings$.next(this.userSettings);
        }
    }
    setHideLyricsAlways(hide) {
        if (hide !== null) {
            this.userSettings.hideLyricsAlways = hide;
            this.userSettingUpdatePreferences();
            this.userSettings$.next(this.userSettings);
        }
    }
    setExperimentalLooping(experimentalLooping) {
        if (experimentalLooping !== null) {
            this.userSettings.experimentalLooping = experimentalLooping;
            this.userSettingUpdatePreferences();
            this.userSettings$.next(this.userSettings);
        }
    }
    userSettingUpdatePreferences() {
        const userSettings = JSON.stringify(this.userSettings);
        preferences_1.Preferences.set({
            key: 'userSettings',
            value: userSettings
        });
    }
    async storageFlagsUpdatePreferences() {
        const flags = JSON.stringify(this.localStorageFlags);
        await preferences_1.Preferences.set({
            key: 'flags',
            value: flags
        });
    }
    // seemds decap, was to see if routing sub event was done by backclick 
    setPendingHistoryBackClick(value) {
        console.log(`set pending back ${value}`);
        this.mRouterEventParms.pendingHistoryBackClick = value;
    }
    getPrevRoute() {
        return this.mRouterEventParms.previousUrl;
    }
    isRunningAsPwa() {
        return window.matchMedia('(display-mode: standalone)').matches ? true : false;
    }
    setSpotIdFromCookie() {
        this.spotId = this.cookieService.get('spotId');
    }
    getSpotId() {
        return this.spotId;
    }
    getTuneItVersion() {
        return `${environment_1.ENV.DEPLOY_VERSION_MAJOR}.${environment_1.ENV.DEPLOY_VERSION_MINOR}`;
    }
    setPwaUpdateAvailable(value) {
        this.pwaUpdateAvailable$.next(value);
    }
    closeKeyboard(event) {
        event.srcElement.blur(); // drops input cursor
        if (this.isNativeCapPlatform())
            keyboard_1.Keyboard.hide();
    }
    isNativeSafari() {
        const isStandalone = ('standalone' in window.navigator) && (window.navigator['standalone']);
        // In-app browsers usually don't have these properties
        const hasSafariInterface = 'webkitFullscreenEnabled' in document;
        if (!hasSafariInterface && !isStandalone) {
            return true;
        }
        return false;
    }
    isSignedUp() {
        // signed up means registered, with a display name
        return this.cookieService.get('isSignedUp') == 'true' ? true : false;
    }
    getUserDisplayName() {
        return this.cookieService.get('displayName');
    }
    static { this.ɵfac = function GlobalService_Factory(t) { return new (t || GlobalService)(i0.ɵɵinject(i1.CookieService), i0.ɵɵinject(i2.ToastController), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i4.HelperFunctionsService), i0.ɵɵinject(i2.ModalController)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: GlobalService, factory: GlobalService.ɵfac, providedIn: 'root' }); }
}
exports.GlobalService = GlobalService;
