"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoopProgressBarComponent = void 0;
const error_handler_service_1 = require("../../services/error-handler/error-handler.service");
const player_service_1 = require("../../services/music/player.service");
const core_1 = require("@angular/core");
const i0 = require("@angular/core");
const i1 = require("../../services/music/player.service");
const i2 = require("../../services/error-handler/error-handler.service");
const i3 = require("@angular/common");
const i4 = require("@ionic/angular");
function LoopProgressBarComponent_ion_progress_bar_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "ion-progress-bar", 1);
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("value", ctx_r0.loopPosition);
} }
class LoopProgressBarComponent {
    constructor(ps, changeDetector, eh) {
        this.ps = ps;
        this.changeDetector = changeDetector;
        this.eh = eh;
        this.ionProgressbar = null;
        this.shouldAddBackLoopAnimation = false;
        // something to do with animation, ah for not move backwards when starting over
        this.loopPosition = .5;
        this.isLooping = false;
    }
    ngOnInit() {
        this.ps.isLooping$.subscribe(isLooping => {
            console.log('received is looping');
            this.isLooping = isLooping;
            this.changeDetector.detectChanges();
        });
        this.ps.loopTime$.subscribe(loopPosition => {
            try {
                //console.log(loopPosition)
                if (this.shouldAddBackLoopAnimation) {
                    const ionProgressBarElem = document.getElementById('ionProgressBar');
                    ionProgressBarElem.classList.remove('m-ion-progress-bar');
                    this.shouldAddBackLoopAnimation = false;
                }
                if (loopPosition >= 1 || loopPosition < 0) {
                    const ionProgressBarElem = document.getElementById('ionProgressBar');
                    ionProgressBarElem.classList.add('m-ion-progress-bar');
                    this.shouldAddBackLoopAnimation = true;
                }
                this.loopPosition = loopPosition;
                this.changeDetector.detectChanges();
            }
            catch (e) {
                console.error('Error, loopProgressBar loopTime subg', e);
                this.eh.logSentryError(e);
            }
        });
    }
    static { this.ɵfac = function LoopProgressBarComponent_Factory(t) { return new (t || LoopProgressBarComponent)(i0.ɵɵdirectiveInject(i1.PlayerService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i2.ErrorHandlerService)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: LoopProgressBarComponent, selectors: [["app-loop-progress-bar"]], decls: 1, vars: 1, consts: [["id", "ionProgressBar", "type", "determinate", 3, "value", 4, "ngIf"], ["id", "ionProgressBar", "type", "determinate", 3, "value"]], template: function LoopProgressBarComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵtemplate(0, LoopProgressBarComponent_ion_progress_bar_0_Template, 1, 1, "ion-progress-bar", 0);
        } if (rf & 2) {
            i0.ɵɵproperty("ngIf", ctx.isLooping);
        } }, dependencies: [i3.NgIf, i4.IonProgressBar], styles: [".m-ion-progress-bar[_ngcontent-%COMP%]::part(progress) {\n  transition: none !important;\n}\n\nion-progress-bar[_ngcontent-%COMP%] {\n  width: 100wv;\n}"] }); }
}
exports.LoopProgressBarComponent = LoopProgressBarComponent;
