"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FullscreenSpinnerComponent = void 0;
const i0 = require("@angular/core");
const i1 = require("@ionic/angular");
class FullscreenSpinnerComponent {
    constructor() { }
    ngOnInit() { }
    static { this.ɵfac = function FullscreenSpinnerComponent_Factory(t) { return new (t || FullscreenSpinnerComponent)(); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: FullscreenSpinnerComponent, selectors: [["app-fullscreen-spinner"]], decls: 1, vars: 0, template: function FullscreenSpinnerComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelement(0, "ion-spinner");
        } }, dependencies: [i1.IonSpinner], styles: ["ion-grid[_ngcontent-%COMP%] {\n  height: 80dvh;\n  max-height: 80dvh;\n  width: 100dvw;\n  padding: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  opacity: 0;\n  background-color: 0;\n}\n\nion-row[_ngcontent-%COMP%] {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  opacity: 0.5;\n}\n\nion-col[_ngcontent-%COMP%] {\n  height: 25%;\n  width: 100%;\n  opacity: 0.5;\n}\n\nion-spinner[_ngcontent-%COMP%] {\n  background-color: rgba(255, 255, 255, 0);\n  justify-self: center;\n  align-self: center;\n}"] }); }
}
exports.FullscreenSpinnerComponent = FullscreenSpinnerComponent;
