"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BeErrorLogTypeEnum = exports.CreateTuneListErrors = exports.FadeCurveTypesE = exports.ListElementTypesEnum = void 0;
var ListElementTypesEnum;
(function (ListElementTypesEnum) {
    ListElementTypesEnum["slimSpotAlbum"] = "SLIM_SPOT_ALBUM";
    ListElementTypesEnum["slimSpotArtist"] = "SLIM_SPOT_ARTIST";
    ListElementTypesEnum["slimTuneTrack"] = "SLIM_TUNE_TRACK";
    ListElementTypesEnum["tune"] = "TUNE";
    ListElementTypesEnum["slimSpotTrack"] = "SLIM_SPOT_TRACK";
})(ListElementTypesEnum = exports.ListElementTypesEnum || (exports.ListElementTypesEnum = {}));
var FadeCurveTypesE;
(function (FadeCurveTypesE) {
    FadeCurveTypesE["linear"] = "LINEAR";
    FadeCurveTypesE["exp"] = "EXP";
    FadeCurveTypesE["polynom2deg"] = "POLYNOM2DEG";
})(FadeCurveTypesE = exports.FadeCurveTypesE || (exports.FadeCurveTypesE = {}));
var CreateTuneListErrors;
(function (CreateTuneListErrors) {
    CreateTuneListErrors["noTuneListName"] = "NO_TUNE_LIST_NAME";
    CreateTuneListErrors["tuneListNameTooShort"] = "TUNE_LIST_NAME_TOO_SHORT";
    CreateTuneListErrors["tuneListNameTooLong"] = "TUNE_LIST_NAME_TOO_LONG";
    CreateTuneListErrors["tuneListNameNotUnique"] = "TUNE_LIST_NAME_NOT_UNIQUE";
})(CreateTuneListErrors = exports.CreateTuneListErrors || (exports.CreateTuneListErrors = {}));
var BeErrorLogTypeEnum;
(function (BeErrorLogTypeEnum) {
    // quick fix to use same interface for moongoose schema which is Mixed type but still needs a generic enum to init
    BeErrorLogTypeEnum["mongooseSchemaInitDummy"] = "MONGOOSE_SCHEMA_INIT_DUMMY";
    BeErrorLogTypeEnum["lyricsNotFoundGenius"] = "LYRICS_NOT_FOUND_ON_GENIUS";
    BeErrorLogTypeEnum["lyricsTooLongGenius"] = "LYRICS_TOO_LONG_FROM_GENIUS";
    BeErrorLogTypeEnum["test"] = "TEST_ERROR_ENUM";
})(BeErrorLogTypeEnum = exports.BeErrorLogTypeEnum || (exports.BeErrorLogTypeEnum = {}));
