"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DevPageModule = void 0;
const pwa_install_prompt_ios_module_1 = require("./../components/pwa-install-prompt/ios/pwa-install-prompt-ios.module");
const directives_module_1 = require("./../directives/directives.module");
const common_1 = require("@angular/common");
const forms_1 = require("@angular/forms");
const footer_bar_module_1 = require("../components/footer-bar/footer-bar.module");
const angular_1 = require("@ionic/angular");
const input_1 = require("@angular/material/input");
const form_field_1 = require("@angular/material/form-field");
const dev_routing_module_1 = require("./dev-routing.module");
const chips_1 = require("@angular/material/chips");
const icon_1 = require("@angular/material/icon");
const dev_page_1 = require("./dev.page");
const selected_track_module_1 = require("../components/selected-track/selected-track.module");
const lyrics_module_1 = require("../components/lyrics/lyrics.module");
const i0 = require("@angular/core");
class DevPageModule {
    static { this.ɵfac = function DevPageModule_Factory(t) { return new (t || DevPageModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: DevPageModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [common_1.CommonModule,
            forms_1.FormsModule,
            angular_1.IonicModule,
            dev_routing_module_1.DevPageRoutingModule,
            footer_bar_module_1.FooterBarModule,
            input_1.MatInputModule,
            chips_1.MatChipsModule,
            icon_1.MatIconModule,
            form_field_1.MatFormFieldModule,
            directives_module_1.DirectivesModule,
            selected_track_module_1.SelectedTrackModule,
            pwa_install_prompt_ios_module_1.PwaInstallPromptIosModule,
            lyrics_module_1.LyricsModule] }); }
}
exports.DevPageModule = DevPageModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DevPageModule, { declarations: [dev_page_1.DevPage], imports: [common_1.CommonModule,
        forms_1.FormsModule,
        angular_1.IonicModule,
        dev_routing_module_1.DevPageRoutingModule,
        footer_bar_module_1.FooterBarModule,
        input_1.MatInputModule,
        chips_1.MatChipsModule,
        icon_1.MatIconModule,
        form_field_1.MatFormFieldModule,
        directives_module_1.DirectivesModule,
        selected_track_module_1.SelectedTrackModule,
        pwa_install_prompt_ios_module_1.PwaInstallPromptIosModule,
        lyrics_module_1.LyricsModule] }); })();
