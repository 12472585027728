"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListElementModule = void 0;
const angular_1 = require("@ionic/angular");
const common_1 = require("@angular/common");
const list_element_component_1 = require("./list-element.component");
const directives_module_1 = require("../../directives/directives.module");
const i0 = require("@angular/core");
class ListElementModule {
    static { this.ɵfac = function ListElementModule_Factory(t) { return new (t || ListElementModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: ListElementModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [common_1.CommonModule,
            angular_1.IonicModule,
            directives_module_1.DirectivesModule] }); }
}
exports.ListElementModule = ListElementModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(ListElementModule, { declarations: [list_element_component_1.ListElementComponent], imports: [common_1.CommonModule,
        angular_1.IonicModule,
        directives_module_1.DirectivesModule], exports: [list_element_component_1.ListElementComponent] }); })();
