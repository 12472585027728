"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WaitListPageRoutingModule = void 0;
const router_1 = require("@angular/router");
const wait_list_page_1 = require("./wait-list.page");
const i0 = require("@angular/core");
const i1 = require("@angular/router");
const routes = [
    {
        path: '',
        component: wait_list_page_1.WaitListPage
    }
];
class WaitListPageRoutingModule {
    static { this.ɵfac = function WaitListPageRoutingModule_Factory(t) { return new (t || WaitListPageRoutingModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: WaitListPageRoutingModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [router_1.RouterModule.forChild(routes), router_1.RouterModule] }); }
}
exports.WaitListPageRoutingModule = WaitListPageRoutingModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(WaitListPageRoutingModule, { imports: [i1.RouterModule], exports: [router_1.RouterModule] }); })();
