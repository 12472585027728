"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryHandlerModule = void 0;
const category_handler_routing_module_1 = require("./category-handler-routing.module");
const shared_module_1 = require("./../../shared/shared.module");
const list_element_module_1 = require("../../components/list-element/list-element.module");
const category_handler_component_1 = require("./category-handler.component");
const i0 = require("@angular/core");
class CategoryHandlerModule {
    static { this.ɵfac = function CategoryHandlerModule_Factory(t) { return new (t || CategoryHandlerModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: CategoryHandlerModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [shared_module_1.SharedModule,
            list_element_module_1.ListElementModule,
            category_handler_routing_module_1.CategoryHandlerRoutingModule] }); }
}
exports.CategoryHandlerModule = CategoryHandlerModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CategoryHandlerModule, { declarations: [category_handler_component_1.CategoryHandlerComponent], imports: [shared_module_1.SharedModule,
        list_element_module_1.ListElementModule,
        category_handler_routing_module_1.CategoryHandlerRoutingModule], exports: [category_handler_component_1.CategoryHandlerComponent] }); })();
