"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TuneListPageModule = void 0;
const loop_progress_bar_module_1 = require("./../components/loop-progress-bar/loop-progress-bar.module");
const common_1 = require("@angular/common");
const forms_1 = require("@angular/forms");
const angular_1 = require("@ionic/angular");
const tune_list_routing_module_1 = require("./tune-list-routing.module");
const tune_list_page_1 = require("./tune-list.page");
const shared_module_1 = require("../shared/shared.module");
const footer_bar_module_1 = require("../components/footer-bar/footer-bar.module");
const list_element_module_1 = require("../components/list-element/list-element.module");
const selected_track_module_1 = require("../components/selected-track/selected-track.module");
const lyrics_module_1 = require("../components/lyrics/lyrics.module");
const i0 = require("@angular/core");
class TuneListPageModule {
    static { this.ɵfac = function TuneListPageModule_Factory(t) { return new (t || TuneListPageModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: TuneListPageModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [common_1.CommonModule,
            forms_1.FormsModule,
            angular_1.IonicModule,
            tune_list_routing_module_1.TuneListPageRoutingModule,
            shared_module_1.SharedModule,
            footer_bar_module_1.FooterBarModule,
            list_element_module_1.ListElementModule,
            selected_track_module_1.SelectedTrackModule,
            loop_progress_bar_module_1.LoopProgressBarModule,
            lyrics_module_1.LyricsModule] }); }
}
exports.TuneListPageModule = TuneListPageModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(TuneListPageModule, { declarations: [tune_list_page_1.TuneListPage], imports: [common_1.CommonModule,
        forms_1.FormsModule,
        angular_1.IonicModule,
        tune_list_routing_module_1.TuneListPageRoutingModule,
        shared_module_1.SharedModule,
        footer_bar_module_1.FooterBarModule,
        list_element_module_1.ListElementModule,
        selected_track_module_1.SelectedTrackModule,
        loop_progress_bar_module_1.LoopProgressBarModule,
        lyrics_module_1.LyricsModule] }); })();
