"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListElementHtmlContextE = exports.BrowsersE = exports.UserSettingsKeysE = exports.ToastEnum = exports.ModalsE = exports.TuneListPageSwtich = exports.VersionUpdateEventsE = exports.SetSaveTuneFieldsError = exports.TuneValidationError = exports.SaveOrUpdateTuneError = exports.SendTuneError = exports.UpdateTuneError = exports.SaveTuneError = exports.MNgThemesE = exports.ColorThemeE = exports.RouteEnum = exports.DevicePlatformE = exports.MyLibraryTuneListStates = exports.MyLibrarySwtichSegment = void 0;
/* module.exports.ListElementTypesEnum =
{
    album : 'ALBUM',
    artist : "ARTIST",
    slimTuneTrack : 'SLIM_TUNE_TRACK', //
    tune : 'TUNE',
    track : "TRACK",
}

module.exports.FadeCurveTypes = {
    linear : 'LINEAR',
    exp : 'EXP',
    polynom2deg : 'POLYNOM2DEG'
}
 */
var MyLibrarySwtichSegment;
(function (MyLibrarySwtichSegment) {
    MyLibrarySwtichSegment["tuneTriggers"] = "TUNE_TRIGGERS";
    MyLibrarySwtichSegment["tuneLists"] = "TUNE_LISTS";
})(MyLibrarySwtichSegment = exports.MyLibrarySwtichSegment || (exports.MyLibrarySwtichSegment = {}));
var MyLibraryTuneListStates;
(function (MyLibraryTuneListStates) {
    MyLibraryTuneListStates["multipleTuneLists"] = "MULTIPLE_TUNE_LISTS";
    MyLibraryTuneListStates["singleTuneList"] = "SINGLE_TUNE_LIST";
})(MyLibraryTuneListStates = exports.MyLibraryTuneListStates || (exports.MyLibraryTuneListStates = {}));
var DevicePlatformE;
(function (DevicePlatformE) {
    DevicePlatformE["android"] = "ANDROID";
    DevicePlatformE["ios"] = "IOS";
    DevicePlatformE["unknown"] = "UNKNOWN";
})(DevicePlatformE = exports.DevicePlatformE || (exports.DevicePlatformE = {}));
// used to filter throwed global errors from mGlobalErrorService in route. To check if the route sub. should care
var RouteEnum;
(function (RouteEnum) {
    RouteEnum["login"] = "LOGIN_ROUTE";
})(RouteEnum = exports.RouteEnum || (exports.RouteEnum = {}));
//general
var ColorThemeE;
(function (ColorThemeE) {
    ColorThemeE["light"] = "light";
    ColorThemeE["dark"] = "dark";
})(ColorThemeE = exports.ColorThemeE || (exports.ColorThemeE = {}));
//for ng components, defined in styles.scss
var MNgThemesE;
(function (MNgThemesE) {
    MNgThemesE["light"] = "my-light-theme";
    MNgThemesE["dark"] = "my-dark-theme";
})(MNgThemesE = exports.MNgThemesE || (exports.MNgThemesE = {}));
/* exports.FadeCurveTypes = FadeCurveTypes
exports.ListElementTypesEnum = ListElementTypesEnum */
//#Opt more detailed
var SaveTuneError;
(function (SaveTuneError) {
    SaveTuneError["saveTuneServerError"] = "Error saving Tune to server";
})(SaveTuneError = exports.SaveTuneError || (exports.SaveTuneError = {}));
//#Opt more detailed
var UpdateTuneError;
(function (UpdateTuneError) {
    UpdateTuneError["updateTuneError"] = "Error saving Tune to server";
})(UpdateTuneError = exports.UpdateTuneError || (exports.UpdateTuneError = {}));
var SendTuneError;
(function (SendTuneError) {
    SendTuneError["noTuneNumber"] = "Tune has never been saved";
    SendTuneError["shareNotSupported"] = "Share was not supported";
    SendTuneError["errorSharing"] = "Failed to share";
})(SendTuneError = exports.SendTuneError || (exports.SendTuneError = {}));
var SaveOrUpdateTuneError;
(function (SaveOrUpdateTuneError) {
    SaveOrUpdateTuneError["setTuneSaveFields"] = "Error setting Tune save fields";
    SaveOrUpdateTuneError["validateTuneError"] = "Error validating Tune";
    SaveOrUpdateTuneError["saveTuneError"] = "Error saving Tune";
    SaveOrUpdateTuneError["updateTuneError"] = "Error updating Tune";
})(SaveOrUpdateTuneError = exports.SaveOrUpdateTuneError || (exports.SaveOrUpdateTuneError = {}));
var TuneValidationError;
(function (TuneValidationError) {
    TuneValidationError["noStartTime"] = "Tune needs a start time";
    TuneValidationError["noStopTime"] = "Tune needs a stop time";
    TuneValidationError["tooShort"] = "Tune needs to have a message when it should be suggested";
    TuneValidationError["noTrigger"] = "Tune needs to have a message when it should be suggested";
    TuneValidationError["noLyrics"] = "Tune needs to have selected lyrics";
})(TuneValidationError = exports.TuneValidationError || (exports.TuneValidationError = {}));
var SetSaveTuneFieldsError;
(function (SetSaveTuneFieldsError) {
    SetSaveTuneFieldsError["settingLyrics"] = "Failed to set lyrics";
    SetSaveTuneFieldsError["cloningTune"] = "Failed to clone Tune";
})(SetSaveTuneFieldsError = exports.SetSaveTuneFieldsError || (exports.SetSaveTuneFieldsError = {}));
/////////////////////// SW ////////////////////////
var VersionUpdateEventsE;
(function (VersionUpdateEventsE) {
    VersionUpdateEventsE["versionUpdateDetected"] = "VERSION_DETECTED";
    VersionUpdateEventsE["versionReady"] = "VERSION_READY";
    VersionUpdateEventsE["versionFailedInstall"] = "VERSION_INSTALLATION_FAILED";
    VersionUpdateEventsE["noVersionUpdateDetected"] = "NO_NEW_VERSION_DETECTED";
})(VersionUpdateEventsE = exports.VersionUpdateEventsE || (exports.VersionUpdateEventsE = {}));
var TuneListPageSwtich;
(function (TuneListPageSwtich) {
    TuneListPageSwtich["noTuneLists"] = "NO_TUNE_LISTS";
    TuneListPageSwtich["tuneLists"] = "TUNE_LISTS";
    TuneListPageSwtich["tunes"] = "TUNES";
})(TuneListPageSwtich = exports.TuneListPageSwtich || (exports.TuneListPageSwtich = {}));
var ModalsE;
(function (ModalsE) {
    ModalsE["addToTuneList"] = "ADD_TO_TUNE_LIST";
    ModalsE["createTuneList"] = "CREATE_TUNE_LIST";
    ModalsE["renameTuneList"] = "RENAME_TUNE_LIST";
    ModalsE["unsupportedInAppBrowser"] = "UNSUPPORTED_IN_APP_BROWSER";
    ModalsE["notRecommendedBrowser"] = "NOT_RECOMMENDED_BROWSER";
    ModalsE["fullScreenSpinner"] = "FULL_SCREEN_SPINNER";
})(ModalsE = exports.ModalsE || (exports.ModalsE = {}));
var ToastEnum;
(function (ToastEnum) {
    ToastEnum["default"] = "TOAST_DEFAULT";
    ToastEnum["success"] = "TOAST_SUCCESS";
    ToastEnum["warning"] = "TOAST_WARNING";
    ToastEnum["error"] = "TOAST_ERROR";
})(ToastEnum = exports.ToastEnum || (exports.ToastEnum = {}));
var UserSettingsKeysE;
(function (UserSettingsKeysE) {
    UserSettingsKeysE["prefersColorTheme"] = "prefersColorTheme";
    UserSettingsKeysE["showLyricsOnFirstLoop"] = "showLyricsOnFirstLoop";
    UserSettingsKeysE["hideLyricsAlways"] = "hideLyricsAlways";
})(UserSettingsKeysE = exports.UserSettingsKeysE || (exports.UserSettingsKeysE = {}));
var BrowsersE;
(function (BrowsersE) {
    BrowsersE["chrome"] = "chrome";
    BrowsersE["safari"] = "safari";
})(BrowsersE = exports.BrowsersE || (exports.BrowsersE = {}));
var ListElementHtmlContextE;
(function (ListElementHtmlContextE) {
    ListElementHtmlContextE["emptyClass"] = "";
    ListElementHtmlContextE["myLibTriggerList"] = "--list-elem-name-max-width-my-lib-trigger-list";
    //myLibTuneListList = 'list-elem-my-lib-list-elem-tune-list'
    ListElementHtmlContextE["myLibTuneListList"] = "--list-elem-name-max-width-my-lib-tune-list-list";
    ListElementHtmlContextE["tuneListPage"] = "--list-elem-name-max-width-tune-list-page";
})(ListElementHtmlContextE = exports.ListElementHtmlContextE || (exports.ListElementHtmlContextE = {}));
