"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchHandlerRoutingModule = void 0;
const router_1 = require("@angular/router");
const search_handler_component_1 = require("./search-handler.component");
const i0 = require("@angular/core");
const i1 = require("@angular/router");
const routes = [
    {
        path: '',
        component: search_handler_component_1.SearchHandlerComponent,
    },
];
class SearchHandlerRoutingModule {
    static { this.ɵfac = function SearchHandlerRoutingModule_Factory(t) { return new (t || SearchHandlerRoutingModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: SearchHandlerRoutingModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [router_1.RouterModule.forChild(routes), router_1.RouterModule] }); }
}
exports.SearchHandlerRoutingModule = SearchHandlerRoutingModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(SearchHandlerRoutingModule, { imports: [i1.RouterModule], exports: [router_1.RouterModule] }); })();
