"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectedTrackRoutingModule = void 0;
const selected_track_component_1 = require("./selected-track.component");
const router_1 = require("@angular/router");
const i0 = require("@angular/core");
const i1 = require("@angular/router");
const routes = [
    {
        path: 'selected-track',
        component: selected_track_component_1.SelectedTrackComponent
    }
];
class SelectedTrackRoutingModule {
    static { this.ɵfac = function SelectedTrackRoutingModule_Factory(t) { return new (t || SelectedTrackRoutingModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: SelectedTrackRoutingModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [router_1.RouterModule.forChild(routes), router_1.RouterModule] }); }
}
exports.SelectedTrackRoutingModule = SelectedTrackRoutingModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(SelectedTrackRoutingModule, { imports: [i1.RouterModule], exports: [router_1.RouterModule] }); })();
