"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SEOService = void 0;
const common_1 = require("@angular/common");
const i0 = require("@angular/core");
class SEOService {
    constructor(dom) {
        this.dom = dom;
    }
    updateCanonicalUrl() {
        // const head = this.dom.getElementsByTagName('head')[0];
        // var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
        // if (element==null) {
        //   element= this.dom.createElement('link') as HTMLLinkElement;
        //   head.appendChild(element);
        // }
        // element.setAttribute('rel','apple-touch-icon')
        // element.setAttribute('sizes','192x192')
        // element.setAttribute('href', ENV.DOMAIN_OF_BACKEND + '/icon?resolution=192x192')
    }
    static { this.ɵfac = function SEOService_Factory(t) { return new (t || SEOService)(i0.ɵɵinject(common_1.DOCUMENT)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: SEOService, factory: SEOService.ɵfac, providedIn: 'root' }); }
}
exports.SEOService = SEOService;
