"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MyLibraryStateService = void 0;
const rxjs_1 = require("rxjs");
const i0 = require("@angular/core");
class MyLibraryStateService {
    // Its not really state, but could and should be. Added later in desing.
    constructor() {
        this.tuneDeletedFromTuneList$ = new rxjs_1.BehaviorSubject(null);
        this.tuneAddedToTuneList$ = new rxjs_1.BehaviorSubject(null);
    }
    tuneDeletedFromTuneList(tuneListId, tuneId) {
        this.tuneDeletedFromTuneList$.next({ tuneListId: tuneListId, tuneId: tuneId });
    }
    tuneAddedToTuneList(tuneListId, tuneId) {
        this.tuneAddedToTuneList$.next({ tuneListId: tuneListId, tuneId: tuneId });
    }
    static { this.ɵfac = function MyLibraryStateService_Factory(t) { return new (t || MyLibraryStateService)(); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: MyLibraryStateService, factory: MyLibraryStateService.ɵfac, providedIn: 'root' }); }
}
exports.MyLibraryStateService = MyLibraryStateService;
