"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatListPageRoutingModule = void 0;
const router_1 = require("@angular/router");
const chat_list_page_1 = require("./chat-list.page");
const i0 = require("@angular/core");
const i1 = require("@angular/router");
const routes = [
    {
        path: '',
        component: chat_list_page_1.ChatListPage
    },
    {
        path: 'chat/:id',
        loadChildren: () => Promise.resolve().then(() => require('../chat/chat.module')).then(m => m.ChatPageModule)
    }
];
class ChatListPageRoutingModule {
    static { this.ɵfac = function ChatListPageRoutingModule_Factory(t) { return new (t || ChatListPageRoutingModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: ChatListPageRoutingModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [router_1.RouterModule.forChild(routes), router_1.RouterModule] }); }
}
exports.ChatListPageRoutingModule = ChatListPageRoutingModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(ChatListPageRoutingModule, { imports: [i1.RouterModule], exports: [router_1.RouterModule] }); })();
