"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryHandlerRoutingModule = void 0;
const category_handler_component_1 = require("./category-handler.component");
const router_1 = require("@angular/router");
const i0 = require("@angular/core");
const i1 = require("@angular/router");
const routes = [
    {
        path: '',
        //redirectTo:'/find-tune/select'
        component: category_handler_component_1.CategoryHandlerComponent,
    },
];
class CategoryHandlerRoutingModule {
    static { this.ɵfac = function CategoryHandlerRoutingModule_Factory(t) { return new (t || CategoryHandlerRoutingModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: CategoryHandlerRoutingModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [router_1.RouterModule.forChild(routes), router_1.RouterModule] }); }
}
exports.CategoryHandlerRoutingModule = CategoryHandlerRoutingModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CategoryHandlerRoutingModule, { imports: [i1.RouterModule], exports: [router_1.RouterModule] }); })();
