"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotRecommendedBrowserComponent = void 0;
const enums_enum_1 = require("./../../../classes-enums-interfaces-types/enums/enums.enum");
const angular_1 = require("@ionic/angular");
const global_service_1 = require("../../../services/global.service");
const i0 = require("@angular/core");
const i1 = require("@ionic/angular");
const i2 = require("../../../services/global.service");
const i3 = require("@angular/common");
function NotRecommendedBrowserComponent_div_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵtext(1);
    i0.ɵɵelement(2, "ion-icon", 5);
    i0.ɵɵtext(3);
    i0.ɵɵelement(4, "ion-icon", 5);
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.msgAndroid1, "");
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("src", ctx_r0.androidLogo);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.msgAndroid2, "");
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("src", ctx_r0.chromeLogo);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.msgAndroid3, " ");
} }
function NotRecommendedBrowserComponent_div_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵtext(1);
    i0.ɵɵelement(2, "ion-icon", 5);
    i0.ɵɵtext(3);
    i0.ɵɵelement(4, "ion-icon", 5);
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", ctx_r1.msgIos1, " ");
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("src", ctx_r1.appleLogoWhite);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", ctx_r1.msgIos2, "");
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("src", ctx_r1.safariLogo);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", ctx_r1.msgIos3, " ");
} }
class NotRecommendedBrowserComponent {
    constructor(modalControl, gs) {
        this.modalControl = modalControl;
        this.gs = gs;
        this.devicePlatform = this.gs.getDevicePlatform();
        this.devicePlatformE = enums_enum_1.DevicePlatformE;
        this.msgIos1 = `Hi 👋

	You seem to be viewing this on an iOS `;
        this.msgIos2 = `device.
	
	At this point this Web App has only been tested with Safari `;
        this.msgIos3 = `
	
	It is recommended to use this Web App in Safari on iOS.`;
        this.msgAndroid1 = `Hi 👋

	You seem to be viewing this on an Android `;
        this.msgAndroid2 = ` device

	At this point this Web App has only been tested with Chrome `;
        this.msgAndroid3 = `
	
	It is highly recommended to use this Web App in Chrome.`;
        this.msg = '';
        this.chromeLogo = '../../../assets/google-chrome-logo.svg';
        this.androidLogo = '../../../assets/android-head-logo.svg';
        this.safariLogo = '../../../assets/safari-border-logo.svg';
        this.appleLogoWhite = '../../../assets/apple-logo-white.svg';
    }
    ngOnInit() {
    }
    closeModal() {
        try {
            return this.modalControl.dismiss(null, 'cancel', enums_enum_1.ModalsE.notRecommendedBrowser);
        }
        catch (e) {
            console.log(`Error closing modal`, e);
        }
    }
    static { this.ɵfac = function NotRecommendedBrowserComponent_Factory(t) { return new (t || NotRecommendedBrowserComponent)(i0.ɵɵdirectiveInject(i1.ModalController), i0.ɵɵdirectiveInject(i2.GlobalService)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: NotRecommendedBrowserComponent, selectors: [["app-not-recommended-browser"]], decls: 9, vars: 3, consts: [["slot", "end"], ["color", "primary", 3, "strong", "click"], ["id", "main-grid"], ["class", "msg-div", 4, "ngIf"], [1, "msg-div"], [3, "src"]], template: function NotRecommendedBrowserComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "ion-toolbar")(1, "ion-title");
            i0.ɵɵtext(2, "Info");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(3, "ion-buttons", 0)(4, "ion-button", 1);
            i0.ɵɵlistener("click", function NotRecommendedBrowserComponent_Template_ion_button_click_4_listener() { return ctx.closeModal(); });
            i0.ɵɵtext(5, "Close");
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(6, "ion-grid", 2);
            i0.ɵɵtemplate(7, NotRecommendedBrowserComponent_div_7_Template, 6, 5, "div", 3);
            i0.ɵɵtemplate(8, NotRecommendedBrowserComponent_div_8_Template, 6, 5, "div", 3);
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("strong", true);
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("ngIf", ctx.devicePlatform == ctx.devicePlatformE.android);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.devicePlatform == ctx.devicePlatformE.ios);
        } }, dependencies: [i3.NgIf, i1.IonButton, i1.IonButtons, i1.IonGrid, i1.IonIcon, i1.IonTitle, i1.IonToolbar], styles: ["#main-grid[_ngcontent-%COMP%] {\n  padding: 5vw;\n}\n\n.msg-div[_ngcontent-%COMP%] {\n  text-align: center;\n  line-height: 1.5;\n  white-space: pre-line;\n}\n\nion-icon[_ngcontent-%COMP%] {\n  height: 3dvh;\n  width: 3dvh;\n  border-radius: 1.5dvh;\n  color: white;\n  vertical-align: middle;\n  display: inline-block;\n}"] }); }
}
exports.NotRecommendedBrowserComponent = NotRecommendedBrowserComponent;
