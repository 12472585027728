"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatMessageComponent = void 0;
const global_service_1 = require("../../../services/global.service");
const i0 = require("@angular/core");
const i1 = require("../../../services/global.service");
const i2 = require("@angular/common");
const _c0 = function (a0) { return { "justify-content": a0 }; };
const _c1 = function (a0) { return { "background-color": a0 }; };
class ChatMessageComponent {
    constructor(mGlobal) {
        this.mGlobal = mGlobal;
        this.backgroundColorReceiver = `#00FF00`;
        this.backgroundColorSender = `#0000FF`;
    }
    ngOnInit() {
        //this.isSender = (this.chatMessage.sender == this.mGlobal.userId)
        if (!this.chatMessage.id) {
        }
        console.log("what");
    }
    static { this.ɵfac = function ChatMessageComponent_Factory(t) { return new (t || ChatMessageComponent)(i0.ɵɵdirectiveInject(i1.GlobalService)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ChatMessageComponent, selectors: [["app-chat-message"]], inputs: { chatMessage: "chatMessage" }, decls: 3, vars: 7, consts: [["id", "messageContainerRow", 3, "ngStyle"], ["id", "messageArea", 3, "ngStyle"]], template: function ChatMessageComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "div", 1);
            i0.ɵɵtext(2);
            i0.ɵɵelementEnd()();
        } if (rf & 2) {
            i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(3, _c0, ctx.isSender ? "right" : "left"));
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(5, _c1, ctx.isSender ? ctx.backgroundColorSender : ctx.backgroundColorReceiver));
            i0.ɵɵadvance(1);
            i0.ɵɵtextInterpolate1(" ", ctx.chatMessage.text, " ");
        } }, dependencies: [i2.NgStyle], styles: ["#messageContainerRow[_ngcontent-%COMP%] {\n  width: 100%;\n  display: flex;\n  justify-content: flex-end;\n  padding-top: 0.5dvh;\n  padding-bottom: 0.5dvh;\n  padding-left: 3dvh;\n  padding-right: 3dvh;\n}\n\n#messageArea[_ngcontent-%COMP%] {\n  width: 80%;\n  padding: 3vw;\n  border-radius: 3.5vw;\n}"] }); }
}
exports.ChatMessageComponent = ChatMessageComponent;
