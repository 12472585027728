"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TuneListPageRoutingModule = void 0;
const router_1 = require("@angular/router");
const tune_list_page_1 = require("./tune-list.page");
const i0 = require("@angular/core");
const i1 = require("@angular/router");
const routes = [
    {
        path: '',
        component: tune_list_page_1.TuneListPage
    },
    {
        path: '**',
        redirectTo: ''
    }
];
class TuneListPageRoutingModule {
    static { this.ɵfac = function TuneListPageRoutingModule_Factory(t) { return new (t || TuneListPageRoutingModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: TuneListPageRoutingModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [router_1.RouterModule.forChild(routes), router_1.RouterModule] }); }
}
exports.TuneListPageRoutingModule = TuneListPageRoutingModule;
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(TuneListPageRoutingModule, { imports: [i1.RouterModule], exports: [router_1.RouterModule] }); })();
